import React, {useRef, useEffect, useState} from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import {DialogRef} from "react/parkable-components/dialog/Dialog";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import { IRootReducer } from "../../redux/reducers/main";
import { showDeleteFavouriteModal, deleteFavouritePark } from "../../redux/actions/userOptions";
import Strings from '../../constants/localization/localization'
import { UserOptions } from "../../model/UserOptions";
import {ParkDTO} from "../../model/ParkDTO";

interface DeleteFavouriteParam {
    result:boolean,
    details: UserOptions | any
}

type Props = {
    park: ParkDTO | undefined,
    deleteFavouriteCallback?: (param: DeleteFavouriteParam) => void;
}

const DeleteFavourite = ({isShowing, park, deleteFavouriteCallback, showDeleteFavouriteModal, deleteFavouritePark}: Props & ReturnType<typeof getReduxProps> & typeof actions) => {
    const dialogRef = useRef<DialogRef|null>(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isShowing) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [isShowing]);
    const onSave = () => {
        if (!park) {return}
        setLoading(true);
        deleteFavouritePark(park.id, onSuccess, onError);
    }
    const onCancel = () => {
        showDeleteFavouriteModal(false)
    }
    const onSuccess = (userOptions:UserOptions) => {
        onCancel()
        deleteFavouriteCallback?.({result: true, details: userOptions})
    }
    const onError = (err:any) => {
        onCancel();
        deleteFavouriteCallback?.({result: false, details: err})
    }

    return  <Dialog ref={dialogRef} disableCloseOnPositive
                    onModalHide={onCancel} //in case when user click outside of dialog
                    positiveText={Strings.remove}
                    negativeText={Strings.cancel}
                    positiveProps={{textProps: {h4: true}, disabled: loading}}
                    negativeProps={{textProps: {h4: true}, disabled: loading}}
                    onPositivePress={onSave}
                    onNegativePress={onCancel}
                    >
                <View style={{paddingTop: 16}}>
                    <Text h2 style={{marginBottom:3}}>
                        {Strings.remove_from_favourites}
                    </Text>
                    <Text h5 style={{marginBottom:21}}>
                        {Strings.remove_car_park_from_your_favourites}
                    </Text>
                </View>
            </Dialog>
}

const actions = {
    showDeleteFavouriteModal,
    deleteFavouritePark
}

const getReduxProps = (state: IRootReducer) => {
    const isShowing = state.userOptions.showDeleteFavouriteModal;
    return {isShowing}
}

export default connect(getReduxProps, actions)(DeleteFavourite) as React.FunctionComponent<Props>;
