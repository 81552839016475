/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/08/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

// @flow

import {get, post, put, del} from "./rest";
import {ParkDTO, RetrievePark2Response} from "../model/ParkDTO";
import {Park} from "../model/Park";
import {ParkInfo2} from "../model/ParkInfo2";
import {Bay, Feature} from "../model/Bay";
import {Token} from "./rest";
import { ParkingActivity } from "../model/Types";
import {ActivityOrigin} from "../model/ActivityOrigin";
import {BayDTO} from "../model/BayDTO";
import {SessionLineItem} from "../model/SessionLineItem";
import {OcpiSession} from "../model/ocpi/OcpiSession";
import {ParkSession} from "../model/ParkSession";
import {ParkAvailabilityNotificationResponse} from "../model/ParkAvailabilityNotificationResponse";
import {ParkAvailabilityNotificationsResponse} from "../model/ParkAvailabilityNotificationsResponse";
import {useMySWR} from "./http/use-swr";
import { Nully } from "../constants/nully";
import {AccessGateProximity} from "../model/AccessGate";
import {generateQueryString} from "./http/rest";

export function getParkAPI(api:string, token:Token, parkId:number) {
    api = api + `v2/parks/${parkId}`;
    return get(token, api) as Promise<{park: ParkDTO}>;
}

export const getParkBaysAPI = (api:string, token:Token, parkId:number) => get(token, `${api}v2/parks/${parkId}/bays`);

export function getBaysAvailToUserAPI(api:string, token:Token, parkId:number, parkingType: ParkingActivity, feature?: Feature, origin? : ActivityOrigin){
    api = api + `v2/parks/${parkId}/userBays`;
    return get(token, api, {feature, parkingType, origin}) as Promise<{bays: BayDTO[]}>;
}

export type ParkSessionResponse = {
    userCardlastFour: string | null;
    userCardBrand: string | null;
    parkSession: ParkSession | null;
    gateOpenSuccess: boolean | null;
    vacateBayDeadline: string | null;
    sessionLineItem: SessionLineItem | null;
    ocpiSessions: OcpiSession[] | null;
}

export function changeSessionBay(api:string, token:Token, sessionId:number, bayId:number, bayGroupId:number, organisationId:number| null){
    api = api + `v2/parksessions/${sessionId}/changebay?bayId=${bayId}&bayGroupId=${bayGroupId}&organisationId=${organisationId}`;
    return put(token, api) as Promise<ParkSessionResponse>;
}

export function getOrganisationDTOAPI(api:string, token:Token, orgId:number) {
    api = api + "v2/organisations";
    return get(token, api + "/" + orgId /*+ "/client"*/) as Promise<any>;
}

export function getOrganisationGroupsAPI(api:string, token:Token, orgId:number) {
    api = api + `v2/organisations/${orgId}/usergroups`;
    return get(token, api);
}

export function getHostId(api:string, token:Token, parkId:number) {
    api = `${api}v2/parks/${parkId}/hostId`;
    return get(token, api);
}

export function getBayGroupsInPark(api:string, token:Token, orgId:number, parkId:number) {
    api = `${api}v2/organisations/${orgId}/baygroups/park/${parkId}`;
    return get(token, api)
}

export function getBayGroup(api:string, token:Token, orgId:number, groupId:number) {
    api = `${api}v2/organisations/${orgId}/baygroups/${groupId}`;
    return get(token, api);
}

export const openGate = (api:string, token:Token, parkId:number, gateId:string, proximity: Array<AccessGateProximity>) =>
    post(token, `${api}v1/accessgate/${gateId}/${parkId}/open`, {proximity});

export const getHostedParksAPI = (api:string, token:Token) =>  get(token, `${api}v1/parks/hosted`) as Promise<{parks: Park[]}>;

export const updateParkAPI = (api:string, token:Token, parkId:number, data:Object) => put(token, `${api}v2/parks/${parkId}`, data) as Promise<{park: ParkDTO, parkInfo: ParkInfo2 }>;

export const deletePark = (api:string, token:Token, parkId:number) => del(token, `${api}v1/parks/${parkId}`);

export const getRecentlyParkedInParks = (api:string, token:Token) => get(token, `${api}v3/parks/recentParked`);

export const isPublicParker = (api:string, token:Token, parkId:number) => get(token, `${api}v3/parks/${parkId}/isPublicParker`) as Promise<{publicParker: boolean}>;

export const getBaysForPark = (api:string, token:Token, parkId:number) => get(token, `${api}v2/parks/${parkId}/bays`);

export const getParksInOrg = (api:string, token:Token, organisationId:number) => get(token, `${api}v2/organisations/${organisationId}/parks`) as Promise<{parks: ParkDTO[]}>;

export const getBayAPI = (api:string, token:Token, parkId:number, bayId:number) =>  get(token, `${api}v2/bay/${parkId}/${bayId}`) as Promise<Bay>;

export interface ParkInfo2sResponse {
    parkInfo: ParkInfo2[];
    cursor: string;
    total: number;
}

export const getClosestParks = (api:string, token:Token, latitude:number, longitude:number, distanceMetres:number, count: number | null | undefined, cursor: string | null,
                                filterCampus?: boolean) =>
    get(token, `${api}v2/parks`, {
        latitude,
        longitude,
        distancemetres: distanceMetres,
        count,
        cursor,
        filterCampus,
        sort: false
    }) as Promise<ParkInfo2sResponse>;

export const searchParkAddress = (api:string, token:Token, address:string) => {
    const encoded = encodeURI(address);
    return get(token, `${api}v2/parks/query?address=${encoded}&active=true`) as Promise<ParkInfo2sResponse>;
};

export const createUpdateParkAvailabilityNotification = (api:string, token:Token, parkId: number, minutes:number) : Promise<ParkAvailabilityNotificationResponse> => post(token, `${api}v1/parkavailabilitynotifications`, {parkId: parkId, notifyForMinutes: minutes});
export const getParkAvailabilityNotificationForPark = (api:string, token:Token, parkId: number) : Promise<ParkAvailabilityNotificationResponse> => get(token, `${api}v1/parkavailabilitynotifications/park?parkId=${parkId}`);
export const getParkAvailabilityNotifications = (api:string, token:Token) : Promise<ParkAvailabilityNotificationsResponse> => get(token, `${api}v1/parkavailabilitynotifications`);
export const deleteParkAvailabilityNotification = (api:string, token:Token, parkId: number, id: number) => del(token, `${api}v1/parkavailabilitynotifications?parkId=${parkId}&id=${id}`);

//GET a Park data with SWR
export const usePark = (parkId: Nully<number>, organisationId?: Nully<string | number>) => {
    const queryString = generateQueryString({ organisationId });
    const url = parkId ? `v2/parks/${parkId}${queryString}` : null;
    const {data, ...rest} = useMySWR<RetrievePark2Response>(url);
    return {park: data?.park, ...rest}
};

export const useParksInOrg = (orgId: Nully<number>) => {
    const url = orgId ? `v2/organisations/${orgId}/parks` : null;
    const { data, ...rest } = useMySWR<{ parks: ParkDTO[] }>(url);
    return { parks: data?.parks, ...rest };
};

export const useMyPrivateParks = () => {
    return useMySWR<{ parks: ParkDTO[] }>("v3/parks/private");
};
