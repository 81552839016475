import * as Type from '../actions/geoLocation';
import {ILocation} from "../../constants/Util";

const init = {
    gpsLocation: null as null | ILocation,
    status: false as boolean,
    errorGetGPSLocation: false as boolean,
};

export type IGeoLocation = typeof init;

export default function geoLocationReducer (state = init, action: any): IGeoLocation {
    switch (action.type) {
        case Type.CURRENT_POSITION_RECEIVED:
            return {
                ...state,
                gpsLocation: action.gpsLocation,
            };
        case Type.LOCATION_PROVIDER_STATUS_RECEIVED:
            return {
                ...state,
                status: action.status,
            };
        case Type.SET_ERROR_GET_GPS_LOCATION: {
            return {
                ...state,
                errorGetGPSLocation: action.errorGetGPSLocation,
            };
        }

        default:
            return state;
    }
}
