import {Platform} from "react-native";
import defaultConfig from "../config/default";
import * as Application from "expo-application";
import { browserName, browserVersion } from "react-device-detect";

export function appVersionString() {
    if(Platform.OS === "web") {
        return "v" + "Web" 
        + browserName + browserVersion 
        + ":" + reactVersionString();
    }
    return "v" + Application.nativeApplicationVersion
        + "b" + Application.nativeBuildVersion
        + ":" + reactVersionString();
}

function reactVersionString() {
    return "r" + defaultConfig.version
        + "." + defaultConfig.build;
}

export function sentryVersionString() {
    return Platform.OS + "-" + reactVersionString();
}
