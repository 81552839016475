import {Bay} from "../model/Bay";
import {ParkDTO} from "../model/ParkDTO";
import {BayGroup} from "../model/BayGroup";
import {Park} from "../model/Park";
import {StartSubscriptionPark} from "../components/subscriptions/StartSubscriptionSummaryView";
import {ParkDTOWithTerritory} from "../redux/actions/parks";


export type ILongTermPrice = {
    pricePerWeek: number | null,
    pricePerMonth: number | null
}

export const getLongTermPrices = (park?: ParkDTO | Park | ParkDTOWithTerritory | StartSubscriptionPark, bayGroup?: BayGroup): ILongTermPrice => {
    return {
        pricePerWeek: bayGroup?.pricePerWeek??park?.pricePerWeek??null,
        pricePerMonth: bayGroup?.pricePerMonth??park?.pricePerMonth??null
    }
};
