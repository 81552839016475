import React from "react"
import Icon from "react/parkable-components/icon/Icon";
import { IconName } from "react/parkable-components/icon/Icons";
import Text from "react/parkable-components/text/Text";
import {TouchableOpacity, View} from "react-native";
import {generateCommonPinStyles, markerStyles,} from "./styles";

export type PinWrapperProps = Parameters<typeof generateCommonPinStyles>[0]
	& {
		icon: IconName
		children: string
		handlePress: () => any
	}

/** An optional wrapper used for run-of-the-mill pins */
export const PinWrapper = (props: PinWrapperProps) => {
	const {
		isFavouritePark,
		isOrgMember,
		isParkUnavailable,
		icon,
		children,
		handlePress,
	} = props

	const styles = generateCommonPinStyles({
		isFavouritePark,
		isOrgMember,
		isParkUnavailable,
	});

	return <TouchableOpacity onPress={handlePress} activeOpacity={1}>
		<View style={markerStyles.container}>
			<View style={markerStyles.innerContainer}>
				<View style={styles.iconStyle}>
					<Icon iconProps={{ allowFontScaling: false }}
						name={icon}
						iconStyle={{ fontSize: 20 }}
						color={styles.iconColor}
					/>
				</View>

				<View style={styles.textPanelStyle}>
					<Text allowFontScaling={false} style={styles.textStyle}>
						{children}
					</Text>
				</View>
			</View>

			<View style={styles.arrowContainerStyle}>
				<View style={styles.arrowStyle} />
			</View>
		</View >
	</TouchableOpacity>
}
