/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 24/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/

import React, {} from "react";
import {StyleSheet, View} from "react-native";
import DayOfWeek from "./DayOfWeek";
import {Availability, getDayAvailability} from "../../model/Availability";
import {getDayOfWeek} from "../../constants/Util";

type DayOfWeekHeaderProps = {
    parkAvailability: Availability,
}

const DayOfWeekHeader = (props: DayOfWeekHeaderProps) => {

    const { parkAvailability } = props;

    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    const renderDayOfWeekTitleTile = (dayOfWeek: string, i: number) => {
        const dayAvailability = getDayAvailability(getDayOfWeek(i).toLowerCase(), parkAvailability);
        const isParkOpen = dayAvailability.available;

        return (<DayOfWeek key={i} dayOfWeek={dayOfWeek} isAvailable={isParkOpen} dayOfWeekIndex={i}/>);
    }

    const dayOfWeekTitleTiles = daysOfWeek.map((dayOfWeek, i) => renderDayOfWeekTitleTile(dayOfWeek, i));

    return (
        <View style={styles.dayOfWeekHeaderContainer}>
            {dayOfWeekTitleTiles}
        </View>
    );
};

const styles = StyleSheet.create({
    dayOfWeekHeaderContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
});

export default DayOfWeekHeader;
