import React, { Component } from "react";
import connect from "../../constants/connect";
import Button from "react/parkable-components/button/Button";
import Text from "react/parkable-components/text/Text";
import { cancelReservation, getCurrentParkingSession, stopParking } from "../../redux/actions/parking";
import Strings from "../../constants/localization/localization";
import { Image, StyleSheet, View } from "react-native";
import SessionElapsed from "./SessionElapsed";
import { getPark } from "../../redux/actions/parks";
import { showAlert, showConfirmation } from "../../alerts";
import { keepSessionRunningAPI } from "../../api/parking";
import { Routes } from "react/navigation/root/root.paths";
import { IRootReducer } from "../../redux/reducers/main";
import { DispatchFunc } from "../../model/Types";
import { Park } from "../../model/Park";
import { Token } from "../../api/rest";
import { getId } from "../../constants/Util";
import { getTerritory } from "../../redux/actions/territories";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";

const carParkingBayBackground = require('../../resources/carParkingBayBackground.png');

const getReduxProps = (state: IRootReducer) => {

    const currentSession = state.parking.currentSession;

    let park = undefined as Pick<Park, 'id' | 'reservationMinutes' | 'territory'> | undefined;
    if (!!currentSession) {
        if (typeof currentSession.park === 'number') {
            park = state.parks.parks[currentSession.park];
        } else {
            park = currentSession.park;
        }
    }

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    } as Token;

    return {
        currentSession,
        park,
        territory: state.territories.territories?.[getId(park?.territory)],
        api: state.data.api,
        token: tokenObject,
    };
}

type Props = NavigationProps<Routes.WarningOpenSessionView> & ReturnType<typeof getReduxProps> & {dispatch: DispatchFunc};

interface WarningOpenSessionViewState {
    loading: boolean,
    loadingSession: boolean,
}

class WarningOpenSessionView extends Component<Props, WarningOpenSessionViewState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            loading: false,
            loadingSession: false,
        };
    }

    UNSAFE_componentWillMount() {

        const currentSession = this.props.currentSession;
        if (!!currentSession) {
            if (!this.props.park && typeof currentSession.park === 'number') {
                this.props.dispatch(getPark(currentSession.park));
            }
        } else {
            this.setState({loadingSession: true});
            this.props.dispatch(getCurrentParkingSession(() => {
                this.setState({loadingSession: false});
            }, () => {
                this.setState({loadingSession: false});
            }));
        }

        if (!this.props.territory && !!this.props.park) {
            this.props.dispatch(getTerritory(getId(this.props.park.territory)));
        }
    }

    onCancelReservationPress = () =>
        showConfirmation(Strings.confirm_end_reservation, Strings.end_reservation, true, confirmed => {
            if (confirmed && !!this.props.currentSession) {

                const parkId = getId(this.props.currentSession?.park);
                this.setState({ loading: true }, () =>
                    this.props.dispatch(cancelReservation(this.props.currentSession!.id, parkId,
                        () => {
                            this.props.navigation.reset({
                                routes: [{
                                    name: Routes.ParkDetailView,
                                    params: { parkId }
                                }]
                            });
                        },
                        () => this.setState({ loading: false }))));
            }

        });

    onStopParkingPress = () =>
        showConfirmation(Strings.confirm_end_parking, Strings.end_parking, true, confirmed => {
            if (!confirmed || !this.props.currentSession) {return}

            this.setState({loading: true});
            this.props.dispatch(stopParking(this.props.currentSession.id, this.props.territory,
                    () => {
                        this.props.navigation.reset({
                            routes: [{
                                name: Routes.SessionSummary,
                            }]
                        });
                    },
                    () => this.setState({loading: false})));
        });

    renderActionButtons = (isReservation: boolean) => {
        if (isReservation) {
            return <Button plain border center textProps={{red: true}} style={styles.buttons}
                           onPress={this.onCancelReservationPress}>{Strings.cancel_reservation}</Button>
        }

        return <Button red border center style={styles.buttons}
                       onPress={this.onStopParkingPress}>{Strings.stop_parking}</Button>
    }

    onKeepSessionButtonPress = () => {
        const {
            api,
            token,
            dispatch,
            currentSession
        } = this.props;
        if (!currentSession) {
            return;
        }
        this.setState({loadingSession: true});
        const onDone = () => this.setState({loadingSession: false});
        const sessionId = currentSession.id;
        keepSessionRunningAPI(api, token, sessionId).then(() => {
            console.log('DID KEEP SESSION');
            onDone();
            this.props.navigation.reset({
                routes: [{
                    name: Routes.ActiveSession,
                    params: { sessionId }
                }]
            });
        }).catch((err) => {
            onDone();
            console.log(err);
            showAlert(err?.message??Strings.internal_error_if_persists);
        });
    }

    onBackPress = () => this.props.navigation.pop();

    render() {
        const {currentSession, park, territory} = this.props;
        const isReservation = !!currentSession && !currentSession.startedAt && !!currentSession.reservationStartedAt;
        const currencyCode = territory?.currencyCode;

        return (
            <ParkableBaseView
                removeStandardMargins
                toolbarStyle={{marginHorizontal: PADDING}}
                onBackPress={this.onBackPress}
                loading={this.state.loading || this.state.loadingSession}>
                <View style={styles.bodyView}>
                    <Text h2>{Strings.did_you_forget_to_stop_parking_question}</Text>
                    <View style={styles.imageContainer}>
                        <Image style={styles.carParkingBayBackground} source={carParkingBayBackground}
                               resizeMode={"cover"} />
                    </View>
                    <View style={styles.sessionDetails}>
                        <Text p style={styles.warningText}>{Strings.warning_reminder_running_session}</Text>
                        {!!currentSession && <Button
                            style={styles.buttons}
                            plain
                            border
                            center
                            onPress={this.onKeepSessionButtonPress}>
                            {Strings.keep_session_going}
                        </Button>}
                        {!!currentSession && this.renderActionButtons(isReservation)}
                        {!!(currencyCode && currentSession) &&
                        <SessionElapsed session={currentSession}
                                        park={park ?? null}
                                        isReservation={isReservation}
                                        currencyCode={currencyCode} />}

                    </View>
                </View>
            </ParkableBaseView>
        );
    }
}


export default connect(getReduxProps)(WarningOpenSessionView) as React.FunctionComponent<Props>;

export const WarningOpenSessionRoute = createRoute({
    path: Routes.WarningOpenSessionView,
});

const styles = StyleSheet.create({
    bodyView: {
        flex: 1,
        marginTop: 10,
        marginHorizontal: PADDING,
    },
    sessionDetails: {
        padding: 15,
        alignItems: 'center'
    },
    warningText: {
        marginTop: 50,
    },
    buttons: {
        width: "100%",
        marginTop: PADDING,
    },
    imageContainer:{
        flexDirection: "row",
        marginHorizontal: -PADDING,
    },
    carParkingBayBackground: {
        flex: 1,
        zIndex: -1,
        aspectRatio: 375 / 291,
    },
    keepSessionButton: {
        marginTop: -15,
        marginBottom: -5
    },
});
