import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { IRootReducer } from "../../../redux/reducers/main";
import { getCurrentPosition, setLocationProviderStatus } from "../../../redux/actions/geoLocation";
import { setGpsRequested } from "../../../redux/actions/user";
import { AppState, AppStateStatus, Linking, NativeModules, Platform, View } from "react-native";
import Strings from "../../../constants/localization/localization";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import _ from "lodash";
import { AppStateChangeThrottleMillis } from "../../../constants/constants";
import * as Location from "expo-location";

const { RNAndroidOpenSettings } = NativeModules;

const GPSLocationDialogView = (props: ReduxProps & Props & typeof actions) =>{

    const {
        getCurrentPosition,
        showGPSDialog,
        setShowGPSDialog,
        setLocationProviderStatus,
        gpsRequested,
        setGpsRequested
    } = props;

    const [hasPermission, setHasPermission] = useState(false);
    const [isGpsOn, setIsGpsOn] = useState(true);

    const dialogRef = useRef<DialogRef|null>(null);

    const onNegativePress = useCallback(() => {
        dialogRef.current?.hide()
        setGpsRequested(true);
        // setOpenDialog(false);
        setShowGPSDialog?.(false);
    },[]);

    const onPositivePress = useCallback(() => {
        // setOpenDialog(false);
        dialogRef.current?.hide()
        setShowGPSDialog?.(false);
        if (Platform.OS === 'ios') {
            void Linking.openSettings();
        } else {
            RNAndroidOpenSettings.appDetailsSettings();
        }
    }, []);

    const isSettingsOn = () => {
        Location.getForegroundPermissionsAsync().then((permissions) => {
            const { granted } = permissions;
            setLocationProviderStatus(granted);
            setIsGpsOn(granted);
        });
    };

    const throttledPermissionsCheck = useCallback(_.throttle(() => {
        Location.getForegroundPermissionsAsync().then((permissions) => {
            const { granted } = permissions;
            setHasPermission(granted);
            if (granted) {
                getCurrentPosition();
            }
        });
    }, AppStateChangeThrottleMillis), []);

    const onAppStateChange = (appState: AppStateStatus) => {
        if (appState === 'active') {
            throttledPermissionsCheck();
        }
    };

    //ASK PERMISSION TO USE USER LOCATION
    useEffect(() => {

        const subscription = AppState.addEventListener('change', onAppStateChange);

        Location.getForegroundPermissionsAsync().then((permissions) => {
            const { granted } = permissions;
            setHasPermission(granted);
        });

        //IF GPS IS ON -> GET CURRENT LOCATION AND MOVE MAP
        if(isGpsOn){
            getCurrentPosition();
        }
        return () => {
            subscription.remove();
        }
    },[]);


    //AFTER ASK PERMISSION
    // -> ADD LISTENER TO GPS SETTINGS EVENT
    // -> If CURRENT LOCATION IS NULL, CHECK IF GPS IS TURNED ON
    useEffect( () => {
        if(hasPermission){
            isSettingsOn();
        }
    },[hasPermission]);

    //IF GPS IS ON -> GET CURRENT LOCATION AND MOVE MAP
    useEffect(() =>{
        if(isGpsOn){
            getCurrentPosition();
        }
    },[isGpsOn]);

    //IF GPS IS OFF AND FIRST TIME -> ASK TO TURN ON GPS
    //IF HAS LAST LOCATION APP SHOULDN'T BOTHER USER
    useEffect(() =>{
        if(hasPermission && !isGpsOn && !gpsRequested){
            dialogRef.current?.show()
        } else {
            dialogRef.current?.hide()
        }
    },[isGpsOn, hasPermission, gpsRequested]);

    //OPEN DIALOG TO TURN ON LOCATION
    useEffect(() => {
        if (showGPSDialog) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [showGPSDialog]);

    return <Dialog ref={dialogRef} disableCloseOnPositive
                   positiveText={Strings.ok}
                   negativeText={Strings.cancel}
                   onPositivePress={onPositivePress}
                   onNegativePress={onNegativePress}>
        <View>
            <Text h1>{Strings.enable_location}</Text>
            <Text p>{Strings.turnLocationSettings}</Text>
        </View>
    </Dialog>;
};

type Props = {
    showGPSDialog?: boolean,
    setShowGPSDialog?: (active: boolean) => void;
}

const actions ={
    getCurrentPosition,
    setLocationProviderStatus,
    setGpsRequested,
};

const getReduxProps = (state:IRootReducer, props:Props) => {
    return {
        gpsRequested: state.user.gpsRequested,
        ...props
    }
};

type ReduxProps = ReturnType<typeof getReduxProps>;

export default connect(
    getReduxProps,
    actions
)(GPSLocationDialogView as React.FunctionComponent) as React.FunctionComponent<Props>
