import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import { Camera, FlashMode } from "expo-camera";
import { assignBayToBaySensor } from "../../redux/actions/sensors";
import { Routes } from "react/navigation/root/root.paths";
import { Bay } from "../../model/Bay";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { BarCodeScanner } from "expo-barcode-scanner";
import { useAppDispatch } from "../../redux/redux";

type Props = NavigationProps<Routes.AssignQRForBay>;

export default function AssignQRForBay(props: Props) {

    const [qrCode, setQrCode] = useState("");
    const [disabled, setDisabled] = useState(false);
    const {parkId, bay} = props.route.params;

    const dispatch = useAppDispatch();

    const onSuccess = (e: any) => {
        const qrcode = e.data;
        setQrCode(qrcode);
    }

    const save = () => {

        if (!qrCode || qrCode === "") {
            return;
        }

        setDisabled(true);

        dispatch(assignBayToBaySensor(qrCode, bay.id, parkId,
            () => {
                setDisabled(false);
                props.navigation.push(Routes.AssignSensorBayList, { parkId });
            }, () => {
                setDisabled(false);
            }
        ));
    }

    return (
        <View style={styles.mainContainer}>
            <ParkableBaseView toolbarTitleText={Strings.scan_bay_qr} scrollable={false}>
                <View>
                    <Text grey center strapline>{Strings.scan_bay_qr_hint}</Text>
                    <Text red center strapline bold>{`${Strings.bay_name} ${bay?.signage || ""}`}</Text>
                </View>

                <Camera
                    onBarCodeScanned={onSuccess}
                    barCodeScannerSettings={{
                        barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                    }}
                    style={styles.qrScanner}
                    flashMode={FlashMode.torch}
                />

                <View style={{flex: 1}}>
                    <View>
                        <Text grey style={{paddingLeft: 10}}>{Strings.qrCode + qrCode}</Text>
                    </View>
                    <View style={{flex: 1, alignItems: 'center', justifyContent: 'center'}}>
                        <Button
                            small
                            disabled={disabled}
                            onPress={save}
                        > {Strings.save}</Button>
                    </View>
                </View>
            </ParkableBaseView>
        </View>
    );
}

export class AssignQRForBayParams {
    parkId: number;
    bay: Bay;
}

export const AssignQRForBayRoute = createRoute({
    path: Routes.AssignQRForBay,
    params: { type: AssignQRForBayParams }
});

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1
    },
    qrScanner: {
        width: "100%",
        aspectRatio: 1,
        borderRadius: 9,
        backgroundColor:"blue",
    },
})
