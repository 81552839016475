import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Spinner from "react/parkable-components/spinner/Spinner";
import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { updateUserOptions, useUserOptions } from "../../../api/userOptions";
import Strings from "../../../constants/localization/localization";
import { createRoute } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import {useAlertSnackbar} from "../../../root/alert-snackbar/alert-snackbar";
import ParkableBaseView from "../../common/ParkableBaseView";

enum PrivacyFields {
    email = "showEmail",
    phone = "showPhone",
}

const PrivacySettingsView = () => {
    const { userOptions, isLoading, mutate } = useUserOptions();
    const {showSnackBar} = useAlertSnackbar();

    const [loading, setLoading] = useState(false);

    const handleCheck = async (field: PrivacyFields, value: boolean) => {
        setLoading(true);
        try {
            await updateUserOptions({ [field]: value });
        } catch (e) {
            showSnackBar({
                text: Strings.error,
                hideDismiss: true,
                style: {
                    backgroundColor: Colours.RED,
                },
            });
        }
        await mutate();
        setLoading(false);
    };

    return (
        <ParkableBaseView loading={loading}>
            <Text h1 style={styles.header}>
                {Strings.privacy_settings.privacy_settings}
            </Text>
            <View style={styles.scrollWrapper}>
                <Text p>{Strings.privacy_settings.settings_intro_1}</Text>
                <Text p>{Strings.privacy_settings.settings_intro_2}</Text>
                <View>
                    <Text grey bold strapline style={styles.spacer}>
                        {Strings.tandem_parking.tandem_chat}
                    </Text>
                    {isLoading && <Spinner />}
                    {!isLoading && (
                        <>
                            <View style={styles.spacer}>
                                <CheckBox
                                    initialChecked={userOptions?.privacyOptions?.showEmail}
                                    onChange={(checked) => handleCheck(PrivacyFields.email, checked)}
                                >
                                    {Strings.email}
                                </CheckBox>
                            </View>
                            <View>
                                <CheckBox
                                    initialChecked={userOptions?.privacyOptions?.showPhone}
                                    onChange={(checked) => handleCheck(PrivacyFields.phone, checked)}
                                >
                                    {Strings.phone_number}
                                </CheckBox>
                            </View>
                        </>
                    )}
                </View>
            </View>
        </ParkableBaseView>
    );
};

export default PrivacySettingsView;

export const PrivacySettingsRoute = createRoute({
    path: Routes.PrivacySettingsView,
});

const styles = StyleSheet.create({
    spacer: {
        marginBottom: 9,
    },
    mainContainer: {
        flex: 1,
    },
    header: {
        paddingTop: 9,
    },
    scrollWrapper: {
        marginBottom: 36,
    },
});
