import React from 'react';
import { StyleSheet, View, Image, Platform } from 'react-native';
import Strings from "../../constants/localization/localization";
import { Text } from 'react/parkable-components'
import ParkableBaseView from "./ParkableBaseView";
import { PADDING } from "../../root/root.constants";

const Start_EV_Session = require('../../resources/Start_EV_Session.png');
const hands = require('../../resources/hands.png');

export default function LoadingView(props: Props) {
    const {title, startingEvSession, showBackButton} = props;

    return (
        <ParkableBaseView
            showBackButtonOnToolbar={showBackButton}
            removeStandardMargins
            toolbarStyle={styles.toolbar}
        >
            <View style={styles.mainContainer}>
                <Text h1 >{title}</Text>
                <Text p >{Strings.one_moment_please}</Text>
            </View>
            <View>
                <Image style={styles.backgroundImage} source={!!startingEvSession ? Start_EV_Session : hands} />
            </View>
        </ParkableBaseView>
    );
}

type Props = {
    title:string;
    startingEvSession?: boolean | false;
    showBackButton?: boolean | false;
}

const styles = StyleSheet.create({
    toolbar: {
        paddingHorizontal: PADDING,
    },
    mainContainer: {
        flex: 1,
        padding: PADDING,
        height:'100%'
    },
    backgroundImage: {
        resizeMode: 'stretch',
        width: '100%',
        ...Platform.select({
            web: {
                left: 0,
                height:480,
            },
        })
    },
});
;
