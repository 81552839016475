import {get, put, Token} from "./rest";

export const getSensorsForPark = (api:string, token: Token, parkId:number) => get(token, `${api}v2/baysensors?parkId=${parkId}`);
export const getSensorsByQR = (api:string, token: Token, qrCode:number) => get(token, `${api}v2/baysensors?qr=${qrCode}`);

export function searchByEUI(url: string, token: Token, id: string) {
    url = `${url}v2/baysensors?devEUI=${id}`;

    return get(token, url);
}

export function assignSensorToQRCode(url: string, token: Token, sensorId: string, qrCode: any){

    url = `${url}v2/baysensors/${sensorId}`;

    const body = {
        qrCode
    };

    return put(token, url, body);
}

export function checkQRCode(api: string, token: Token, qrCode: any){
    const url = `${api}v2/baysensors?qr=${qrCode}`;

    return get(token, url);
}

export function retrieveParkSensors(api: string, token: Token, parkId: number){
    const url = `${api}v2/baysensors?parkId=${parkId}`;

    return get(token, url);
}

export function assignSensorToBay(url: string, token: Token, sensorId: string, bayId: number, parkId: number) {
    url = `${url}v2/baysensors/${sensorId}`;

    const body = {
        bay: bayId,
        park: parkId
    };

    return put(token, url, body);
}