import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { Platform } from "react-native";
import AssignSensorQrCodeView from "./AssignSensorQrCodeView";
import AssignSensorQrManualCodeView from "./AssignSensorQrManualCodeView";

type Props = NavigationProps<Routes.ScanSensorQrCodeView>

export default function QrScannerRoot(props: Props) {
    return Platform.OS === "web"
        ? <AssignSensorQrManualCodeView {...props} />
        : <AssignSensorQrCodeView {...props} />;
}

export const AssignSensorQrCodeRoute = createRoute({
    path: Routes.AssignSensorQrCodeView
})
