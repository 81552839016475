import React from "react";
import { useMemo } from "react";
import { ParkInfo } from "react/model/ParkInfo";
import {
	ParkPinMeta,
	useParkPinPressHandler,
} from "../park.constants";
import { generateEvIcon, generateEvText, getShowEvPin } from "./constants";
import { PinWrapper } from "../../pin-wrapper";
import { useReduxMaps } from "react/redux/redux";

export type ParkPinEvWebProps = {
	meta: ParkPinMeta;
	data: Pick<ParkInfo, "parkId" | "hideEVBays">;
};

export const ParkPinEvWeb = (props: ParkPinEvWebProps) => {
	const { meta, data } = props
	const { preferences } = useReduxMaps();

	const {
		hasEvCharger,
		isFavouritePark,
		isOrgMember,
		isParkOwner,
		isParkUnavailable,
		zIndex,
	} = meta;

	const { hideEVBays } = data;

	const shouldShow = getShowEvPin({
		hideEVBays,
		isParkOwner,
		hasEvCharger,
		isFavouritePark,
		isOrgMember,
		preferences
	});

	const handlePress = useParkPinPressHandler({
		parkId: data.parkId,
	});


	const icon = generateEvIcon({ isFavouritePark, isOrgMember })
	const text = generateEvText({ isOrgMember });

	const deps = [
		text,
		props.data.parkId,
		shouldShow,
		icon,
	]

	return useMemo(
		() =>
			<>
				{shouldShow && (
					<PinWrapper
						handlePress={handlePress}
						isFavouritePark={isFavouritePark}
						isOrgMember={isOrgMember}
						isParkUnavailable={isParkUnavailable}
						icon={icon}
					>
						{text}
					</PinWrapper>
				)}
			</>,
		deps)
};
