import React from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import { useOrganisation } from "../../api/organisations";
import TableRowParksComponent from "./TableRowParksComponent";
import { useCampus } from "../../api/campus/campus.api";
import Strings from "../../constants/localization/localization";
import {
    RequestState,
    useParkingRequestOptions
} from "../../api/parkingRequestOptions/parkingRequestOptions.api";
import { useParks } from "../../api/park/park.api";
import { futureParkingButtonStatus } from "./startParkingButtonStatus";
import { useEmployeeSubscriptions } from "../../api/employeeSubscription/employeeSubscription.api";
import { getSubscriptionInPark } from "../../constants/getActiveSubscriptions";
import { ParkingType } from "../../model/Types";
import { Routes } from "react/navigation/root/root.paths";
import { onParkTomorrowPress } from "../../constants/onParkTomorrowPress";
import { useCurrentUser, useUserRoles } from "../../api/user/user.api";
import { useParkingRequestsForUser } from "../../api/parking-request/parking-request.api";
import _ from "lodash";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { userIsOrgMember, userIsParkableAdmin } from "../../constants/getUserMember";

class CampusScreenProps {
    campusId: number;
    organisationId: number;
    parkingType: ParkingType;
};

const CampusScreenView = (props: NavigationProps<Routes.CampusScreenView>) => {

    const {organisationId: campusOrgId, campusId, parkingType } = props.route.params??{};

    const {campus} = useCampus(campusOrgId, campusId);
    const {parks} = useParks(campus?.parks);
    const userOrgId = !parks ? undefined : parks[0].organisation;
    const { options } = useParkingRequestOptions(userOrgId, undefined, campusId, campusOrgId);
    const { state } = options ?? {};
    const {subscriptions} = useEmployeeSubscriptions();
    const {user} = useCurrentUser();
    const { parkingRequests } = useParkingRequestsForUser(user?.id);
    const {organisation} = useOrganisation(userOrgId);

    const {userRoles} = useUserRoles();

    const isUserPrivateOrganisation = (userOrgId &&
        userIsOrgMember(userRoles, userOrgId)) ||
            userIsParkableAdmin(userRoles);

    const isFutureBooking = state === RequestState.FutureBooking;
    const allowAdvanceBooking = state && state !== RequestState.Disabled && isUserPrivateOrganisation;

    const availabilityHelpers = parks?.map(park => futureParkingButtonStatus(park, undefined, isFutureBooking));
    const futureParkingText =
        (availabilityHelpers?.find(h => h.availableToday) ??
            availabilityHelpers?.find(h => h.isParkOpenTomorrow) ??
            _.orderBy(availabilityHelpers, h => h?.nextOpenDay?.openDay)?.[0])?.futureParkingButtonText;

    const futureButtonStatus = parks?.map(park => futureParkingButtonStatus(park, getSubscriptionInPark(subscriptions??[], park.id), isFutureBooking));
    const futureButtonStatusDisabled = futureButtonStatus ? futureButtonStatus.filter(fb => fb.disableFutureParkButton).length > 0 : true;
    const futureBookingDisabled = futureButtonStatusDisabled
        || (parkingType === ParkingType.ELECTRIC && (parks ? parks.filter(p => p.allowEVReservations !== true).length > 0 : true));

    const onPFBParkTomorrowPress = () => {
        void onParkTomorrowPress(undefined, campusOrgId, campusId, user?.id, parkingRequests??[], props.navigation);
    };

    const onFutureBookingPress = () => props.navigation.push(Routes.FutureBookingView, {
        organisationId: campusOrgId,
        campusId,
        parkId: parks?.[0].id,
    });

    return (
        <ParkableBaseView >
                <View>
                    <Text h3 grey mb={4.5}>{organisation?.name}</Text>
                    <Text h1 style={styles.campusName}>{campus?.name}</Text>
                    {campus?.parks?.map((parkId) => <TableRowParksComponent key={parkId} parkId={parkId}/>)}
                    {allowAdvanceBooking && (isFutureBooking
                        ? (
                            <Button plain border form onPress={onFutureBookingPress}
                                    disabled={futureBookingDisabled}
                                    style={styles.button}>{Strings.future_booking.future_booking}</Button>
                        ) : (
                            <Button disabled={futureBookingDisabled} border center plain textProps={{ h5: true }}
                                    style={styles.button} onPress={onPFBParkTomorrowPress}>
                                {futureParkingText ?? Strings.loading}
                            </Button>
                        ))}
                </View>
        </ParkableBaseView>
    );
};

export default CampusScreenView;

export const CampusScreenRoute = createRoute({
    path: Routes.CampusScreenView,
    params: { type: CampusScreenProps }
});

const styles = StyleSheet.create({
    outerContainer: {
        flex: 1,
    },
    campusName: {
        lineHeight: 35,
        marginBottom:9
    },
    button: {
        borderColor: 'black',
        borderWidth: 2,
        marginTop: 35,
    },
});
