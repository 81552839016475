import {useMySWR} from "../http/use-swr";
import { RewardsResponse } from "./dto/Reward";
import { post } from "../../api/http/rest";
import {Nully} from "../../constants/nully";

export const useMyRewards = () => {
    return useMySWR<RewardsResponse>(`v1/reward`);
};


export const redeem = (request: {amountToRedeem: number, territoryId: number}) => {
    return post<RewardsResponse>(
        `v1/reward/redeem`,
        request
    );
};

export const useUserRewards = (userId: Nully<number>) => {
    return useMySWR<RewardsResponse>(userId ? `v1/reward/all?userId=${userId}` : null)
}
