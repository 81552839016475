import React, {FC} from 'react';
import {Dimensions, Platform, StyleSheet, TouchableOpacity} from 'react-native';
import Text from "react/parkable-components/text/Text";
import {useMakeBayAvailableContext} from "../employeeSubscription/context";

interface SiteAvailabilityTileDayOfWeekProps {
    dayOfWeek: string,
    isAvailable: boolean,
    dayOfWeekIndex: number,
}

const SCREEN_WIDTH = Dimensions.get("window").width;

const SiteAvailabilityTileDayOfWeek: FC<SiteAvailabilityTileDayOfWeekProps> = (props) => {
    const { isAvailable, dayOfWeek, dayOfWeekIndex } = props;

    const context = useMakeBayAvailableContext();

    return (
        <TouchableOpacity style={styles.dayOfWeekContainer} disabled={!isAvailable} onPress={() => {
            context?.setShowSharingRecurrenceDialog(true)
            context?.setSelectedDaysOfWeek([dayOfWeekIndex]);
        }}>
            <Text h4 bold grey style={[styles.dayTilesText, !isAvailable ? {opacity: 0.25} : {opacity: 0.99}]}>{dayOfWeek}</Text>
        </TouchableOpacity>
    );
}

const styles = StyleSheet.create({
    dayOfWeekContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 0,
        ...Platform.select({
            native: {
                height: (SCREEN_WIDTH - 21) / 10,
                width: (SCREEN_WIDTH - 21) / 10,
                margin: 6,
            },
            web: {
                flex: 1,
                maxWidth: "90%",
                aspectRatio: 1,
                margin: 17,
            }
        }),
        borderRadius: 3,
        backgroundColor: "#EDF1F3"
    },
    dayTilesText: {
        backgroundColor: 'transparent',
        marginTop: 8.
    },
});

export default SiteAvailabilityTileDayOfWeek;
