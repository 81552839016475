import React, {useRef, useState} from "react";
import { View } from "react-native";
import { connect } from "react-redux";
import { IRootReducer } from "../../../redux/reducers/main";
import { contentStyles, styles } from "./styles";
import {
    changeParkingRequestBay,
    updateParkingRequest,
    useNextConfirmedParkingRequestForCurrentUser,
    useParkingRequestsForUser
} from "../../../api/parking-request/parking-request.api";
import Button from "react/parkable-components/button/Button";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import Strings from "../../../constants/localization/localization";
import { usePark } from "../../../api/park/park.api";
import moment from "moment";
import { useBay } from "../../../api/bay/bay.api";
import { useVehicle } from "../../../api/vehicle/vehicle.api";
import { ParkingRequestStatus } from "../../../model/ParkingRequest";
import CasualCharge from "../../parkDetails/CasualCharge";
import { ParkingType } from "../../../model/Types";
import { useTerritory } from "../../../api/territory/territory.api";
import { Nully } from "../../../constants/nully";
import { LocationRow } from "../../widgets/table-rows/location-row";
import ChangeableBayRow from "../../widgets/table-rows/bay-row";
import { VehicleRow } from "../../widgets/table-rows/registration-row";
import { DateRow } from "../../widgets/table-rows/date-row";
import { OpeningHoursRow } from "../../widgets/table-rows/opening-hours-row";
import { getDayAvailabilityInternal } from "../../../constants/availability/getDayAvailability";
import { CreditCardRow } from "../../widgets/table-rows/credit-card-row";
import { showAlert } from "../../../alerts";
import { useFailedSessionTransaction } from "../../../api/parkSession/parkSession.api";
import { Routes } from "react/navigation/root/root.paths";
import TandemBayAllocatedAlert from "../../tandemParking/TandemBayAllocatedAlert";
import { createRoute, NavigationProps, useNavigation } from "../../../navigation/constants";
import ParkableBaseView from "../../common/ParkableBaseView";
import { CarParkNameRow } from "react/components/widgets/table-rows/car-park-row";
import getOpeningTime from "react/constants/availability/getOpeningTime";
import DriveTimeTableRow from "react/components/parkDetails/DriveTimeTableRow";

const getReduxProps = (state: IRootReducer) => {
    return {
        parks: state.parks.parks,
        currentUser: state.user.user,
    };
};

class ConfirmedBookingParams {
    parkingRequestId: number;
    backButtonToMapView?: boolean;
    revalidate?: () => void;
}

const _ConfirmedBookingView = (
    props: ReturnType<typeof getReduxProps> & NavigationProps<Routes.ConfirmedBookingView>
) => {
    const { currentUser } = props;
    const { parkingRequests, mutate: mutateParkingRequests } = useParkingRequestsForUser(currentUser?.id);
    const {mutate: mutateConfirmedRequest} = useNextConfirmedParkingRequestForCurrentUser();
    const navigation = useNavigation();
    const {parkingRequestId, revalidate, backButtonToMapView} = props.route.params ?? {} as ConfirmedBookingParams;

    const request = parkingRequests?.find(r => r.id === parkingRequestId);
    const { park } = usePark(request?.park);
    const { bay } = useBay(request?.park, request?.bay);
    const { vehicle } = useVehicle(request?.inVehicleId);
    const { territory } = useTerritory(park?.territory);
    const { parkSession: failedTransactionSession } = useFailedSessionTransaction();

    const confirmDialogRef = useRef<DialogRef>(null);
    const updateConfirmation = useRef<((confirmed: boolean) => void) | undefined>(undefined);

    const [loading, setLoading] = useState(false);

    if (!request) {
        return (
            <View style={[styles.container, { paddingTop: 45, flexDirection: "row", justifyContent: "center" }]}>
                <Spinner large />
            </View>
        );
    }

    const handleCancelBooking = async () => {
        confirmDialogRef.current?.show();
        const confirmed = await new Promise<boolean>((resolve) => updateConfirmation.current = resolve);
        if (!confirmed) { return }

        setLoading(true);
        try {
            await updateParkingRequest(request.id, {
                status: ParkingRequestStatus.CancelledUser
            });
            await mutateParkingRequests();
            mutateConfirmedRequest().then(() => {});
            await revalidate?.();
            navigation.pop();
        } finally {
            setLoading(false);
        }
    }

    const handleCloseConfirmation = async (confirmed: boolean) => {
        confirmDialogRef.current?.hide();
        updateConfirmation.current?.(confirmed);
    };

    const handleVehicleChange = async (vehicleId: Nully<number>) => {
        if (vehicleId && request.inVehicleId !== vehicleId) {
            setLoading(true);
            try {
                await updateParkingRequest(request.id, {
                    vehicleId
                })
                await mutateParkingRequests()
            } catch (e) {
                showAlert((e as any)?.response?.data?.message ?? Strings.unable_to_change_vehicle, Strings.whoops)
            } finally {
                setLoading(false);
            }
        }
    }

    const handleBayChange = async (bayId: Nully<number>) => {
        if (request.id != null && bayId != null && park?.id != null) {
            try {
                setLoading(true)
                await changeParkingRequestBay(request.id, bayId, park?.id)
                await mutateParkingRequests()
            } catch (e) {
                showAlert((e as any)?.response?.data?.message ?? Strings.unable_to_change_bay, Strings.whoops)
            } finally {
                setLoading(false)
            }
        }
    }

    const requestDay = moment(request.date)
    const dayAvailability = getDayAvailabilityInternal(requestDay.day(), park?.availability)

    const backToTheMapView = () => {
        if (backButtonToMapView) {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        } else {
            navigation.pop();
        }
    }

    const openingTime = getOpeningTime(dayAvailability);
    return (<ParkableBaseView backButtonOverride={backToTheMapView} loading={loading}>

        <View style={contentStyles.container}>

            <Text bold h1>{Strings.future_booking.confirmation.title}</Text>

            <Text p small>
                {Strings.future_booking.confirmation.info_text}
            </Text>

            <TandemBayAllocatedAlert bay={bay} />

            <DateRow date={moment(request.date)} />
            <OpeningHoursRow dayAvailability={dayAvailability} />
            <CarParkNameRow displayName={park?.displayName}/>
            <DriveTimeTableRow showDriveTime={false} showLocationLabel endLatitude={park?.latitude} endLongitude={park?.longitude} park={park || null} />
            {bay &&
                <ChangeableBayRow
                    hideEVBays
                    currentBay={bay}
                    organisationId={park?.organisation}
                    onChange={handleBayChange}
                    changeBayOptions={{
                        parkId: request.park!,
                        requestDate: request.date,
                        parkingType: ParkingType.CASUAL
                    }}
                />}
            <VehicleRow registration={vehicle?.registration} onChange={handleVehicleChange} />
            <CreditCardRow />
            {park && territory && <CasualCharge
                parkingType={ParkingType.CASUAL}
                territory={territory}
                park={park}
                time={openingTime}
                date={requestDay}
                bay={bay} />
            }

            {!!failedTransactionSession && <View style={styles.failedPayment}>
                <Text center>{Strings.you_have_a_failed_payment}</Text>
                <Button plain border style={[styles.failedPaymentButton]} onPress={() => {
                    navigation.push(Routes.RetryPaymentRequest, {sessionId: failedTransactionSession.id})
                }}>
                    <Text small center>{Strings.resolve}</Text>
                </Button>
            </View>}

            <View style={styles.cancelContainer}>
                <Button red border center onPress={handleCancelBooking}>
                    <Text white>{Strings.cancel_booking}</Text>
                </Button>
            </View>

        </View>

        <Dialog
            ref={confirmDialogRef}
            title={Strings.are_you_sure_you_want_to_cancel_your_booking}
            positiveText={Strings.yes}
            negativeText={Strings.no}
            negativeProps={{ red: false, textProps: { h5: true } }}
            positiveProps={{ red: true, textProps: { h5: true } }}
            onDismiss={() => handleCloseConfirmation(false)}
            onPositivePress={() => handleCloseConfirmation(true)}
            onNegativePress={() => handleCloseConfirmation(false)} />

    </ParkableBaseView>);
}

export const ConfirmedBookingView = connect(getReduxProps)(_ConfirmedBookingView);

export const ConfirmedBookingRoute = createRoute({
    path: Routes.ConfirmedBookingView,
    params: {
        type: ConfirmedBookingParams,
    }
})
