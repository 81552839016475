import {ParkAvailabilityViewParams} from "../ParkAvailabilityView";
import getParkAvailability from "../../../constants/getParkAvailability";
import moment from "moment";
import Strings from "../../../constants/localization/localization";
import {ParkDTO} from "../../../model/ParkDTO";
import {Park} from "../../../model/Park";
import {ParkDTOWithTerritory} from "../../../redux/actions/parks";

export const getAvailabilityInfo = (
    park: ParkDTO|Park|ParkDTOWithTerritory,
    heldBayId: number | null,
    vacateBayDeadline: string | null /*ISO8601*/): ParkAvailabilityViewParams | null => {
    
    // @ts-ignore
    const availability = getParkAvailability(park);
    
    const twenty4HoursFromNow = moment().add({hours:  24});
    const vacateBayDeadlineIsLessThan24HoursFromNow = !!vacateBayDeadline && moment(vacateBayDeadline)
        .subtract({seconds: 1})
        .isBefore(twenty4HoursFromNow);

    const bonusBayStyle = !!heldBayId && vacateBayDeadlineIsLessThan24HoursFromNow;

    if (availability.isOpen24hours) {
        //7x24
        if (bonusBayStyle) {
            return {
                bonusBayStyle,
                isOpen24hours: true,
                closesAtMidnightTonight: true,
                info: Strings.available_till_midnight,
                title: Strings.bay_available_till_midnight_tonight,
                description: Strings.youll_need_to_leave_by(Strings.midnight_tonight, false),
                disabled: false,
            }
        } else {
            return {
                bonusBayStyle,
                isOpen24hours: true,
                closesAtMidnightTonight: false,
                info: Strings.park_724,
                title: Strings.park_724,
                description: Strings.feel_free_to_stay_overnight,
                disabled: false,
            }
        }
    } else if(!availability.isOpenNow) {
        //park closed now
        return {
            bonusBayStyle: false,
            isOpen24hours: false,
            closesAtMidnightTonight: false,
            info: Strings.closed,
            title: Strings.sorry_this_park_is_closed, //todo
            description: '', //todo
            disabled: true,
        }
    } else if(availability.closingTime) {
        //open today, closing today
        return {
            bonusBayStyle,
            isOpen24hours: false,
            closesAtMidnightTonight: availability.isMidnightTonight,
            info: `${Strings.closes} ${availability.closingTime}`,
            title: Strings.park_closes_at_(availability.closingTime, availability.isMidnightTonight ? '' : ' today'),
            description: Strings.youll_need_to_leave_by(availability.closingTime, false),
            disabled: false,
        }
    } else if(availability.tomorrowClosingTime) {
        //open today, closing tomorrow
        return {
            bonusBayStyle,
            isOpen24hours: false,
            closesAtMidnightTonight: false,
            info: `${Strings.closes} ${availability.tomorrowClosingTime} tomorrow`,
            title: Strings.park_closes_at_(availability.tomorrowClosingTime, ' tomorrow'),
            description: Strings.youll_need_to_leave_by(availability.tomorrowClosingTime, true),
            disabled: false,
        }
    } else if (availability.otherDayClosing) {
        //open today, closing some later day
        const dayOfWeek = ` on ${availability.otherDayClosing.day.format('dddd')}`;
        
        return {
            bonusBayStyle,
            isOpen24hours: true,
            closesAtMidnightTonight: bonusBayStyle,
            info: bonusBayStyle ? Strings.available_till_midnight : `${Strings.closes} ${availability.otherDayClosing.closingTime}${dayOfWeek}`,
            title: bonusBayStyle ? Strings.bay_available_till_midnight_tonight : Strings.park_closes_at_(availability.otherDayClosing.closingTime, dayOfWeek),
            description: bonusBayStyle ? Strings.youll_need_to_leave_by(Strings.midnight_tonight, false) : Strings.youll_need_to_leave_by(`${availability.otherDayClosing.closingTime}${dayOfWeek}`, false),
            disabled: false,
        }
    } else {
        return null //todo this shouldn't happen, but hide the component if it does
    }
}
