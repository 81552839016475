import React, { PropsWithChildren, useEffect } from "react";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import { SafeAreaView, TouchableOpacity, View } from "react-native";
import { createContext, ReactNode, useContext, useRef, useState } from "react"
import {snackbarStyles} from "./styles";
import {Nully} from "../../constants/nully";

export type AlertOptions = {
    title: string
    onPositivePress?: () => any,
    onNegativePress?: () => any,
    negativeText?: string
    positiveText?: string
    children?: ReactNode
}

//may use this when adding snackbar to web so just commented for now
export type SnackOptions = {
    isStatic?: boolean,
    onPress?: Nully<() => void>,
    duration?: number,
    hideDismiss?: boolean,
    style?: any,
    tapToClose?: boolean,
    text: string
}

const AlertContext = createContext({
    showAlert: (opts: AlertOptions) => { },
    showSnackBar: (opts: SnackOptions) => { },
    dismissSnackBar: () => { }
})

export const AlertSnackbar = ({ children }: PropsWithChildren<{}>) => {
    const dialgoRef = useRef<DialogRef | null>(null)
    const [isShown, setIsShown] = useState(false);
    const [isSnackBarShown, setIsSnackBarShown] = useState(false);
    const [currentAlertOptions, setCurrentAlertOptions] = useState<AlertOptions | undefined>()
    const [currentSnackOptions, setCurrentSnackOptions] = useState<SnackOptions | undefined>()

    useEffect(() => {
        if (isShown) {
            dialgoRef.current?.show()
            return
        }
        dialgoRef.current?.hide()
    }, [isShown, dialgoRef])

    const showAlert = (opts: AlertOptions) => {
        setCurrentAlertOptions(opts)
        setIsShown(true)
    }

    const handlePositivePress = () => {
        setIsShown(false)
        currentAlertOptions?.onPositivePress?.()
    }

    const handleNegativePress = () => {
        setIsShown(false)
        currentAlertOptions?.onNegativePress?.()
    }

    const title = currentAlertOptions?.title ?? "Alert"
    const positiveText = currentAlertOptions?.positiveText ?? "Ok"
    const negativeText = currentAlertOptions?.negativeText

    const dismissSnackBar = () => {
        setIsSnackBarShown(false);
    }

    const showSnackBar = (options: SnackOptions) => {
        setCurrentSnackOptions(options);
        setIsSnackBarShown(true);
    }

    const renderTopSnackbar = () => {
        if(currentSnackOptions && isSnackBarShown) {
            return <SafeAreaView style={snackbarStyles.droidSafeArea}>
                <TouchableOpacity onPress={dismissSnackBar}>
                    <View style={[snackbarStyles.container, currentSnackOptions?.style]}>
                        <Text small center white bold onPress={() => {
                            currentSnackOptions?.onPress?.();
                            dismissSnackBar();
                        }}>{currentSnackOptions?.text}</Text>
                        {!currentSnackOptions?.hideDismiss &&
                        <Text small white bold style={snackbarStyles.dismiss}>{'\u00D7'}</Text>}
                    </View>
                </TouchableOpacity>
            </SafeAreaView>
        }
        return null;
    }

    return <AlertContext.Provider value={{ showAlert, showSnackBar, dismissSnackBar }}>
        {children}
        {renderTopSnackbar()}
        <Dialog title={title}
            ref={dialgoRef}
            positiveText={positiveText}
            negativeText={negativeText}
            onNegativePress={handleNegativePress}
            onPositivePress={handlePositivePress}
            children={currentAlertOptions?.children} />
    </AlertContext.Provider>
}

export const useAlertSnackbar = () => {
    const context = useContext(AlertContext)
    if (context === undefined) {
        throw new Error("Cannot user Alert Snackbar Context outside of it's provider")
    }
    return context
}
