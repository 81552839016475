import React from "react";
import Text from "react/parkable-components/text/Text";
import { Image, StyleSheet, View } from "react-native";
import { theme } from "react/constants/theme";

const cross_on_red = require("../../../resources/cross-on-red.png");
const tick_green = require("../../../resources/tickGreenCircle.png");

export const PasswordHelperBlock = (props: { title?: string; pass?: boolean }) => {
  const { title, pass } = props;
  return (
    <View style={styles.container}>
      {pass
        ? <Image source={tick_green} style={{height: 17, width: 17}} />
        : <Image source={cross_on_red} style={{height: 17, width: 17}} />}
      <Text small red={!pass} green={pass} style={styles.text}>{title}</Text>
    </View>
  )
}

export const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  text: {
    marginLeft: 7,
  },
});
