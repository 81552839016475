import React, {useRef, useState} from 'react';
import {StyleSheet, View} from "react-native";
import {setAPIUrl} from "../../redux/actions/data";
import Strings from "../../constants/localization/localization";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Button from "react/parkable-components/button/Button";
import Radio from "react/parkable-components/radio/Radio";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import connect from "../../constants/connect";
import Colours from "../../constants/colors";
import {isProductionApi} from '../../constants/ApiRoutes';
import {IRootReducer} from "../../redux/reducers/main";
import {DispatchFunc} from "../../model/Types";
import {createRoute, NavigationProps} from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { EnvironmentName, findConfig } from "../../hooks/useConfig";
import ParkableBaseView from "../common/ParkableBaseView";

const getReduxProps = (state: IRootReducer) => {
    return {
        api: state.data.api,
        apiName: state.data.apiName,
        service: state.data.service,
    };
}

type Props = ReturnType<typeof getReduxProps> & {dispatch: DispatchFunc} & NavigationProps<Routes.ChangeServerView>;

const ChangeServerView = (props: Props) => {

    const {
        navigation,
        dispatch,
        service: serviceName,
        apiName
    } = props;

    const serviceDialogRef = useRef<DialogRef|null>(null);
    const [serviceRadioValue, setServiceRadioValue] = useState<"default"|"custom">(serviceName?.length ? "custom" :"default");
    const [selectedApiName, setSelectedApiName] = useState(apiName);
    const [service, setService] = useState(serviceName ?? "");
    const [click, setClick] = useState(0);
    const [showDevServers, setShowDevServers] = useState(false);
    const [loading, setLoading] = useState(false);

    const isDefaultService = serviceRadioValue === "default";

    const onServiceDialogPositivePress = () => {
        const api = findApi(selectedApiName);
        if(isDefaultService) {
            onSetApiUrl(api.api, api.name);
        } else if(service.length) {
            onSetApiUrl(api.serviceUrl!(service), api.name, service);
        }
    }

    const onSetApi = (newApiName: EnvironmentName) => {
        const api = findApi(newApiName);
        if(api.serviceUrl) {
            setSelectedApiName(newApiName);
            serviceDialogRef.current?.show();
        } else {
            onSetApiUrl(api.api, api.name);
        }
    };

    const findApi = (apiName: EnvironmentName) => {
        const config = findConfig(apiName);
        if (!config) {
            throw new Error("couldn't find api, " + apiName);
        }
        return {
            ...config.api,
            api: config.api.url,
        }
    }

    const onSetApiUrl = (api: string, apiName: string, service?: string) => {

        setLoading(true);

        setTimeout(() => {
            dispatch(setAPIUrl(api, apiName, service));
            navigation.goBack();
        }, 777);
    }

    const changeMoreServers = () => {
        if(click + 1 > 4) {
            setShowDevServers(true);
        } else {
            setClick(click + 1);
        }
    }

    //console.log('server', apiName);

    const productionSelected = isProductionApi(apiName);

    return (
        <ParkableBaseView loading={loading}>
                <View style={styles.buttonContainer}>
                    <Text h2
                          // @ts-ignore
                          black
                          suppressHighlighting
                          onPress={changeMoreServers}>{Strings.choose_server}</Text>
                    <Text grey
                        // @ts-ignore
                          h6>
                        {Strings.demoExplanation}</Text>
                    <Button style={{marginBottom: 18, marginTop: 56}}
                        center
                        plain={!productionSelected}
                        border={!productionSelected}
                        onPress={() => onSetApi('production')}>
                        {Strings.live}
                    </Button>
                    <Button
                            center
                            plain={apiName !== "demo"}
                            border={apiName !== "demo"}
                            onPress={() => onSetApi('demo')}>
                        {Strings.demo}
                    </Button>
                    {showDevServers && <Button style={{marginBottom: 18, marginTop: 18}}
                        center
                        plain={apiName !== "qa"}
                        border={apiName !== "qa"}
                        onPress={() => onSetApi('qa')}>
                        {"QA"}
                    </Button>}
                    {showDevServers && <Button style={{marginBottom: 18}}
                            center
                            plain={apiName !== "dev"}
                            border={apiName !== "dev"}
                            onPress={() => onSetApi('dev')}>
                        {"Dev"}
                    </Button>}
                    {showDevServers && <Button style={{marginBottom: 18}}
                        center
                        plain={apiName !== "local"}
                        border={apiName !== "local"}
                        onPress={() => onSetApi('local')}>
                        {"Local"}
                    </Button>}
                </View>

                <Dialog
                    ref={serviceDialogRef}
                    label={Strings.service_dialog.title}
                    labelProps={{ style: { color: Colours.Black, textAlign: "left" } }}
                    title={Strings.service_dialog.message}
                    titleProps={{ style: { textAlign: "left" }, h2: undefined }}
                    positiveText={Strings.ok}
                    negativeText={Strings.cancel}
                    disableCloseOnPositive={!isDefaultService && !service.length}
                    onPositivePress={onServiceDialogPositivePress}
                    onNegativePress={() => serviceDialogRef.current?.hide()}
                >
                    <Radio
                        initialValue={"default"}
                        value={serviceRadioValue}
                        onChange={e => setServiceRadioValue(e.value)}
                        options={[
                            { label: Strings.service_dialog.default, value: "default" },
                            { label: Strings.service_dialog.custom, value: "custom" }
                        ]}
                    />
                    {!isDefaultService && <Input label={Strings.service_dialog.service_name_placeholder}
                                                 autoCapitalize={"none"}
                                                 value={service}
                                                 onChangeText={setService} />}
                </Dialog>
            </ParkableBaseView>
    )
};

export default connect(getReduxProps)(ChangeServerView);

export const ChangeServerRoute = createRoute({
    path: Routes.ChangeServerView,
});

const styles = StyleSheet.create({
    buttonContainer: {
        marginTop: 14,
        marginBottom: 27,
    }
});
