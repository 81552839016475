import React, { PropsWithChildren, ReactNode } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import Button, { ButtonProps } from "../button/Button";
import Card, { CardProps } from "../card/Card";
import Icon, { IconProps } from "../icon/Icon";
import { IconName } from "../icon/Icons";
import Colours from "../styles/Colours";
import Text, { TextProps } from "../text/Text";
import classnames from "../util/classnames";

const styles = StyleSheet.create({
    base: {
        flexDirection: "row",
        alignItems: "center",
    },
    content: {
        flex: 1,
        paddingVertical: 9,
        borderBottomWidth: 1,
        borderBottomColor: Colours.GREY_BORDER,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
    condensed: {
        paddingVertical: 4,
    },
    contentInner: {
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
    },
    textContent: {
        flex: 1,
        marginLeft: 9,
    },
    button: {
        width: 111,
    },
    label: {
        fontSize: 13,
        lineHeight: 18,
    },
    chevronTextStyle: {
        flex: 1,
        alignItems: 'flex-end'
    },
});

const cn = classnames(styles);

export type TableRowProps = PropsWithChildren<
    {
        baseStyle?: ViewStyle;
        textProps?: TextProps;
        label?: String;
        labelBottom?: String;
        labelProps?: TextProps;
        chevron?: boolean;
        chevronText?: string;
        buttonText?: string;
        onButtonPress?: () => void;
        buttonProps?: Omit<ButtonProps, "onPress">;
        iconLeft?: IconName;
        iconLeftProps?: Omit<IconProps, "name">;
        iconRight?: IconName;
        iconRightProps?: Omit<IconProps, "name">;
        contentLeft?: ReactNode;
        contentRight?: ReactNode;
        hideBorder?: boolean;
        backgroundColor?: string;
        condensed?: boolean;
        chevronTextProps?: TextProps;
    } & CardProps
>;
export default function TableRow(props: TableRowProps) {
    const {
        children,
        textProps,
        label,
        labelProps,
        chevron,
        chevronText,
        buttonText,
        onButtonPress,
        buttonProps,
        iconLeft,
        iconLeftProps,
        iconRightProps,
        contentLeft,
        contentRight,
        labelBottom,
        hideBorder,
        condensed,
        chevronTextProps,
        ...otherProps
    } = props;
    let { iconRight } = props;
    if (chevron) {
        iconRight = "cheveronright";
    }

    const buttonStyle = [styles.button, buttonProps?.style];

    const baseStyle = [
        styles.base,
        props.backgroundColor ? { backgroundColor: props.backgroundColor } : {},
    ];

    const contentStyle = [
        cn.styles("content", {
            condensed: condensed,
        }),
        props.hideBorder ? { borderBottomWidth: 0 } : {},
    ];

    const labelStyle = [styles.label, labelProps?.style];

    const _chevronTextStyle = [styles.chevronTextStyle, chevronTextProps?.style];

    const contentInnerStyle = (!!chevronText) ? [styles.contentInner, {flex: 4}]: styles.contentInner;

    return (
        <Card elevation={0} {...otherProps} style={baseStyle}>
            <View style={contentStyle}>
                <View style={contentInnerStyle}>
                    {contentLeft && contentLeft}
                    {iconLeft && (
                        <Icon {...(iconLeftProps || {})} name={iconLeft} />
                    )}
                    <View style={styles.textContent}>
                        {!!label && (
                            <Text
                                small
                                grey
                                {...(labelProps || {})}
                                style={labelStyle}
                            >
                                {label}
                            </Text>
                        )}
                        <Text
                            textBreakStrategy={"highQuality"}
                            small
                            {...(textProps || {})}
                        >
                            {children}
                        </Text>
                        {!!labelBottom && (
                            <Text
                                small
                                grey
                                {...(labelProps || {})}
                                style={labelStyle}
                            >
                                {labelBottom}
                            </Text>
                        )}
                    </View>
                </View>
                {!!chevronText && (
                    <View style={_chevronTextStyle}>
                        <Text small>{chevronText}</Text>
                    </View>
                )}
                <View>
                    {buttonText ? (
                        <Button
                            small
                            {...(buttonProps || {})}
                            onPress={onButtonPress}
                            style={buttonStyle}
                        >
                            {buttonText}
                        </Button>
                    ) : (
                        iconRight && (
                            <Icon
                                small={chevron}
                                {...(iconRightProps || {})}
                                name={iconRight}
                            />
                        )
                    )}
                    {contentRight && contentRight}
                </View>
            </View>
        </Card>
    );
}
