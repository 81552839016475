import { Alert, Platform } from "react-native";
import Strings from './constants/localization/localization';

export function showAlert(msg: string, title?: string) {

    console.log("function showAlert ", msg, title);
    if(msg === 'SERVICE_NOT_AVAILABLE'){
        console.trace();
    }

    if(Platform.OS === "web") {
        window?.alert((title || Strings.error) + '\n\n' + msg)
        return;
    }

    Alert.alert(
        title || Strings.error,
        msg,
        [{text: Strings.ok, onPress: () => console.log('OK Pressed')},],
        {cancelable: false}
    )
}

export function showConfirmation(
    msg: string,
    title?: string,
    cancelable?: boolean,
    resultCallback?: (confirmed: boolean) => void,
    confirmText?: string,
    cancelText?: string) {

    const callback = resultCallback ?? (() => console.log("button pressed"));

    if(Platform.OS === "web") {
        let result = true;
        if(cancelable) {
            result = window?.confirm((title || Strings.error) + '\n\n' + msg)
        } else {
            window?.alert((title || Strings.error) + '\n\n' + msg)
        }
        resultCallback?.(result);
        return;
    }

    Alert.alert(
        title || Strings.success,
        msg,
        [
            ...(cancelable ? [{text: cancelText || Strings.cancel, onPress: () => callback(false)}] : []),
            {text: confirmText || Strings.ok, onPress: () => callback(true)}
        ],
        {cancelable: cancelable, onDismiss: () => callback(false)}
    )
}

export const showConfirmationPromise = (
    msg: string,
    title: string,
    cancelable: boolean,
    confirmText: string,
    cancelText?: string): Promise<boolean> =>
    new Promise(resolve => showConfirmation(msg, title, cancelable, result => resolve(result), confirmText, cancelText));
