/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 29/08/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, { PropsWithChildren } from "react";
import { StyleSheet, Text as TextComponent } from "react-native";
import Colours from "../../constants/colors";
import { FONT_BOLD, FONT_REGULAR } from "../../constants/constants";

interface TextProps {
    blue?: boolean,
    weirdBlue?: boolean,
    black?: boolean,
    orange?: boolean,
    wGrey?: boolean,
    grey?: boolean,
    purple?: boolean,
    red?: boolean,
    green?: boolean,
    darkGrey?: boolean,
    lightGrey?: boolean,
    darkBlue?: boolean,

    lineHeight?: number,
    regular?: boolean,
    bold?: boolean,
    center?: boolean,
    right?: boolean,

    big?: boolean,
    mediumBig?: boolean,
    medium?: boolean,
    h1?: boolean,
    h2?: boolean,
    h3?: boolean,
    h4?: boolean,
    h5?: boolean,
    h6?: boolean,
    h7?: boolean,
    h8?: boolean,
    h9?: boolean,
    p?: boolean,

    mb?: number,
    mt?: number,
    ml?: number,
    mr?: number,
    pad?: number,
    pb?: number,
    pt?: number,
    pl?: number,
    pr?: number,
    m?: number,

    style?: any,
}

const styles = StyleSheet.create({
    mainContainer: {},
});

const Text = (props : PropsWithChildren<TextProps>) => {

    // noinspection PointlessBooleanExpressionJS
    let textColor = Colours.White;

    if (!!props.blue) {
        textColor = Colours.ParkableBlue;
    }
    if (!!props.weirdBlue) {
        textColor = Colours.ParkableWeirdBlue;
    }
    if (!!props.black) {
        textColor = Colours.Black;
    }
    if (!!props.orange) {
        textColor = Colours.ParkableOrange;
    }
    if (!!props.wGrey) {
        textColor = "#C2CDD6";
    }
    if (!!props.grey) {
        textColor = Colours.ParkableLightGrey;
    }
    if (!!props.darkGrey) {
        textColor = Colours.ParkableDarkGrey;
    }
    if (!!props.purple) {
        textColor = Colours.Purple;
    }
    if (!!props.red) {
        textColor = Colours.Red;
    }
    if (!!props.green) {
        textColor = Colours.ParkableGreen;
    }
    if (!!props.lightGrey) {
        textColor = Colours.ParkableXtraLightGrey;
    }
    if (!!props.darkBlue) {
        textColor = "#294F6E";
    }

    let fontFamily = FONT_REGULAR;
    if (!!props.bold) {
        fontFamily = FONT_BOLD;
    }

    let fontSize = 14;
    if (!!props.big) {
        fontSize = 60
    }
    if (!!props.mediumBig) {
        fontSize = 50
    }
    if (!!props.medium) {
        fontSize = 45
    }
    if (!!props.h1) {
        fontSize = 32
    }
    if (!!props.h2) {
        fontSize = 28
    }
    if (!!props.h3) {
        fontSize = 26
    }
    if (!!props.h4) {
        fontSize = 22
    }
    if (!!props.h5) {
        fontSize = 20
    }
    if (!!props.h6) {
        fontSize = 18
    }
    if (!!props.h7) {
        fontSize = 16
    }
    if (!!props.h8) {
        fontSize = 14
    }
    if (!!props.h9) {
        fontSize = 10
    }
    if (!!props.p) {
        fontSize = 12
    }

    let textAlign = "left";
    if (!!props.center) {
        textAlign = "center";
    }
    if (!!props.right) {
        textAlign = "right";
    }

    let extraStyles = {} as any;

    if (!!props.pad) {
        extraStyles.padding = props.pad;
    }

    if (!!props.pl) {
        extraStyles.paddingLeft = props.pl;
    }
    if (!!props.pr) {
        extraStyles.paddingRight = props.pr;
    }
    if (!!props.pt) {
        extraStyles.paddingTop = props.pt;
    }
    if (!!props.pb) {
        extraStyles.paddingBottom = props.pb;
    }

    if (!!props.m) {
        extraStyles.margin = props.m;
    }

    if (!!props.ml) {
        extraStyles.marginLeft = props.ml;
    }
    if (!!props.mr) {
        extraStyles.marginRight = props.mr;
    }
    if (!!props.mt) {
        extraStyles.marginTop = props.mt;
    }
    if (!!props.mb) {
        extraStyles.marginBottom = props.mb;
    }

    return (
        <TextComponent {...props} style={[styles.mainContainer, {
            color: textColor,
            fontFamily: fontFamily,
            fontSize: fontSize,
            textAlign: textAlign,
            lineHeight: props.lineHeight,
            backgroundColor: 'transparent',
        }, extraStyles, props.style]}>{props.children}</TextComponent>
    );
}

export default Text;
