import React, {useEffect, useState} from 'react';
import Strings from "../../constants/localization/localization";
import {StyleSheet, TouchableOpacity} from 'react-native';
import Text from "react/parkable-components/text/Text";
import Colours from "react/parkable-components/styles/Colours";
import {UserOptions} from "../../model/UserOptions";
import getParkAvailability from "../../constants/getParkAvailability";
import {userIsOrgMember} from "react/constants/getUserMember";
import {useUserRoles} from "react/api/user/user.api";
import {ParkDTO} from "react/model/ParkDTO";

type Props = {
    navigateToNotificationOptions: () => void,
    userOptions?: UserOptions,
    park: ParkDTO | undefined,
}
const EndSessionReminderBox = (props: Props) => {

    const {navigateToNotificationOptions, userOptions, park} = props;

    const [displayNothing, setDisplayNothing] = useState(true);

    const {userRoles} = useUserRoles();
    const privateOrgMember = userIsOrgMember(userRoles, park?.organisation);

    useEffect(() => {
        if(!park || !userOptions || !userOptions.parkingRemindersEnabled || privateOrgMember) {
            setDisplayNothing(true);
            return;
        }

        const availability = getParkAvailability(park);

        console.log("Park closes in " + availability.hoursUntilClose + " hours");
        if(userOptions && userOptions.parkingReminderHours && (availability.permanentlyAvailable || userOptions.parkingReminderHours < availability.hoursUntilClose)){
            setDisplayNothing(false);
        }
    }, [park, userOptions, privateOrgMember]);

    if(displayNothing){
        return null;
    }

    return <TouchableOpacity activeOpacity={0.65} style={styles.main} onPress={navigateToNotificationOptions}>
        <Text bold>{Strings.staying_longer_question(userOptions?.parkingReminderHours || 12)}</Text>
        <Text><Text style={{textDecorationLine: 'underline'}}>{Strings.change_your_settings}</Text>{Strings.change_your_settings_sentence_end}</Text>
    </TouchableOpacity>
}

export default EndSessionReminderBox;

const styles = StyleSheet.create({
    main: {
        width: "100%",
        borderColor: Colours.GREY_10,
        borderWidth: 1,
        padding: 7,
        marginBottom: 9
    },
});
