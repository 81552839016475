import {useMySWR} from "../http/use-swr";
import {TerritoriesResponse} from "./dto/TerritoriesResponse";
import {Nully} from "../../constants/nully";
import {TerritoryDTOResponse} from "./dto/TerritoryDTO";
import {get} from "../http/rest";

export const useTerritories = () => {
    return useMySWR<TerritoriesResponse>(
        `v1/territories`
    );
};

export const useTerritory = (territoryId: Nully<string | number>) => {
    return useMySWR<TerritoryDTOResponse>(
        territoryId ? `v1/territories/${territoryId}` : null
    );
};

export const getTerritory = (territoryId: number) => get<TerritoryDTOResponse>(`v1/territories/${territoryId}`);