import React, { useEffect } from "react";
import { FlatList, StyleSheet, View } from "react-native";
import _ from "lodash";
import { Colours } from "../../constants/colors";
import Strings from "../../constants/localization/localization";
import connect from "../../constants/connect";
import Text from "../common/Text";
import { Routes } from "react/navigation/root/root.paths";
import { retrieveParkSensors } from "../../redux/actions/sensors";
import moment from "moment";
import { IRootReducer } from "../../redux/reducers/main";
import { DispatchFunc } from "../../model/Types";
import { Bay } from "../../model/Bay";
import { userIsParkableAdmin, userIsSensorAdmin } from "../../constants/getUserMember";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import Button from "react/parkable-components/button/Button";
import { useAppDispatch } from "../../redux/redux";

type AssignSensorBayListProps = NavigationProps<Routes.AssignSensorBayList>;

const getReduxProps = (state: IRootReducer, props: AssignSensorBayListProps) => {

    const { parkId } = props.route.params;
    const park = state.parks.parks[parkId];

    const userRoles = state.user.userRoles;

    const isAdmin = userIsParkableAdmin(userRoles);
    const isBaySensorAdministrator = isAdmin || userIsSensorAdmin(userRoles);

    const tokenObject = {
        firebaseToken: state.auth.fireBaseToken
    };

    return {
        park: park,
        user: state.user.user,
        api: state.data.api,
        token: tokenObject,
        isAdmin: isAdmin,
        isBaySensorAdministrator,
        bays: (state.sensors.bays || {})[parkId] || [],
        sensors: state.sensors.sensors,
        parkBaysLoading: state.sensors.parkBaysLoading,
        dateformat: state.settings.dateformat
    };
}

type Props = AssignSensorBayListProps & ReturnType<typeof getReduxProps> & {dispatch: DispatchFunc};

function AssignSensorBayList(props: Props) {

    const dispatch = useAppDispatch();

    const { parkId } = props.route.params;

    useEffect(() => {
        dispatch(retrieveParkSensors(parkId));
    }, []);

    const renderSeparator = () => {
        return (
            <View
                style={{
                    height: 1,
                    width: '86%',
                    backgroundColor: Colours.BORDER_GREY,
                }}
            />
        );
    };

    const renderSensorHeartbeatTime = (bay: Bay) => {

        const match = _.filter(props.sensors, function(s) { return s.id === bay.sensor; })[0];
        if(match === undefined){
            return null;
        }
        else if(!match.lastHeartbeat){
            return <Text h7 darkGrey>{Strings.not_seen}</Text>
        }
        else{
            return <Text h7 darkGrey>{Strings.last_heartbeat} {moment(match.lastHeartbeat).format(`${props.dateformat} hh.mma`)}</Text>
        }
    }

    const sensorListItemBackgroundColour = (bay: Bay) => {
        if(!bay.sensor){
            return Colours.White;
        }
        else{
            const match = _.filter(props.sensors, function(s) { return s.id === bay.sensor; })[0];

            if(match === undefined){
                return Colours.White;
            }
            else if(match.lastHeartbeat == null){
                return Colours.LIGHT_RED;
            }
            else{
                return Colours.ParkableLightGreen;
            }
        }
    }

    const renderSensorText = (bay: Bay) => {
      if(!bay.sensor){
          return <Text h7 lightGrey>{Strings.sensor} {Strings.no_sensor}</Text>
      }
      return <Text h7 darkGrey>{bay.sensor}</Text> ;
    }

    const renderAssignBtn = (bay: Bay) => {
        return (
            <Button small plain onPress={() => onAssignSensor(bay)}>
                {bay.sensor ? Strings.reassign : Strings.assign}
            </Button>
        );
    };

    const onAssignSensor = (bay: Bay) => {
        props.navigation.push(Routes.AssignQRForBay, {parkId, bay});
    }

    const filterBays = (bays: Bay[]) => {
      bays = _.filter(bays, b => b.type === "Visitor").sort((b1, b2) => {
        return ('' + b1.signage).localeCompare(b2.signage??'');
      })

      return bays;
    }

    const { bays } = props;

    const baysAvailable = filterBays(bays);

    return (
        <View style={styles.mainContainer}>
            <ParkableBaseView
                scrollable={false}
                toolbarTitleText={Strings.assign_sensor}
                loading={props.parkBaysLoading}
            >
                <FlatList
                    style={{flex: 1}}
                    data={baysAvailable}
                    showsVerticalScrollIndicator={false}
                    renderItem={({ item }) =>
                        <View style={{flex: 1, paddingLeft: 20, paddingTop: 5, paddingBottom: 5, backgroundColor: sensorListItemBackgroundColour(item)}}>
                            <View style={styles.itemWrapper}>
                                <View >
                                    <Text h4 darkGrey>{Strings.bay_name} {item.signage}</Text>
                                    {renderSensorText(item)}
                                </View>
                                <View style={styles.itemButton}>
                                    {renderAssignBtn(item)}
                                </View>
                            </View>
                            {renderSensorHeartbeatTime(item)}
                        </View>
                    }
                    keyExtractor={item => (item.id + "")}
                    ItemSeparatorComponent={renderSeparator}
                />
            </ParkableBaseView>
        </View>
    )
}

export default connect(getReduxProps)(AssignSensorBayList as any) as React.FC<AssignSensorBayListProps>;

export class AssignSensorBayListParams {
    parkId: number;
}

export const AssignSensorBayListRoute = createRoute({
    path: Routes.AssignSensorBayList,
    params: { type: AssignSensorBayListParams }
});

const styles = StyleSheet.create({
    mainContainer: {
        flex: 1
    },
    itemWrapper:{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    itemButton:{
        textAlign: 'right',
        width: 150,
    }
});
