import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import Strings from "../../constants/localization/localization";
import { Routes } from "react/navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import Button from "react/parkable-components/button/Button";
import Input from "react/parkable-components/input/Input";
import { FirebaseService } from "../../services/firebase.service";
import { getAdminCustomToken } from "../../api/auth";
import { useSelector } from "../../redux/redux";
import useAuthToken from "../../constants/useAuthToken";

export default function AdminLoginAsUserView(props: NavigationProps<Routes.AdminLoginAsUserView>) {

    const { navigation } = props;
    const { api } = useSelector(state => state.data);
    const token = useAuthToken();

    const [email, setEmail] = useState("");
    const [submitting, setSubmitting] = useState(false);

    async function onSubmit() {
        setSubmitting(prevState => !prevState);
        try {
            const { firebaseCustomToken } = await getAdminCustomToken(token, api, email);
            await FirebaseService.signInWithCustomToken(firebaseCustomToken);
        } finally {
            setSubmitting(false);
            navigation.pop();
        }
    }

    return (
        <ParkableBaseView toolbarTitleText={Strings.login_as} scrollable={false}>
            <View style={styles.main}>
                <Input returnKeyType={"go"}
                       autoFocus={true}
                       autoCorrect={false}
                       autoComplete={"email"}
                       keyboardType={"email-address"}
                       autoCapitalize={"none"}
                       placeholder={"Email"}
                       onChangeText={(e) => setEmail(e)}
                       value={email}
                       onSubmitEditing={onSubmit} />
                <Button center
                        disabled={!email || submitting}
                        loading={submitting}
                        onPress={onSubmit}>
                    {Strings.log_in}
                </Button>
            </View>
        </ParkableBaseView>
    );
}

export const AdminLoginAsUserRoute = createRoute({
    path: Routes.AdminLoginAsUserView
});

const styles = StyleSheet.create({
    main: {
        flex: 1,
        paddingTop: 20,
        justifyContent: "space-between"
    }
});
