import React, { useRef, useState, useEffect, useCallback } from "react";
import { View, KeyboardAvoidingView, Platform, Keyboard } from 'react-native';
import { connect } from "react-redux";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Input from "react/parkable-components/input/Input";
import Text from "react/parkable-components/text/Text";
import Dialog from "react/components/dialog/Dialog";
import { IRootReducer } from "../../redux/reducers/main";
import { showSaveFavouriteModal, saveFavouritePark } from "../../redux/actions/userOptions";
import Strings from '../../constants/localization/localization'
import { UserOptions } from "../../model/UserOptions";
import { ParkDTOWithTerritory } from "../../redux/actions/parks";

interface SaveFavouriteParam {
    result: boolean,
    details: any
}

type Props = {
    park: Pick<ParkDTOWithTerritory, 'id'> | undefined,
    saveFavouriteCallback?: (param: SaveFavouriteParam) => void;
}
const SaveFavourite = ({isShowing, park, saveFavouriteCallback, showSaveFavouriteModal, saveFavouritePark}: Props & ReturnType<typeof getReduxProps> & typeof actions) => {
    const dialogRef = useRef<DialogRef|null>(null);


    useEffect(() => {
        console.log('show save fav dialog ', isShowing);
        if (isShowing) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [isShowing]);
    const [parkLabel, setParkLabel] = useState<string|undefined>(undefined);
    const [error, setError] = useState<string|undefined>(undefined);
    const [showTitle, setShowTitle] = useState(true);
    const [loading, setLoading] = useState(false);

    const onSave = () => {
        if (!park) {return}
        if(!parkLabel) {
            setError(Strings.label_cannot_be_empty)
        } else {
            Keyboard.dismiss();
            setError(undefined);
            setLoading(true);
            saveFavouritePark(parkLabel, park.id, onSuccess, onError);
        }
    };
    const onCancel = () => {
        setError(undefined);
        showSaveFavouriteModal(false)
    };
    const onSuccess = (userOptions:UserOptions) => {
        onCancel();
        saveFavouriteCallback?.({result: true, details: userOptions});
    };
    const onError = (err:any) => {
        onCancel();
        saveFavouriteCallback?.({result: false, details: err});
    };

    useEffect(() => {
        const keyboardDidShowListener = Keyboard.addListener(
            'keyboardDidShow',
            keyboardDidShow,
        );

        return () => keyboardDidShowListener.remove();
    }, []);

    useEffect(() => {
        const keyboardDidHideListener = Keyboard.addListener(
            'keyboardDidHide',
            keyboardDidHide,
        );

        return () => keyboardDidHideListener.remove();
    }, []);

    const keyboardDidShow = useCallback(() => {
        // console.log("Keyboard show");
        // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setShowTitle(false);
    },[]);

    const keyboardDidHide = useCallback(() => {
        // console.log("Keyboard hide");
        // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setShowTitle(true);
    },[]);

    return  <Dialog ref={dialogRef} disableCloseOnPositive
                    onModalHide={onCancel} //in case when user click outside of dialog
                    positiveText={Strings.save}
                    negativeText={Strings.cancel}
                    positiveProps={{textProps: {h4: true}, disabled: loading}}
                    negativeProps={{textProps: {h4: true}, disabled: loading}}
                    onPositivePress={onSave}
                    onNegativePress={onCancel}
                    >
                <KeyboardAvoidingView
                        behavior={Platform.OS == "ios" ? "padding" : "height"} >
                    <View style={{paddingTop: 16}}>
                        {showTitle && <Text h2 style={{marginBottom:3}}>
                            {Strings.save_to_favourites}
                        </Text> }
                        {showTitle && <Text h5 style={{marginBottom:21}}>
                            {Strings.love_to_save_carpark}
                        </Text> }
                        <Input label={Strings.label_carpark} placeholder={Strings.eg_work} value={parkLabel} onChangeText={e => setParkLabel(e)} error={error}/>
                    </View>
        </KeyboardAvoidingView>
    </Dialog>
};

const actions = {
    showSaveFavouriteModal,
    saveFavouritePark
};

const getReduxProps = (state: IRootReducer) => {
    const isShowing = state.userOptions.showSaveFavouriteModal;
    return {isShowing}
};

export default connect(getReduxProps, actions)(SaveFavourite)
