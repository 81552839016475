import React, { useContext, useEffect } from "react";
import { ChatContext, ProblemActionContext } from "../Context";
import { Routes } from "react/navigation/root/root.paths";
import { AllProblemViewProps } from "../ProblemView";
import { useBay } from "../../../api/bay/bay.api";
import { useParkSessionV3 } from "../../../api/parkSession/parkSession.api";

export const MessageTandemParkers = (props: AllProblemViewProps) => {

    const { navigation } = props;
    const {parkId, sessionId, bayId} = props.route.params;

    const { bay } = useBay(parkId, bayId);
    const { parkSession: session } = useParkSessionV3(sessionId);

    const {messages} = useContext(ChatContext)
    const {problemActions, updateProblemActions } = useContext(ProblemActionContext)

    useEffect(()=> {
        problemActions.onMessageTandemParkersPress = onMessageTandemParkersPress;
        updateProblemActions(problemActions);
    }, [messages])

    const onMessageTandemParkersPress = () => {
        navigation.push(Routes.TandemChatView,{
            sessionId: session?.id,
            tandemPodId: bay?.tandemPod ?? undefined,
        });
    };

    return <></>
}
