import {Platform} from "react-native";
import { UserDTO } from "./api/user/dto/user.dto";
import * as Device from "expo-device";
import { Settings, AppEventsLogger } from "react-native-fbsdk-next";
import {Territory} from "./model/Territory";
import {getPark} from "./api/park/park.api";
import {getTerritory} from "./api/territory/territory.api";
import { requestTrackingPermissionsAsync } from "expo-tracking-transparency";

let initialised = false;
let facebookLoggingAllowed = Platform.OS === 'android';

export const setFacebookLoggingAllowed = async (allowed: boolean) => {
    facebookLoggingAllowed = allowed;
    if (!Device.isDevice || Platform.OS === "web") {
        return;
    }

    if (initialised) {
        return;
    }
    const { status } = await requestTrackingPermissionsAsync();

    if (facebookLoggingAllowed) {
        Settings.initializeSDK();
        if (status === "granted" && Platform.OS === "ios") {
            await Settings.setAdvertiserIDCollectionEnabled(true);
            await Settings.setAdvertiserTrackingEnabled(true);
        }
    }
};

export const getCurrencyCode = async (territory: Pick<Territory, 'currencyCode'> | undefined, parkId: number): Promise<string | undefined> => {
    if (territory) {
        return territory.currencyCode;
    }
    const park = (await getPark(parkId)).park;
    if (park) {
        return (await getTerritory(park.territory)).territory?.currencyCode;
    }
}

export const logEvent = (
  priceInfo: undefined | { price: number, currencyCode: string },
  facebookEventName: string,
  facebookParams: { [key: string]: number | string }) => {

    if (!Device.isDevice || Platform.OS === "web") {
        return;
    }

    try {
        if (facebookLoggingAllowed) {
            try {
                if (priceInfo !== undefined) {
                    void AppEventsLogger.logPurchase(priceInfo.price, priceInfo.currencyCode, facebookParams);
                } else {
                    void AppEventsLogger.logEvent(facebookEventName, facebookParams);
                }
            } catch (e) {
                console.log("error on send facebook analytics", e);
            }
        }

        try {
        } catch (e) {
            console.log('error on send firebase analytics', e)
        }

    } catch (e) {
        console.log("error on sending analytics", e);
    }

}

export const setUserForAnalytics = (user: UserDTO) => {
    if (!Device.isDevice || Platform.OS === "web") {
        return
    }

    if (user.id) {
        AppEventsLogger.setUserID(`${user.id}`);
    }
    if (user.email) {
        AppEventsLogger.setUserData({email: user.email});
    }
}
