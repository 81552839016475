import { useMySWR } from "../http/use-swr";
import { Nully } from "../../constants/nully";
import { AccessGatesResponse } from "./dto/AccessGate.dto";

export const useAccessGatesInPark = (parkId: Nully<number>) => {
    const resp = useMySWR<AccessGatesResponse>(parkId ? `v1/accessgate/park/${parkId}` : null);

    return {
        ...resp,
        gates: resp.gates?.filter(g => g.showGateInMobileApp)
    }
}
