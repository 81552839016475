import React, {useState} from "react";
import {IRootReducer} from "react/redux/reducers/main";
import Colors from "../../constants/colors";
import {Constants} from "../../constants/constants";
import {connect} from "react-redux";
import {StyleSheet, View} from 'react-native';
import Strings from "../../constants/localization/localization";
import {checkQRCode, checkSensorReceived} from "react/redux/actions/sensors";
import moment from 'moment';
import {Camera} from "expo-camera";
import {BarCodeEvent, BarCodeScanner} from "expo-barcode-scanner";
import {createRoute, NavigationProps, useNavigation} from "react/navigation/constants";
import {Routes} from "react/navigation/root/root.paths";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import ParkableBaseView from "../common/ParkableBaseView";

const getReduxProps = (state: IRootReducer) => {
    const park = state.parks.parks[state.sensors.scannedSensor?.park ?? 0];
    let bay = undefined;
    if (!!park) {
        const bays = state.parks.bays[park.id];
        bay = bays?.[state.sensors.scannedSensor?.bay ?? 0];
    }
    return {
        sensor: state.sensors.scannedSensor,
        park,
        bay,
        dateformat: state.settings.dateformat
    }
}

const actions = {
    checkQRCode,
    checkSensorReceived,
};

export type ScanSensorProps = typeof actions & ReturnType<typeof getReduxProps> & NavigationProps<Routes.ScanSensorQrCodeView>;

const ScanSensorQrCodeView = (props: ScanSensorProps) => {

    const [scanned, setScanned] = useState(false);
    const [scannedCode, setScannedCode] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined)

    const navigation = useNavigation();

    const onBackPress = () => {
        if (props.sensor !== undefined) {
            props.checkSensorReceived(undefined);
            setScannedCode(undefined);
            setScanned(false);
        } else {
            navigation.reset({
                routes: [{
                    name: Routes.ParkableMapView,
                }],
            });
        }
    }

    const onScanSuccess = (code: BarCodeEvent) => {
        setScanned(true);
        setScannedCode(code.data)
    }

    const onSearchCode = () => {
        if (scannedCode !== undefined) {
            props.checkQRCode(scannedCode,
                () => {
                    setError(Strings.unrecognised_code);
                    setScanned(false);
                });
        }
    }

    return (
        <ParkableBaseView
            scrollable={false}
            onBackPress={onBackPress}>
        <View style={styles.base}>
        {props.sensor
            ? <View style={styles.base}>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.park}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.park && props.sensor ? props.park.address : Strings.unassigned}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.bay}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.bay && props.sensor ? props.bay.signage : Strings.unassigned}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.id}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.sensor.id}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.eui}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{props.sensor.eui}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.battery_level}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{!!props.sensor.batteryLevel ? props.sensor.batteryLevel : Strings.never}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey bold>{Strings.last_heartbeat}</Text>
                <Text style={{marginLeft: 15, marginTop: 15, height: 20}} grey>{!!props.sensor.lastHeartbeat ? moment(props.sensor.lastHeartbeat).format(`${props.dateformat} hh.mma`) : Strings.never}</Text>
            </View>
            : <View style={styles.base}>
                <Text h2 bold center style={styles.header}>{Strings.please_scan_code}</Text>
                <View style={styles.qrScannerContainer}>
                    <Camera
                        onBarCodeScanned={scanned ? undefined : (e) => onScanSuccess(e)}
                        barCodeScannerSettings={{
                            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
                        }}
                        style={styles.qrScanner}
                        flashMode={(Camera.Constants.FlashMode as any).auto}
                    />
                </View>
            </View>}
        {!!scanned && !!scannedCode && <View style={{
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: 25
        }}>
            <Text grey>{"QR Code: " + scannedCode}</Text>
            {scanned ? <Button center style={styles.buttonStyle} onPress={onSearchCode}>Search</Button> : null}
        </View>}
        {error && <Text style={styles.error}>{error}</Text>}
        </View>
        </ParkableBaseView>)
}

export default connect(getReduxProps, actions)(ScanSensorQrCodeView);

export const ScanSensorQrCodeRoute = createRoute({
    path: Routes.ScanSensorQrCodeView,
});

const styles = StyleSheet.create({
    base: {
        flex: 1,
    },
    error: {
        alignSelf: "center",
        marginBottom: 9,
        marginHorizontal: 36,
        color: Colours.RED
    },
    buttonStyle: {
        width: 250,
        height: 40
    },
    header: {
        paddingVertical: 14,
    },
    listView: {
        flex: 1,
        marginTop: 10,
        marginHorizontal: 15,
        backgroundColor: Colors.White,
        marginBottom: 10,
        alignSelf: 'stretch'
    },
    listViewItemText: {
        fontFamily: Constants.FONT_BOLD,
        fontSize: 26,
        paddingVertical: 5,
        paddingHorizontal: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    qrScanner: {
        alignSelf: "center",
        borderRadius: 9,
        paddingHorizontal: 36,
        flex: 1,
        height: 270,
    },
    qrScannerContainer: {
        flexDirection: "row",
    }
});
