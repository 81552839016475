import React, {useCallback, useEffect, useRef, useState} from "react";
import {
    StyleSheet,
    Text,
    View,
    TouchableOpacity,
    Animated,
    Platform,
    I18nManager,
} from "react-native";


type Props = {
    isOn: boolean,
    label?: string,
    onColor?: string,
    offColor?: string,
    size?: string,
    labelStyle?: object,
    thumbOnStyle?: object | number,
    thumbOffStyle?: object | number,
    trackOnStyle?: object | number,
    trackOffStyle?: object | number,
    onToggle: (on:boolean) => void,
    icon?: any,
    disabled?: boolean,
    animationSpeed?: number,
    useNativeDriver?: boolean,
    circleColor?: string,
};

const defaultProps = {
    isOn: false,
    onColor: "#4cd137",
    offColor: "#ecf0f1",
    size: "medium",
    labelStyle: {},
    thumbOnStyle: {},
    thumbOffStyle: {},
    trackOnStyle: {},
    trackOffStyle: {},
    icon: null,
    disabled: false,
    animationSpeed: 300,
    useNativeDriver: true,
    circleColor: "white",
} as Props;

function ToggleSwitch(props = defaultProps) {

    const calculateDimensions = useCallback((size: string | undefined) => {
        switch (size) {
            case "small":
                return {
                    width: 40,
                    padding: 10,
                    circleWidth: 15,
                    circleHeight: 15,
                    translateX: 22,
                };
            case "large":
                return {
                    width: 70,
                    padding: 20,
                    circleWidth: 30,
                    circleHeight: 30,
                    translateX: 38,
                };
            default:
                return {
                    width: 46,
                    padding: 12,
                    circleWidth: 18,
                    circleHeight: 18,
                    translateX: 26,
                };
        }
    }, []);

    const offsetX = useRef(new Animated.Value(0)).current;
    const [dimensions] = useState(calculateDimensions(props.size));
    const [toggleSwitchStyle, setToggleSwitchStyle] = useState<any>(undefined);
    const [insideCircleStyle, setInsideCircleStyle] = useState<any>(undefined);

    useEffect (() => {
        const style = [
            {
                justifyContent: "center",
                width: dimensions.width,
                borderRadius: 20,
                padding: dimensions.padding,
                backgroundColor: props.isOn
                    ? props.onColor
                    : props.offColor,
            },
            props.isOn ? props.trackOnStyle : props.trackOffStyle,
        ]

        setToggleSwitchStyle(style);
    }, [props, dimensions]);



    useEffect(() => {
        const style = [
            {
                alignItems: "center",
                justifyContent: "center",
                margin: Platform.OS === "web" ? 0 : 4,
                left: Platform.OS === "web" ? 4 : 0,
                position: "absolute",
                backgroundColor: props.circleColor,
                transform: [{ translateX: offsetX }],
                width: dimensions.circleWidth,
                height: dimensions.circleHeight,
                borderRadius: dimensions.circleWidth / 2,
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.2,
                shadowRadius: 2.5,
                elevation: 1.5,
            },
            props.isOn ? props.thumbOnStyle : props.thumbOffStyle,
        ];

        setInsideCircleStyle(style);
    }, [props, dimensions]);


    const {
        animationSpeed,
        useNativeDriver,
        isOn,
        onToggle,
        disabled,
        labelStyle,
        label,
        icon,
    } = props;

    useEffect(() => {
        let toValue;
        if (!I18nManager.isRTL && isOn) {
            toValue = dimensions.width - dimensions.translateX;
        } else if (I18nManager.isRTL && isOn) {
            toValue = -dimensions.width + dimensions.translateX;
        } else {
            toValue = -1;
        }

        Animated.timing(offsetX, {
            toValue,
            duration: animationSpeed,
            useNativeDriver: !!useNativeDriver,
        }).start();
    }, [dimensions, isOn, useNativeDriver])

    return (
        <View style={styles.container} {...props}>
            {label ? (
                <Text style={[styles.labelStyle, labelStyle]}>{label}</Text>
            ) : null}
            <TouchableOpacity
                style={toggleSwitchStyle}
                activeOpacity={0.8}
                onPress={() => (disabled ? null : onToggle(!isOn))}
            >
                <Animated.View style={insideCircleStyle}>
                    {icon}
                </Animated.View>
            </TouchableOpacity>
        </View>
    );

}

export default ToggleSwitch;

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        alignItems: "center",
    },
    labelStyle: {
        marginHorizontal: 10,
    },
});
