import _ from "lodash";
import { useReduxSubscriptions, useSelector } from "react/redux/redux";
import { getActiveSubscriptions } from "react/constants/getActiveSubscriptions";
import { getId } from "react/constants/Util";

export const useActiveSubscriptions = () => {
	const subs = useReduxSubscriptions();

	return getActiveSubscriptions(subs.employeeSubscriptions);
}

export const useFavouriteParkIds = () => {
	const favouriteParks = useSelector(state => state.userOptions.favouriteParks)
	const activeSubs = useActiveSubscriptions()

	const favParkIds = [
		..._.values(favouriteParks).map((p) => p.id),
		...activeSubs.map((s) => getId(s.park)),
	];

	return favParkIds
}

export const useCurrentUserVehicle = () => {
	const currentVehicleId = useSelector(state => state.user.currentVehicleId)
	const userVehicles = useSelector(state => state.user.vehicles)

	const currentVehicle = userVehicles.find(
		(v) => v.id === currentVehicleId
	)

	return currentVehicle
}
