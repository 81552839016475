import {StyleSheet, View} from 'react-native';
import React from 'react';
import {Colours, Text} from 'react/parkable-components';
import Strings from '../../constants/localization/localization';
import getParkAvailability from "react/constants/getParkAvailability";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import {PricePeriod, PricePeriodType} from "react/model/parkingPrice/PricePeriod";
import {getPricePeriodByTypeAndDay} from "react/services/parkingPrice.service";
import getDayAvailability from "react/constants/availability/getDayAvailability";
import {TerritoryDTO} from "react/api/territory/dto/TerritoryDTO";
import {ParkDTO} from "react/model/ParkDTO";

class RatesTableProps {
    columns: string[];
    rows: string[][];
}

export default function RatesTable(props: RatesTableProps) {
    const {columns, rows} = props;

    return (<View style={styles.table}>
                <View style={styles.tableRow}>
                    {columns.map((column, i)=>
                        <Text key={column} grey bold style={[styles.label, i == 0 ? {maxWidth:  80} : {}]}>{column}</Text>
                    )}
                </View>
                {rows?.map((values) => {
                    return <View style={styles.tableRow} key={values[0]}>
                        {values.map((value, i)=>
                            <Text key={value} style={[styles.tableCell, i == 0 ? {maxWidth:  80} : {}]}>{value}</Text>
                        )}
                    </View>
                })}
            </View>);
};

const styles = StyleSheet.create({
    label: {
        textAlign: "left",
        lineHeight: 40,
        flex: 1,
        justifyContent: "center",
        minHeight:40,
        fontSize: 15,
        color: Colours.GREY_70,
        textTransform: "uppercase"
    },
    table: {
        paddingBottom: 10,
    },
    tableRow: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        height: 40,
        borderBottomWidth: 1,
        borderColor: Colours.GREY_BORDER,
    },
    tableCell: {
        textAlign: "left",
        lineHeight: 40,
        flex: 1,
        justifyContent: "center",
        minHeight:40,
        marginTop: 0,
        marginBottom: 4,
    }
});
