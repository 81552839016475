import React, {Component} from 'react';
import {showAlert} from "../../alerts";
import _ from 'lodash';
import {
    NativeEventEmitter,
    NativeModules,
    Platform,
    AppState,
    DeviceEventEmitter,
    EmitterSubscription, AppStateStatus, NativeEventSubscription
} from "react-native";
import BleManager from 'react-native-ble-manager';
import Kontakt, { KontaktModule, RegionType } from "@parkable/expo-kontaktio";
import {BluetoothState} from "../../model/Types";

const kontaktEmitter = new NativeEventEmitter(KontaktModule);

const bleManagerEmitter = new NativeEventEmitter(NativeModules.BleManager);

export type Beacon = RegionType;

interface BluetoothScannerKontaktProps {
    log?: (log: string) => void,
    locationStatus: boolean,
    addInRangeBeacon: (beacon: Beacon) => void,
    regions: Beacon[] | undefined,
    onBluetoothStateChange: (state: BluetoothState) => void,
}

interface BluetoothScannerKontaktState {
    scanning: boolean,
    bluetoothState: undefined | BluetoothState,
    appState: AppStateStatus,
    bleStarted: boolean,
    activityTimer: any,
}

export default class BluetoothScannerKontakt extends Component<BluetoothScannerKontaktProps, BluetoothScannerKontaktState> {

    subscription: NativeEventSubscription;
    _componentUnmounting = false;
    androidBeaconsDidUpdate: EmitterSubscription | null = null;
    androidBeaconsDidAppear: EmitterSubscription | null = null;
    iosDidRangeBeaconsSubscription: EmitterSubscription | null = null;
    bleUpdateStateListener: EmitterSubscription | null = null;

    constructor(props: BluetoothScannerKontaktProps){
        super(props);

        this.state = {
            scanning: false,
            bluetoothState: 'on',
            appState: 'active',
            bleStarted: false,
            activityTimer: undefined,
        };
    }

    componentDidMount() {
        this.subscription = AppState.addEventListener('change', this.handleAppStateChange);
        void this.startBluetooth();
    }

    componentWillUnmount() {
        this._componentUnmounting = true;
        this.subscription.remove();
        this.stopBluetooth();
    }

    log = (message: string, data?: string) => {
        this.props.log?.(message + " -- " + (data || ""));
    }

    activityTimerTick = async () => {

        if (this.state.bleStarted && !this.state.scanning) {
            console.log(
                "bluetooth: ", this.state.bluetoothState,
                "scanning is ", this.state.scanning,
                "locationstatus is", this.props.locationStatus);

            if (this.props.locationStatus && this.state.bluetoothState === "on") {
                if (!this.state.bleStarted) {
                    await this.startBluetooth();
                } else {
                    this.startScanning(true);
                }
            }
        }
        else if(!this.state.bleStarted){
            await this.startBluetooth();
        }
    }

    setTimer = () => {
        if (!!this.state.activityTimer) {
            return;
        }
        const activityTimer = setInterval(this.activityTimerTick, 2000);
        this.setState({activityTimer});
    }

    clearTimer = () => {
        if (!this.state.activityTimer) {
            return;
        }
        clearInterval(this.state.activityTimer);
        this.setState({activityTimer: null});
    }

    //this is used because react-navigation does not remove the view stack before resetting it.
    //If we dont wait for a small amount of time then when transitioning from park detail to active session screen our scanning is stopped by the detail screen after being started by the active screen
    //I couldn't find any better way to do this reliably :-(
    startBluetooth = async () => {

        console.log('startBluetooth');
        await this.startBluetoothModule();
        this.log('start: bluetooth started');
        if(Platform.OS === 'ios'){
            this.log('start: configure events');
            this.configureIosEvents();
        }
        else { //android
            this.log('start: configure events');
            this.configureAndroidEvents();
        }

    }

    stopBluetooth = () => {
        console.log("stopBluetooth");
        this.clearTimer();
        if(Platform.OS === 'ios'){
            this.clearIosEvents();
        } else { //android
            this.clearAndroidEvents();
        }

        this.bleUpdateStateListener?.remove();
        this.bleUpdateStateListener = null;
    }

    scanConfiguration = () => {

        if(Platform.OS === 'ios'){
            console.log('scanConfiguration - ios');
            return {
                dropEmptyRanges: false, //we want event when devices is zero so we can update our list
                invalidationAge: 2000, //2 seconds and devices will drop off
                connectNearbyBeacons: false
            }
        }
        else { //android
            return {
                scanMode: Kontakt.scanMode.LOW_LATENCY,
                scanPeriod: Kontakt.scanPeriod.RANGING, // activePeriod = 60000ms, passivePeriod = 0ms
                activeCheckConfiguration: Kontakt.activityCheckConfiguration.MINIMAL,
                forceScanConfiguration: Kontakt.forceScanConfiguration.MINIMAL, // forceScanActivePeriod = 1000ms, forceScanPassivePeriod = 500ms
                deviceUpdateCallbackInterval: 1000,
                monitoringEnabled: Kontakt.monitoringEnabled.TRUE,
                monitoringSyncInterval: Kontakt.monitoringSyncInterval.DEFAULT, // 10sec, not used when monitoringEnabled is false
            }
        }
    }

    configureAndroidEvents = () => {

        this.clearAndroidEvents();

        this.androidBeaconsDidUpdate = DeviceEventEmitter.addListener(
            'beaconsDidUpdate',
            ({ beacons }) => {
                _.forEach(beacons, (beacon) => this.props.addInRangeBeacon(beacon));
                this.log('beaconsDidUpdate', JSON.stringify(beacons));
            }
        );

        this.androidBeaconsDidAppear = DeviceEventEmitter.addListener(
            'beaconsDidAppear',
            ({ beacon }) => {
                this.props.addInRangeBeacon(beacon);
                this.log('beaconDidAppear', JSON.stringify(beacon));
            }
        );
    }

    clearAndroidEvents = () => {
        this.androidBeaconsDidUpdate?.remove();
        this.androidBeaconsDidAppear?.remove();
    }

    configureIosEvents = () => {

        console.log('configureIosEvents');
        this.clearIosEvents();
        this.iosDidRangeBeaconsSubscription = kontaktEmitter.addListener(
            'didRangeBeacons',
            ({ region, beacons }: {region?: Beacon, beacons: Beacon[]}) => {
                if (region && beacons.length !== 0) {
                    this.props.addInRangeBeacon(region);
                    this.log("beaconDetected", JSON.stringify(region));
                }
            }
        );
    }

    clearIosEvents = () => {
        this.iosDidRangeBeaconsSubscription?.remove();
    }

    startBluetoothModule = async () => {
        try {
            console.log("startBluetoothModule")
            await BleManager?.start({showAlert: false});

            this.bleUpdateStateListener = bleManagerEmitter?.addListener('BleManagerDidUpdateState', this.handleBluetoothStateChange);

            if (Platform.OS === 'ios') {
                this.log('startBluetoothModule - ios');
                await this.startBluetoothModuleIos();
            } else { //android
                this.log('startBluetoothModule - android');
                await this.startBluetoothModuleAndroid();
            }
        } catch (err) {
            showAlert(err as any);
            this.log('startBluetoothModule error', JSON.stringify(err))
        }
    }

    startBluetoothModuleAndroid = async () => {
        try {
            await Kontakt?.connect("YtNjQDNpPThmCTWSmVSWBowGPLthGOpR", ["IBEACON"]);
            await this.log('Module initialized');
            this.setState({bleStarted: true});
            Kontakt?.configure(this.scanConfiguration());
            BleManager?.checkState();

        } catch (err) {
            await this.log('startBluetoothModuleAndroid', JSON.stringify(err));
        }
        await this.setTimer();
    }

    startBluetoothModuleIos = () => {
        return Kontakt?.init('YtNjQDNpPThmCTWSmVSWBowGPLthGOpR', ["IBEACON"])
            .then(() => {
                Kontakt?.configure(this.scanConfiguration());
                return Kontakt.requestWhenInUseAuthorization();
            }).then(() => {
                this.setState({bleStarted: true});
                this.log('Successfully initialized beacon ranging, monitoring and scanning');
                BleManager?.checkState();
                this.setTimer();
            })
            .catch((error: any) => {
                this.log('startBluetoothModuleIos: error', JSON.stringify(error));
            });
    }

    startScanning = (force: boolean) => {
        if (force || (this.state.bleStarted && !this.state.scanning)) {
            console.log("startScanning; Attempting to start scanning");
            if (Platform.OS === 'ios') {
                this.startScanningIos();
            }
            else { //android
                this.startScanningAndroid();
            }
        }
    }

    startScanningAndroid = () => {
        Kontakt.startScanning()?.then(() => {
            console.log('Started Scanning...');
            this.setState({scanning: true});
        }).catch(() => {
            this.log("Failed to start scanning")
        });
    }

    startScanningIos = () => {

        // only regions with uuid, major, minor can be sent to Kontakt lib
        const btRegionsToScan = (this.props.regions??[]).filter(region => !!region.uuid && !!region.major && !!region.minor);

        if (btRegionsToScan.length === 0) {
            return;
        }

        _.forEach(btRegionsToScan, (region) => {

            Kontakt.startRangingBeaconsInRegion(region)
                .then(() => {
                    this.setState({scanning: true})
                    this.log('startScanningIos: Started Scanning...');
                })
                .catch((error: any) => {
                    this.log('startScanningIos: error [startDiscovery]', error)
                });
        });

        this.setState({scanning: true});
    }

    stopScanning = async () => {
        if (this.state.bleStarted && this.state.scanning) {
            console.log("Attempting to stop scanning..");
            if (Platform.OS === 'ios') {
                return await this.stopScanningIos();
            }
            else { //android
                return await this.stopScanningAndroid();
            }
        }
    }

    stopScanningAndroid = () => {
        return Kontakt.stopScanning()
            .then(() => {
                if(this._componentUnmounting){
                    return;
                }
                this.setState({scanning: false});
                this.log('Stopped scanning');
            })
            .catch((error: any) => {
                this.log('[stopScanning]', error)
            });
    }

    stopScanningIos = async () => {
        try {
            // according to the ios code it shouldn't, but it seems that
            // instead of stopRangingBeaconsInAllRegions: () => Promise<void>,
            // it is stopRangingBeaconsInAllRegions: () => () => Promise<void>
            const p = (await Kontakt.stopRangingBeaconsInAllRegions()) as any;
            if (_.isFunction(p)) {
                await p();
            }

            if (this._componentUnmounting) {
                return;
            }
            this.setState({scanning: false});
            this.log('Stopped scanning');
        } catch (error: unknown) {
            this.log('[stopDiscovery]', error?.toString());
        }
    }

    handleBluetoothStateChange = async (args: { state: BluetoothState }) => {
        if(this.state.bluetoothState === args.state){
            console.log("Bluetooth state is still the same (" + args.state + ")");
            return;
        }
        console.log('handleBluetoothStateChange', args, this.state.bluetoothState);
        this.setState({bluetoothState: args.state});
        this.props.onBluetoothStateChange(args.state);

        if(args.state === 'on' && !this.state.scanning){
            if (!this.state.bleStarted) {
                await this.startBluetooth();
            } else {
                this.startScanning(false);
            }
        }
        else if ((args.state || '').match(/off|unauthorized|unknown/)){
            if (this.state.scanning) {
                await this.stopScanning();
            }

            this.setState({scanning: false});
        }
    }

    handleAppStateChange = async (nextAppState: AppStateStatus) => {
        console.log('app state change', nextAppState);
        const prevState = this.state.appState;
        this.setState({appState: nextAppState});
        if (prevState.match(/inactive|background/) && nextAppState === 'active') {
            console.log('App to active from ', prevState)
            if(!this.state.bleStarted){
                await this.startBluetooth();
            } else {
                this.startScanning(false);
                this.setTimer();
            }
        } else if (prevState === 'active' && nextAppState !== 'active') {
            console.log('App going to ' + nextAppState + ' from active');
            this.clearTimer();
            await this.stopScanning();
        }
    }

    render() {
        return null;
    }

}
