import * as Type from '../actions/user'
import * as TypeVoucher from '../actions/vouchers'
import * as Sentry from 'sentry-expo';
import {appVersionString} from "../../version";
import {Vehicle} from "../../model/Vehicle";
import {ILocation} from "../../constants/Util";
import {User} from "../../model/User";
import {Card} from "../../model/Card";
import { ModalType } from '../../components/common/ModalMessageCard';
import {Voucher} from "../../model/Voucher";
import {CancellationNotes} from "../../model/CancellationNotes";
import {UserDTO, UserRoleDTO} from "../../api/user/dto/user.dto";
import {Platform} from "react-native";
import { CreateUserData } from "../actions/user";

export interface Empty {
    [foo: string]: any //todo remove
}

const initState = {
    cards: null as unknown as Card[],
    user: null as UserDTO | null,
    userRoles: undefined as undefined | UserRoleDTO[],
    currentCardId: null,
    vehicles: [] as Vehicle[],
    currentVehicleId: null as number | null,
    installationId: null as null | number,

    gpsLocation: null as unknown  as ILocation,
    gpsRequested: false as boolean,
    messageModalPayload: {status: false as boolean, modalType: null as unknown as ModalType, data: null as any},
    hasSeenWelcomeBackMessage: false,
    casualVoucher:  {} as {[parkId: number]: Voucher},
    longTermVoucher:  {} as {[parkId: number]: Voucher},
    cancellationNotes: null as CancellationNotes|null,
    createUserData: undefined as CreateUserData|undefined
};

export type IUserReducer = typeof initState;

export default function UserReducer (state = initState, action: any): IUserReducer {
    switch (action.type) {

        case Type.SET_USER: {
            const user = action.user;

            if (!!user) {
                const sentryUser = {
                    id: user.id ? user.id.toString() : undefined,
                    username: user.userName,
                    email: user.email,
                    reactVersion: appVersionString(),
                };
                if (Platform.OS === "ios" || Platform.OS === "android") {
                    Sentry.Native.setUser(sentryUser);
                } else if (Platform.OS === "web") {
                    Sentry.Browser.setUser(sentryUser);
                }
            }

            return {
                ...state,
                user: action.user,
                currentVehicleId: !!action.user ? action.user.vehicleId : null,
            };
        }

        case Type.SET_USER_INSTALLATION_ID: {
            return {
                ...state,
                installationId: action.installationId,
            };
        }
        case Type.SET_USER_CARDS: {
            return {
                ...state,
                cards: action.cards,
                currentCardId: action.currentCardId
            };
        }
        case Type.SET_CURRENT_VEHICLE_ID: {
            const vehicleId = action.vehicleId;
            return {
                ...state,
                currentVehicleId: vehicleId,
            };
        }
        case Type.SET_USER_VEHICLES:
            return {
                ...state,
                vehicles: action.vehicles,
            };
        case Type.SET_USER_VEHICLE: {
            const vehicles = [];
            const vehicle = action.vehicle as Vehicle;
            let isUpdating = false;
            state.vehicles.forEach(v => {
                if (v.id === vehicle.id) {
                    if (vehicle.deleted) {
                        //deleted, not going to push in new array
                    } else {
                        isUpdating = true;
                        vehicles.push(vehicle)
                    }
                } else {
                    vehicles.push(v)
                }
            });
            if (!isUpdating) {
                vehicles.push(vehicle)
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    vehicleId: vehicle.id,
                } as User,
                vehicles
            };
        }
        case Type.SET_USER_HOST_DATA:
            return {
                ...state,
                user: {
                    ...state.user,
                    hostData: action.host,
                } as User,
            };

        case Type.USER_LOGGED_OUT: {

            const {...rest} = state;

            return {
                ...rest,
                ...initState,
            };
        }



        case Type.GPS_REQUESTED: {
            return {
                ...state,
                gpsRequested: action.requested
            }
        }
        case Type.SET_MESSAGE_MODAL: {
            const messageModalPayload = {...action.payload};
            return {
                ...state,
                messageModalPayload
            }
        }
        case Type.SET_SEEN_WELCOME_BACK_MESSAGE:
            return {
                ...state,
                hasSeenWelcomeBackMessage: true
            };
        case TypeVoucher.SET_CASUAL_VOUCHER_PARK: {
            const {parkId, voucher} = action;
            const casualVoucher = {...state.casualVoucher};
            casualVoucher[parkId] = voucher;

            return {
                ...state,
                casualVoucher,
            };
        }
        case TypeVoucher.SET_LONG_TERM_VOUCHER_PARK: {
            const {subscriptionId, parkId, voucher} = action;
            const longTermVoucher = {...state.longTermVoucher};
            longTermVoucher[subscriptionId??parkId] = voucher;

            return {
                ...state,
                longTermVoucher,
            };
        }
        case Type.SET_SUBSCRIPTION_CANCELLATION_NOTES:
            return {
                ...state,
                cancellationNotes: action.notes
            };
        case Type.USER_ROLES_RECEIVED: {
            return {
                ...state,
                userRoles: action.userRoles,
            };

        }
        case Type.SET_CREATE_USER_DATA: {
            return {
                ...state,
                createUserData: action.value,
            };
        }
        default:
            return state
    }
};
