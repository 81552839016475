import * as Type from '../actions/auth'
import { IdTokenResult } from "firebase/auth";
import { FirebaseTokenAndUser } from "../actions/auth";
import { FirebaseUser } from "../../services/firebase.service";

const initialState = {
    firebaseUser: undefined as FirebaseUser | undefined,
    fireBaseToken: undefined as string | undefined,
    fireBaseIdTokenResult: undefined as IdTokenResult | undefined,
    isAuthenticating: true,
};

export type IAuthReducer = typeof initialState;

export default function authReducer(state = initialState, action: any): IAuthReducer {
    switch (action.type) {
        case Type.SET_FIREBASE_TOKEN_AND_USER: {
            const args = action.args as (FirebaseTokenAndUser | undefined);
            return {
                ...state,
                fireBaseIdTokenResult: args?.idTokenResult,
                fireBaseToken: args?.idTokenResult?.token,
                firebaseUser: args?.user,
            };
        }
        case "SET_IS_AUTHENTICATING":
            return {
                ...state,
                isAuthenticating: action.isAuthenticating
            }
        default:
            return state;
    }
}
