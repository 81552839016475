import React from "react";
import { Routes } from "../../../navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Platform } from "react-native";
import AddNewCardMobile from "./AddNewCardMobile";
import AddNewCardWeb from "./AddNewCardWeb";

export type AddNewCardProps = NavigationProps<Routes.AddNewCard>;

function AddNewCard(props: AddNewCardProps) {
    return Platform.OS === "web"
        ? <AddNewCardWeb {...props}/>
        : <AddNewCardMobile {...props} />;
}

class AddNewCardParams {
    destination?: {
        route: Routes,
        params: any,
    };
}

export default AddNewCard

export const AddNewCardRoute = createRoute({
    options: {
        title: "Add New Card"
    },
    path: Routes.AddNewCard,
    params: {
        type: AddNewCardParams,
        deserialize: d => ({
            destination: d.Json(),
        })
    }
})
