import React from "react"
import Text from "react/parkable-components/text/Text";
import moment, { Moment } from "moment-timezone"
import { View } from "react-native"
import { styles } from "./day-row-header.styles"

export type DayRowHeaderProps = {
	week: Moment[]
}

export const  DayRowHeader = (props: DayRowHeaderProps) => {
	const { week } = props
	
	return <View style={styles.container}>
		{week.map((day, i) => {
			const showMonth=getFirstAvailableDateOfMonth(week, day)
			return <View style={{ flex: 1 }} key={i}>
				{showMonth && <Text bold
					key={i}
					style={{
						textAlign: "right",
						fontSize: 12,
						textTransform: "uppercase"
					}}
					ml={2}
					mr={8}
					grey
					small>
					{day.format("MMM")}
				</Text>}
			</View>
		})}

	</View>
}

const getFirstAvailableDateOfMonth = (week: Moment[], currentDay: Moment) => {
	const availableDays = week.map((d) => d.isoWeekday())
	let weekday = moment(currentDay).startOf('month').isoWeekday();
	let day = 1;
	while(currentDay.date() < 7 && !availableDays.includes(weekday)) {
		weekday % 7 === 0 ? weekday = 1 : weekday += 1;
		day++;
	}
	return currentDay.date() === day
}