import React from "react";
import { useMemo } from "react";
import { useReduxMaps } from "react/redux/redux";

import {
	ParkPinMeta,
	useParkPinPressHandler,
} from "../park.constants";
import { generateLongTermIcon, generateLongTermText, getShowLongTermPin } from "./constants";
import { PinWrapper } from "../../pin-wrapper";
import { ParkInfo } from "react/model/ParkInfo";

export type ParkPinLongTermWebprops = {
	meta: ParkPinMeta;
	data: Pick<
		ParkInfo,
		"currencyCode" | "pricePerMonth" | "pricePerWeek" | "parkId" | "hideSubscriptionBays"
	>;
};

export const ParkPinLongTermWeb = (props: ParkPinLongTermWebprops) => {
	const { data, meta } = props
	const {
		hasLongTerm,
		isFavouritePark,
		isOrgMember,
		isParkFull,
		isParkOpen,
		isParkOwner,
		isParkUnavailable,
		longTermDisplayType,
		numSpotsLeft,
		zIndex,
	} = meta;
	const { hideSubscriptionBays } = data;
	const maps = useReduxMaps();

	const shouldShow = getShowLongTermPin({
		hasLongTerm,
		isOrgMember,
		isFavouritePark,
		isParkFull,
		isParkOpen,
		preferences: maps.preferences,
		hideSubscriptionBays,
		isParkOwner
		});

	const icon = generateLongTermIcon({
		isFavouritePark, isOrgMember
	})

	const handlePress = useParkPinPressHandler(data);

	const text = generateLongTermText({
		currencyCode: data.currencyCode,
		displayType: longTermDisplayType,
		isFavouritePark,
		isParkFull,
		numSpotsLeft,
		pricePerMonth: data.pricePerMonth,
		pricerPerWeek: data.pricePerWeek,
		isOrgMember
	});

	const deps = [
		text,
		props.data.parkId,
		shouldShow,
		icon
	]

	return useMemo(
		() => <>
			{shouldShow &&
				<PinWrapper
					handlePress={handlePress}
					isFavouritePark={isFavouritePark}
					isOrgMember={isOrgMember}
					isParkUnavailable={isParkUnavailable}
					icon={icon}
				>
					{text}
				</PinWrapper>}
		</>,
		deps);
};
