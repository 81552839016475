import {View} from "react-native";
import React from "react";

type AllProps = {
    height: number,
    width: number | string,
    colour: string,
    backgroundColour: string,
    marginTop?: number
}

export default function dashes(props: AllProps){

    // These views are to draw the dotted line - dashes dont work if you just set
    // one border side so we draw four borders and then draw over the top, left and right ones...*sigh*
    return (<View
        style={{
            height: props.height,
            backgroundColor: props.backgroundColour,
            borderWidth: props.height,
            borderColor: props.colour,
            borderRadius: 1,
            borderStyle: 'dashed',
            marginTop: props.marginTop
        }}
    >
        <View style={{ position: 'absolute', left: -props.height, top: -props.height, width: props.width, height: props.height, backgroundColor: props.backgroundColour, zIndex: 1 }} />
        <View style={{ position: 'absolute', left: -props.height, top: -props.height, width: props.height, height: '100%', backgroundColor: props.backgroundColour, zIndex: 1 }} />
        <View style={{ position: 'absolute', right: -props.height, top: -props.height, width: props.height, height: '100%', backgroundColor: props.backgroundColour, zIndex: 1 }} />
    </View>)
}