import React, { useRef, useEffect } from 'react';
import { View } from 'react-native';
import Button from "react/parkable-components/button/Button";
import { DialogRef } from "react/parkable-components/dialog/Dialog";
import Colours from "react/parkable-components/styles/Colours";
import Dialog from "react/components/dialog/Dialog";
import useSafeArea from "../../../hooks/useSafeArea";
import Strings from "../../../constants/localization/localization"

type Props = {
    show: boolean,
    message?: string,
    onHide: () => void
}

export default function ReservationCancelled(props: Props) {
    const { top } = useSafeArea();
    const { show = false, message, onHide } = props;

    const dialogRef = useRef<DialogRef | null>(null);

    useEffect(() => {
        if (show) {
            dialogRef.current?.show();
        } else {
            dialogRef.current?.hide();
        }
    }, [show]);

    return <Dialog style={[{top}, { position: 'absolute', width: '100%' }]} onModalHide={onHide}
        ref={dialogRef} iconFABProps={{ style: { backgroundColor: Colours.PINK_DARK } }}
        icon={'reservedfilled'} iconProps={{ white: true }}
        label={Strings.reservations} labelProps={{ style: { color: Colours.PINK } }}
        title={!!message ? message : Strings.reservations_cancelled}>
        <View style={{ alignItems: 'center' }}>
            <View style={{ width: '100%' }}>
                <Button center style={{ marginTop: 10 }} onPress={onHide} plain border >{Strings.dismiss}</Button>
            </View>
        </View>
    </Dialog>
}
