import React from "react"
import { View } from "react-native";
import { useReduxMaps } from "react/redux/redux";
import { ParkInfo } from "react/model/ParkInfo"
import { ParkingType } from "react/model/Types";
import { ParkPinCasualWeb } from "./casual/park-pin-casual.web";
import { ParkPinEvWeb } from "./ev/park-pin-ev.web";
import { ParkPinLongTermWeb } from "./long-term/park-pin-lt-web";
import { useParkPinMeta } from "./park.constants";

export type ParkPinWebProps = {
	info: ParkInfo
}

export const ParkPinWeb = (props: ParkPinWebProps) => {
	const { preferences } = useReduxMaps()

	const parkingType = preferences.parkingType

	const pinData = useParkPinMeta(props.info, parkingType);

	// Note: zIndex must be placed on this top-level component for it
	// to work how we intend
	return <View style={{ zIndex: pinData.zIndex }}>
		{parkingType === ParkingType.CASUAL && <ParkPinCasualWeb meta={pinData} data={props.info} />}
		{parkingType === ParkingType.LONG_TERM && <ParkPinLongTermWeb meta={pinData} data={props.info} />}
		{parkingType === ParkingType.ELECTRIC && <ParkPinEvWeb meta={pinData} data={props.info} />}
	</View>
}