import React, { useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";
import Text from "react/parkable-components/text/Text";
import Icons from "react/parkable-components/icon/Icons";
import { connect } from "react-redux";
import Strings from "../../constants/localization/localization";
import ParkingTypeButton from "./ParkingTypeButton";
import { IRootReducer } from "../../redux/reducers/main";
import { setParkingType } from "../../redux/actions/map";
import { ParkingType } from "../../model/Types";

type ButtonGroupProps = {
    title?: string,
    label?: string,
    fullHeight?: boolean,
    onChangeParkingType: (parkingType: ParkingType) => void,
    currentParkingType?: ParkingType,
    displayCasual: boolean,
    displaySubscription: boolean,
    displayEV: boolean,
    isMotorBikeDefault: boolean
}

const actions = {
    setParkingType
}

const getReduxProps = (state: IRootReducer) => {
    return {
        parkingType: state.maps.preferences.parkingType,
        organisations: state.organisations.organisations
    }
};

const ParkingTypeButtonsGroup = (props: ButtonGroupProps & ReturnType<typeof getReduxProps> & typeof actions) => {
    const {
        title,
        label,
        fullHeight,
        onChangeParkingType,
        displayCasual,
        displaySubscription,
        displayEV,
    } = props;
    const [currentParkingType, setCurrentParkingType] = useState<ParkingType>(props.currentParkingType??props.parkingType);

    const parkingTypesDict = {
        [ParkingType.CASUAL]: displayCasual,
        [ParkingType.LONG_TERM]: displaySubscription,
        [ParkingType.ELECTRIC]: displayEV,
    }
    const parkingTypesAllowed = Object.entries(parkingTypesDict).filter((parkingType) =>
        parkingType[1] !== false
    );

    useEffect(() => {
        const currentParkingType = props.currentParkingType ?? props.parkingType;

        if (!parkingTypesAllowed.find((value) => value[0] === currentParkingType)) {
            setCurrentParkingType(currentParkingType);
            //saves the app from crashing in case displayCasual, displaySubscription, and displayEV are set to false
            onChangeParkingType((parkingTypesAllowed.length ? parkingTypesAllowed[0][0] : "CASUAL") as ParkingType);
            return;
        }
        setCurrentParkingType(currentParkingType);
    }, [
        props.currentParkingType,
        props.parkingType,
        displayCasual,
        displaySubscription,
        displayEV
    ]);


    const changeParkingType = async (parkingType : ParkingType) => {
        //not updating in redux automatically as it causes slow UI if we have map view open underneath
        //leave it up to the page displaying the component as to whether it will update redux
        //await setParkingType(parkingType);
        onChangeParkingType(parkingType);
    }

    if (parkingTypesAllowed.length === 1) {
        return <></>;
    }

    return (
        <View style={[styles.container, fullHeight ? {minHeight: '100%'} : undefined]}>
            {!!title && <Text h2>{title}</Text>}
            {!!label && <Text h5>{label}</Text>}
            <View style={styles.parkingButtons}>
                {displayCasual
                    && <ParkingTypeButton
                            style={styles.parkingButton}
                            icon={props.isMotorBikeDefault ? Icons.motorbike :Icons.car}
                            green={currentParkingType === ParkingType.CASUAL}
                            onPress={() => changeParkingType(ParkingType.CASUAL)}
                        >
                            {Strings.casual}
                        </ParkingTypeButton>
                    }

                <View style={{width: 9}}/>

                {displaySubscription
                    && <ParkingTypeButton
                            style={styles.parkingButton}
                            icon={Icons.key}
                            green={currentParkingType === ParkingType.LONG_TERM}
                            onPress={() => changeParkingType(ParkingType.LONG_TERM)}>
                                {Strings.long_term}
                        </ParkingTypeButton>

                    }

                <View style={{width: 9}}/>

                {displayEV
                    && <ParkingTypeButton
                            style={styles.parkingButton}
                            icon={Icons.electricvehicleplug}
                            green={currentParkingType === ParkingType.ELECTRIC}
                            onPress={() => changeParkingType(ParkingType.ELECTRIC)}
                        >
                            {Strings.ev_charger}
                        </ParkingTypeButton>
                    }
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        paddingBottom: 18
    },
    parkingButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },
    parkingButton: {
        flex: 1,
    },
});

export default connect(getReduxProps, actions)(ParkingTypeButtonsGroup as React.FunctionComponent<ButtonGroupProps>);
