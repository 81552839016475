import {Bay} from "../model/Bay";

export enum TEMPLATE {
    Number = "N",
    Separator = "_",
    Letter = "L"
}

type BayWithSignageComponents = Bay & {
    signageSymbols: (string | number)[],
    signageTemplate: TEMPLATE[]
}

const NUMBERS = Array.from({length: 10}, (x, i) => String(i))
const SEPARATORS = `,<.>/?;:'"[{]}\\|-_=+!@#$%^&*()`.split("")

export const extractCharTemplate = (char: string) => {
    if (NUMBERS.includes(char)) {
        return TEMPLATE.Number
    }
    if (SEPARATORS.includes(char)) {
        return TEMPLATE.Separator
    }
    return TEMPLATE.Letter
}

export const extractStringTemplate = (signage: string) => signage.trim().split("").map(extractCharTemplate);

export const fragmentIntoComponents = (template: TEMPLATE[], signage: string) => {
    const zipped: [string, TEMPLATE][] = signage.split("").map((x, i) => [x, template[i]])

    const components = [] as [string, TEMPLATE][]
    let latestIdx = 0;

    zipped.forEach(([currentSymbol, currentTemplate], i) => {
        if (i === 0) {
            return components.push([currentSymbol, currentTemplate])
        }
        const [latestSymbol, latestTemplate] = components[latestIdx]

        const sameTypeAsPrevious = latestTemplate === currentTemplate;
        if (sameTypeAsPrevious) { // append to last component
            return components[latestIdx] = [latestSymbol + currentSymbol, currentTemplate]
        }

        // add new component
        latestIdx += 1
        return components.push([currentSymbol, currentTemplate])
    })

    const signageSymbols: (string | number)[] = components.map(([symbol, template]) => template === TEMPLATE.Number ? Number(symbol) : symbol)
    const signageTemplate: TEMPLATE[] = components.map(([_, template]) => template)
    return {signageSymbols, signageTemplate}
}

export const groupBaysBySimilarSignageStructure = (bays: Bay[]) => {
    const groups: { [key: string]: BayWithSignageComponents[] } = {}
    const baysWithoutSignage = []

    for (const bay of bays) {
        const {signage} = bay;

        if (signage === null || signage === undefined || signage === "") {
            baysWithoutSignage.push(bay)
            continue
        }

        const template = extractStringTemplate(signage)
        const {signageSymbols, signageTemplate} = fragmentIntoComponents(template, signage);
        const bayWithSignageComponents = {...bay, signageSymbols, signageTemplate}
        const key = signageTemplate.join("")
        groups[key] = [...(groups[key] ?? []), bayWithSignageComponents]
    }

    return {groups, baysWithoutSignage}
}

export const recursivelyCompareComponents = (
    aComponents: BayWithSignageComponents["signageSymbols"],
    bComponents: BayWithSignageComponents["signageSymbols"]
): number => {
    if (aComponents.length === 0 || bComponents.length === 0) {
        return 0;
    }
    const aComponent = aComponents[0]
    const bComponent = bComponents[0]
    if (aComponent === bComponent) {
        return recursivelyCompareComponents(aComponents.slice(1), bComponents.slice(1))
    }

    return aComponent > bComponent ? 1 : -1;
}

export const sortBaysBySignage = (bays: Bay[]) => {
    const {groups, baysWithoutSignage} = groupBaysBySimilarSignageStructure(bays);
    const sortedGroups = Object
        .keys(groups)
        .sort()
        .map(key => groups[key]
            .sort((a, b) => recursivelyCompareComponents(
                a.signageSymbols, b.signageSymbols
            )))
        .reduce((accum, bays) => [...accum, ...bays], [])

    return [...sortedGroups, ...baysWithoutSignage] as Bay[]
}
