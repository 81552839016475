import React from "react"
import { IconName } from "react/parkable-components/icon/Icons";
import { CampusDTO } from "react/api/campus/dto/CampusDTO"
import { PinWrapper } from "./pin-wrapper"
import { useCampusPinData, useCampusPinPressHandler } from "./campus.constants"

export type CampusPinProps = {
	info: CampusDTO
}


export const CampusPinWeb = (props: CampusPinProps) => {
	const {
		containsFavouritePark,
		hasAnOpenPark,
		isOrgMember,
	} = useCampusPinData(props.info)

	const handlePress = useCampusPinPressHandler(props.info)


	// TODO: What is campus text?
	const text = ""

	const icon: IconName = "buildingwork"

	return <PinWrapper
		handlePress={handlePress}
		isFavouritePark={containsFavouritePark}
		isOrgMember={isOrgMember}
		isParkUnavailable={!hasAnOpenPark}
		icon={icon} >
		{text}
	</PinWrapper>
}
