import React, {
    createContext, PropsWithChildren, useContext, useState,
} from "react";
import { RecurrentSharingActions, RecurrentSharingDurations } from "./RecurrentSharingDialog";

export interface IMakeBayAvailableContext {
    showSharingRecurrenceDialog?: boolean;
    setShowSharingRecurrenceDialog: (showDialog: boolean) => void;
    selectedDaysOfWeek?: number[],
    setSelectedDaysOfWeek: (daysSelected: number[]) => void;
    sharingAction?: RecurrentSharingActions
    setSharingAction: (actionType: RecurrentSharingActions) => void;
    sharingDurationValue?: string,
    setSharingDurationValue: (value: string) => void;
    sharingDurationType?: RecurrentSharingDurations
    setSharingDurationType: (durationType: RecurrentSharingDurations) => void;
}

export const MakeBayAvailableContext = createContext<IMakeBayAvailableContext | undefined>(undefined);

export const useMakeBayAvailableContext = () => useContext(MakeBayAvailableContext);

export const MakeBayAvailableProvider = ({ children }: PropsWithChildren) => {
    const [showSharingRecurrenceDialog, setShowSharingRecurrenceDialog] = useState<boolean>(false);
    const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState<number[]>([]);
    const [sharingAction, setSharingAction] = useState<RecurrentSharingActions>(RecurrentSharingActions.Share);
    const [sharingDurationValue, setSharingDurationValue] = useState<string>('1');
    const [sharingDurationType, setSharingDurationType] = useState<RecurrentSharingDurations>(RecurrentSharingDurations.Month);

    return (
        <MakeBayAvailableContext.Provider
            value={{
                showSharingRecurrenceDialog,
                setShowSharingRecurrenceDialog,
                selectedDaysOfWeek,
                setSelectedDaysOfWeek,
                sharingAction,
                setSharingAction,
                sharingDurationValue,
                setSharingDurationValue,
                sharingDurationType,
                setSharingDurationType,
            }}
        >
            {children}
        </MakeBayAvailableContext.Provider>
    );
};
