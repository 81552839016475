import color from "color";
import React, {
    PropsWithChildren,
    Ref,
    useImperativeHandle,
    useState,
} from "react";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import Modal from "react-native-modal";
import Button, { ButtonProps } from "../button/Button";
import Card from "../card/Card";
import FAB, { FABProps } from "../fab/FAB";
import Icon, { IconProps } from "../icon/Icon";
import { IconName } from "../icon/Icons";
import Colours from "../styles/Colours";
import Text, { TextProps } from "../text/Text";
import Nullable from "../types/Nullable";
import classnames from "../util/classnames";
import { ModalProps } from "./ModalProps";

const styles = StyleSheet.create({
    base: {
        margin: 0,
        padding: 18,
        backgroundColor: color(Colours.GREY_60).alpha(0.1).string(),
    },
    card: {
        backgroundColor: "white",
    },
    titleContent: {
        padding: 18,
        paddingBottom: 9,
        // backgroundColor: "orange",
    },
    titleIconContainer: {
        position: "absolute",
        top: -23,
        left: 0,
        right: 0,
        zIndex: 10,
        alignItems: "center",
    },
    titleIcon: {},
    cardContent: {
        paddingHorizontal: 18,
        paddingVertical: 9,
        // backgroundColor: "green",
    },
    buttonFooter: {
        width: "100%",
        position: "relative",
        padding: 18,
        paddingTop: 9,
        // backgroundColor: "pink",
        flexDirection: "row",
    },
    buttonFooterVertical: {
        flexDirection: "column",
    },
    button: {
        flex: 1,
    },
    spacer: {
        width: 9,
        height: 9,
    },
});

const cn = classnames(styles);

export type DialogProps = PropsWithChildren<
    {
        initialShown?: boolean;
        autoHide?: boolean;
        autoHideDuration?: number;

        title?: string;
        titleProps?: TextProps;
        label?: string;
        labelProps?: TextProps;
        icon?: IconName;
        iconProps?: Omit<IconProps, "name">;
        iconFABProps?: FABProps;

        cardStyle?: StyleProp<ViewStyle>;
        cardContentStyle?: StyleProp<ViewStyle>;

        verticalButtons?: boolean;
        buttonFooterStyle?: StyleProp<ViewStyle>;
        positiveText?: string;
        positiveProps?: Omit<ButtonProps, "onPress">;
        disableCloseOnPositive?: boolean;
        onClose?: () => void;
        onPositivePress?: () => void;

        negativeText?: string;
        negativeProps?: Omit<ButtonProps, "onPress">;
        disableCloseOnNegative?: boolean;
        disableCloseOnBackdropPress?: boolean;
        onNegativePress?: () => void;
        animationIn?: string;
        animationOut?: string;
        closeOnClick?: boolean;
        fullScreen?: boolean;
        onModalHide?: Function;
        statusBarTranslucent: boolean;
        isVisible?: boolean;
    } & Omit<ModalProps, "isVisible" | "children">
>;

export interface DialogRef {
    show(): void;

    hide(): void;
}

function Dialog(props: DialogProps, ref: Ref<DialogRef>) {
    const {
        children,
        initialShown,
        autoHide,
        autoHideDuration,

        title,
        titleProps,
        label,
        labelProps,
        icon,
        iconProps,
        iconFABProps,

        verticalButtons,
        positiveText,
        positiveProps,
        disableCloseOnPositive,
        disableCloseOnBackdropPress,
        onClose,

        negativeText,
        negativeProps,
        disableCloseOnNegative,
        animationIn,
        animationOut,
        ...otherProps
    } = props;

    const [isVisible, setIsVisible] = useState<boolean>(initialShown ?? false);
    const [autoHideTimeoutId, setAutoHideTimoutId] =
        useState<Nullable<number>>(null);

    const show = () => {
        setIsVisible(true);
    };

    const hide = () => {
        setIsVisible(false);
        onClose?.();
    };

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide,
    }));

    const onBackButtonPress = () => {
        props.onBackButtonPress?.();
        if (!disableCloseOnBackdropPress) {
        hide();
        }
    };

    const onBackdropPress = () => {
        props.onBackdropPress?.();
        if (!disableCloseOnBackdropPress) {
            hide();
        }
    };

    const style = [cn.styles("base"), props.style];
    const cardStyle = [cn.styles("card"), props.cardStyle];
    const cardContentStyle = [cn.styles("cardContent"), props.cardContentStyle];
    const buttonFooterStyle = [
        cn.styles("buttonFooter", {
            buttonFooterVertical: verticalButtons,
        }),
        props.buttonFooterStyle,
    ];
    const negativeButtonStyle = [
        !verticalButtons && styles.button,
        negativeProps?.style,
    ];
    const positiveButtonStyle = [
        !verticalButtons && styles.button,
        positiveProps?.style,
    ];

    const hasTitle = !!title;
    const hasTitleLabel = !!label;
    const hasTitleIcon = !!icon;
    const hasTitleContent = hasTitle || hasTitleLabel || hasTitleIcon;

    const hasPositiveButton = !!positiveText || !!positiveProps?.loading;
    const hasNegativeButton = !!negativeText;

    const onShow = () => {
        props.onModalShow?.();

        if (autoHide) {
            const timoutId: number = window.setTimeout(
                hide,
                autoHideDuration || 2500
            );
            setAutoHideTimoutId(timoutId);
        }
    };

    const onModalHide = () => {
        props.onModalHide?.();

        if (autoHide && autoHideTimeoutId) {
            clearTimeout(autoHideTimeoutId);
        }
    };

    const onPositivePress = () => {
        props.onPositivePress?.();
        if (!disableCloseOnPositive) {
            hide();
        }
    };
    const onNegativePress = () => {
        props.onNegativePress?.();
        if (!disableCloseOnNegative) {
            hide();
        }
    };

    return (
        <Modal
            animationIn={animationIn || "fadeIn"}
            animationOut={animationOut || "fadeOut"}
            backdropTransitionOutTiming={5}
            {...otherProps}
            style={style}
            isVisible={props.isVisible ?? isVisible}
            onModalShow={onShow}
            onModalHide={onModalHide}
            onBackButtonPress={onBackButtonPress}
            onBackdropPress={onBackdropPress}
        >
            <Card style={cardStyle}>
                {hasTitleContent && (
                    <View style={styles.titleContent}>
                        {hasTitleIcon && icon && (
                            <View style={styles.titleIconContainer}>
                                <FAB
                                    style={styles.titleIcon}
                                    green
                                    elevation={0}
                                    {...(iconFABProps || {})}
                                >
                                    <Icon name={icon} {...(iconProps || {})} />
                                </FAB>
                            </View>
                        )}
                        {hasTitleLabel && (
                            <Text
                                center
                                green
                                bold
                                h2
                                mb={hasTitle ? 9 : 0}
                                mt={hasTitleIcon ? 9 : 0}
                                {...(labelProps || {})}
                            >
                                {label}
                            </Text>
                        )}

                        {hasTitle && (
                            <Text h2 center {...(titleProps || {})}>
                                {title}
                            </Text>
                        )}
                    </View>
                )}
                {children && <View style={cardContentStyle}>{children}</View>}
                {(hasPositiveButton || hasNegativeButton) && (
                    <View style={buttonFooterStyle}>
                        {hasNegativeButton && (
                            <Button
                                center
                                plain
                                border
                                {...(negativeProps || {})}
                                style={negativeButtonStyle}
                                onPress={onNegativePress}
                            >
                                {negativeText}
                            </Button>
                        )}
                        {hasNegativeButton && hasPositiveButton && (
                            <View style={styles.spacer} />
                        )}
                        {hasPositiveButton && (
                            <Button
                                center
                                {...(positiveProps || {})}
                                style={positiveButtonStyle}
                                onPress={onPositivePress}
                            >
                                {positiveText}
                            </Button>
                        )}
                    </View>
                )}
            </Card>
        </Modal>
    );
}

export default React.forwardRef<DialogRef, DialogProps>(Dialog);
