import Colours from "react/parkable-components/styles/Colours";

export default {
  api: {
    name: "local",
    url: "http://localhost:8080/api/",
  },
  environmentDisplayName: "local",
  borderColour: Colours.PINK,
}
