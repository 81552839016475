import * as constants from '../../constants/constants'
import {Region} from "react-native-maps";
import {
    DATE_FORMAT_RECEIVED,
    SET_COUNTRYCODE,
    SET_DEFAULT_LOCATION,
    USER_LOGGED_OUT,
    USER_PROVIDED_REVIEW
} from '../actions/user';
import { SET_BAY_SHARE_MUTE_TODAY } from '../actions/subscriptions';

const init = {
    dateformat: constants.DefaultSettings.DefaultDateFormat,
    countryCode: null as string | null,
    defaultLocation: constants.defaultLocation(),
    bayShareMutedAt: null as string | null,
    providedReviewAt: null as string | null
};

type ISettingsReducer = typeof init;

export default function settingsReducer(state = init, action: any): ISettingsReducer {
    switch (action.type) {
            case DATE_FORMAT_RECEIVED: {
                return {
                    ...state,
                    dateformat: action.dateformat
                }
            }
            case SET_COUNTRYCODE: {
                return {
                    ...state,
                    countryCode: action.countryCode
                }
            }
            case SET_DEFAULT_LOCATION: {
                console.log("UPDATING DEFAULT LOCATION");
                const defaultLocation = state.defaultLocation;
                defaultLocation.latitude = action.defaultLocation.latitude;
                defaultLocation.longitude = action.defaultLocation.longitude;
                return {
                    ...state,
                    defaultLocation
                }
            }
            case SET_BAY_SHARE_MUTE_TODAY: {
                return {
                    ...state,
                    bayShareMutedAt: action.bayShareMutedAt
                }
            }
            case USER_LOGGED_OUT: {
                return {
                    ...state,
                    bayShareMutedAt: null,
                }
            }
            case USER_PROVIDED_REVIEW: {
                return {
                    ...state,
                    providedReviewAt: action.providedReviewAt
                }
            }
        default:
            return state;
    }
}