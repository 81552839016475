import { Routes } from "../../../navigation/root/root.paths";
import React from "react";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Platform } from "react-native";
import QrScannerView from "./QrScannerView";
import QrManualScannerView from "./QrManualScannerView";

type Props = NavigationProps<Routes.QrScannerView>

export default function QrScannerRoot(props: Props) {
    return Platform.OS === "web"
        ? <QrManualScannerView {...props}/>
        : <QrScannerView {...props}/>;
}

export class QrScannerViewParams {
    instructions?: string;
}


export const QrScannerRoute = createRoute({
    path: Routes.QrScannerView,
    params: {type: QrScannerViewParams}
})
