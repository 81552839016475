import { createRoute, NavigationProps } from "../../navigation/constants";
import { Routes } from "../../navigation/root/root.paths";
import { Platform } from "react-native";
import ScanSensorQrCodeView from "./ScanSensorQrCodeView";
import ScanSensorQrManualCodeView from "./ScanSensorQrManualCodeView";

type Props = NavigationProps<Routes.ScanSensorQrCodeView>

export default function QrScannerRoot(props: Props) {
    return Platform.OS === "web"
        ? <ScanSensorQrManualCodeView {...props} />
        : <ScanSensorQrCodeView {...props} />;
}

export const ScanSensorQrCodeRoute = createRoute({
    path: Routes.ScanSensorQrCodeView
})
