/**
 * Created by Chris on 21/02/17.
 */


import React, {PureComponent} from 'react';
import { ActivityIndicator } from 'react-native';
import {ParkableBlue} from "../../constants/colors";

export default class Spinner extends PureComponent<{color?: any, small?: boolean, size?: any}> {

    _root: any = null;

    render() {
        return (
            <ActivityIndicator
                ref={c => this._root = c} {...this.props}
                color={this.props.color ? this.props.color : ParkableBlue} size={this.props.small ? 'small' : this.props.size ? this.props.size : 'large'}
            />
        );
    }
}