import Constants from "expo-constants";
import { Platform } from "react-native";
import { merge } from "lodash";
import { useSelector } from "../redux/redux";
import defaultConfig from "../../config/default";
import devConfig from "../../config/env/dev";
import prodConfig from "../../config/env/prod";
import qaConfig from "../../config/env/qa";
import demoConfig from "../../config/env/demo";
import localConfig from "../../config/env/local";
import webConfig from "../../config/platform/web";
import iosConfig from "../../config/platform/ios";
import androidConfig from "../../config/platform/android";

const platformConfig = Platform.select({
    web: webConfig as Configuration,
    ios: iosConfig as Configuration,
    android: androidConfig as Configuration,
});

const environments = {
    dev: merge({}, defaultConfig, devConfig, platformConfig),
    local: merge({}, defaultConfig, localConfig, platformConfig),
    qa: merge({}, defaultConfig, qaConfig, platformConfig),
    demo: merge({}, defaultConfig, demoConfig, platformConfig),
    production: merge({}, defaultConfig, prodConfig, platformConfig),
}

export type Configuration = {
    api: {
        name: EnvironmentName;
        url: string;
        serviceUrl?: (name:string) => string;
    };
    environmentDisplayName?: string;
    showBuildNumber?: boolean;
    version: string;
    build: number;
    firebase: any;
    firebaseAppId?: string;
    borderColour: string;
    changeServerAllowed?: boolean;
    teamsClientId: string,
    teamsAuthorityUrl: string,
};

export type EnvironmentName =
    "production" |
    "demo" |
    "qa" |
    "dev" |
    "local"

export default function useConfig() {
    const { apiName } = useSelector(state => state.data);
    return findConfig(apiName);
}

export const findConfig = (name: EnvironmentName): Configuration => {
    return (environments[name] ?? environments.production) as Configuration;
};

const environment = Constants.manifest?.extra?.environment as EnvironmentName;

export const WebConfig = environments[environment] as Configuration;
