export enum FutureBookingStatus {
    Requested = "Requested",
    PriorityRequested = " PriorityRequested",
    Confirmed = "Confirmed",
    WaitListed = "WaitListed",
    WaitListedNearTop = "WaitListedNearTop",
}

export type DayTileProps = {
    dayName: string;
    dayNumber: number;
    onClick?: (position: { top: number; left: number }) => any;
    isToday?: boolean;
    isInPast?: boolean;
    status?: FutureBookingStatus;
    loading: boolean,
    hasActiveSession: boolean,
};
