import fetch from "../reducers/fetch";
import {Token} from "../../api/rest";
import * as Api from "../../api/campus/campus.api";
import {DispatchFunc} from "../../model/Types";
import {ILocation} from "../../constants/Util";
import {CampusDTO} from "../../api/campus/dto/CampusDTO";
import {CampusesDataResponse} from "../../api/campus/dto/CampusesDataResponse";

// An action
export const MAP_CAMPUSES = 'MAP_CAMPUSES';

export const setCampusMapView = (result: CampusesDataResponse, expirySeconds: number) => ({
    type: MAP_CAMPUSES,
    result,
    expirySeconds
});

// get campuses as Pins
export function getCampusesToMap(
    location: ILocation,
    distanceMetres: number,
    limit: number,
    cursor: string | null,
    expirySeconds: number,
    onSuccess?: (campuses: CampusDTO[], cursor: string | null) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getCampuses(api, token, location.latitude, location.longitude, distanceMetres, limit, cursor)
            .then((result: CampusesDataResponse) => {
                dispatch(setCampusMapView(result, expirySeconds));
                if(onSuccess){
                    onSuccess(result.data, result.cursor)
                }
            }).catch((err: any) => {
                if (err === 'network_error') {
                    throw err;
                }
            })
    }, arguments);
}
