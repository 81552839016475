import * as Api from "../../api/parking";
import * as ApiTS from "../../api/parking";
import fetch from '../reducers/fetch';
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";
import {IRootReducer} from "../reducers/main";
import { ParkSessionDTO } from "../../model/ParkSessionDTO";
import {BayProblemResponse, completePayment, FailedPaymentsResponse} from "../../api/parking";
import { Territory } from "../../model/Territory";
import { TerritoryDTO } from "../../api/territory/dto/TerritoryDTO";
import { setParksForMapDisplay } from "./parks";
import { getId } from "../../constants/Util";
import { getCurrencyCode, logEvent } from "react/analytics";
import { setBay } from "./parks";
import Strings from "../../constants/localization/localization";
import { ParkSession } from "../../model/ParkSession";
import {
    FINISH_PARKING_DATA,
    HISTORY_PARKING_SESSION,
    PAYMENTS_REQUIRING_AUTHENTICATION,
    SET_SESSIONS
} from "./types";
import { setCurrentParkingSession } from "./session";

function setSessions(sessions: any[]) {
    return {
        type: SET_SESSIONS,
        sessions
    }
}

export function getSessionWithEverything(sessionId: number) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSessionWithEverything(api, token, sessionId).then(({parkSession}) => {
            dispatch(setSessions([parkSession]));
        }, err => {
            console.log('error on getSessionWithEverything', err);
        });
    }, arguments);
}

export function loadSessions(count: number, onSuccess: (response: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.loadSessions(token, api, count).then((r) => {
            onSuccess(r);
            dispatch(setSessions(r.parkSessions));
        });
    }, arguments);
}

export function selectAndHoldBay(parkId: number, bayId: number | undefined, bayFeatureOverride?: any) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token, getState: () => IRootReducer) => {
        const vehicleId = getState().user.currentVehicleId || getState().user?.user?.vehicleId;
        return Api.selectAndHoldBayAPI(api, token, parkId, vehicleId ?? undefined, bayId, bayFeatureOverride);
    }, arguments);
}

export function cancelHold() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.cancelHoldAPI(api, token);
    }, arguments);
}

export function getCurrentParkingSession(onSuccess?: (session: ParkSessionDTO | null) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getCurrentParkingSessionAPI(api, token).then((r) => {
            dispatch(setCurrentParkingSession(r.parkSession));
            onSuccess?.(r.parkSession);
            return r.parkSession;
        }, error => {
            onError?.(error);
        });
    }, arguments);
}

export function getSession(sessionId: number|string, onSuccess?: (session: ParkSessionDTO | null) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSessionAPI(api, token, sessionId).then((r) => {
            dispatch(setCurrentParkingSession(r.parkSession));
            onSuccess?.(r.parkSession);
        });
    }, arguments);
}

export function getSessionHistory(sessionId: number): Promise<ParkSessionDTO> {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSessionAPI(api, token, sessionId).then((r) => {
            dispatch(setHistoryParkingSession(r.parkSession));
            return Promise.resolve(r.parkSession);
        });
    }, arguments) as any as Promise<ParkSessionDTO>;
}

export function cancelReservation (sessionId: number, parkId: number, onSuccess?: (session: ParkSessionDTO | null) => void, onError?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {

        return ApiTS.cancelReservationAPI(api, token, sessionId).then((r) => {
            dispatch(setCurrentParkingSession(null));
            onSuccess?.(r.parkSession);
        }).catch(err => {

            console.log('error on cancel reservation', err);
            if (err === 'network_error') {
                throw err;
            }
            onError?.(err);
        });
    }, arguments);
}

export const setHistoryParkingSession = (session: any) => ({
    type: HISTORY_PARKING_SESSION,
    session,
});

export const setPaymentsRequiringAuthentication = (response: FailedPaymentsResponse) => {
    return {
        type: PAYMENTS_REQUIRING_AUTHENTICATION,
        paymentsRequiringAuthentication: response.failedPayments
    }
};

export function startParking(parkId: number, bayId: number | undefined, isReservation: boolean, gateId: string | undefined, territory: Territory | TerritoryDTO | undefined,
                             userHasCards: boolean,
                             estimatedLeaveTime: string | null,
                             onSuccess: (session: ParkSessionDTO) => void,
                             onFailure: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token, getState: any) => {

        const vehicleId = getState().user.currentVehicleId;
        return ApiTS.startParkingAPI(api, token, parkId, bayId, vehicleId, isReservation, gateId, estimatedLeaveTime).then(({parkSession}) => {

            if (!isReservation) {
                sendStartParkingEventToAnalytics(parkSession, territory, userHasCards);
            }

            dispatch(setCurrentParkingSession(parkSession));
            const mapPark = getState().parks.mapParks[parkSession.park];
            if(!!mapPark) {
                mapPark.currentParksAvailable = mapPark.currentParksAvailable - 1;
                dispatch(setParksForMapDisplay([mapPark], 120));
            }
            onSuccess(parkSession);
        }).catch(err => {
            if(err === 'network_error'){
                throw err;
            } else {
                onFailure(err);
            }
        });
    }, arguments);
}

const sendStartParkingEventToAnalytics = (session: ParkSessionDTO, territory: Territory | TerritoryDTO | undefined, userHasCards: boolean) => {
    try {

        const item = {
            item_id: `${session.id}`,
            item_name: 'casual',
            item_category: 'casual',
            item_brand: 'Parkable',
            price: session.pricePerHour/100
        };

        const firebaseParams = {
            //currency: territory?.currencyCode?.toUpperCase()??'NZD',
            value: session.pricePerHour/100, // Total Revenue
            items: [item]
        } as {[key: string]: any};

        const facebookParams = {
            fb_content_type: 'parkSession',
            fb_content_id: `${getId(session.park)}`,
            fb_num_items: 1,
            fb_payment_info_available: userHasCards ? 1 : 0,
        } as {[key: string]: number | string};

        (async () => {
            const currencyCode = await getCurrencyCode(territory, getId(session.park));
            if (currencyCode) {
                facebookParams.fb_currency = currencyCode.toUpperCase();
                firebaseParams.currency = currencyCode.toUpperCase();
                logEvent({price: session.pricePerHour, currencyCode: currencyCode.toUpperCase()}, 'fb_mobile_initiated_checkout', facebookParams, 'begin_checkout', firebaseParams);
            } else {
                console.log('unable to get territory currency code');
            }
        })();

    } catch (e) {
        console.log('error on create analytics', e)
    }
};

export function startParkingFromReservation(sessionId: number, gateId: string | undefined, territory: Territory | TerritoryDTO | undefined,
                                            userHasCards: boolean,
                                            estimatedLeaveTime: string | null,
                                            onSuccess: (session: ParkSessionDTO | null) => void,
                                            onFailure: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {

        return ApiTS.startParkingFromReservationAPI(api, token, sessionId, gateId, estimatedLeaveTime).then(({parkSession}) => {

            //     if (typeof r.parkSession.park.territory === 'number') {
            //         return Promise.all([Promise.resolve(r.parkSession), TerritoryApi.getTerritoryAPI(api, token, r.parkSession.park.territory)]);
            //     } else {
            //         return Promise.all([Promise.resolve(r.parkSession), Promise.resolve({territory: r.parkSession.park.territory})]);
            //     }
            // }).then(([parkSession, territory]) => {
            //     parkSession.park = {...parkSession.park, territory: territory.territory};

            if (!!parkSession) {
                sendStartParkingEventToAnalytics(parkSession, territory, userHasCards);
            }
            dispatch(setCurrentParkingSession(parkSession));
            onSuccess?.(parkSession);
        }).catch(err => {
            if(err === 'network_error'){
                throw err;
            }
            else if(onFailure){
                onFailure(err);
            }
        });
    }, arguments);
}

export function reportCarInBay(
    parkId: number,
    bayId: number | undefined,
    carRegistration: string | null,
    currentSession: number | undefined,
    employeeSubscriptionId: number | undefined,
    completionCallback: (response: BayProblemResponse) => void,
    onFailCallback?: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return ApiTS.reportCarInBay(api, token, parkId, bayId, carRegistration, currentSession, employeeSubscriptionId).then((result) => {
            completionCallback(result);//to stop the loading and take the  result so we dont have to use the store to update UI
            console.log("updated parking session = ", result);
            if(!!result.parkSession) {
                if (!!result.bay) {
                    dispatch(setBay(result.bay.park, result.bay));
                }

                dispatch(setCurrentParkingSession(result.parkSession));
            }
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            } else {
                console.log("err", err);
                onFailCallback?.(err?.message || Strings.internal_error_if_persists)
            }
        });
    }, arguments);
}

export const setFinishedParkingData = (finishParkingData: any) => ({
    type: FINISH_PARKING_DATA,
    finishParkingData,
});

function sendSessionEndedAnalyticsEvent(userId: number, sessionId: number, parkId: number, price: number, territory: Territory | undefined) {
    try {

        const item = {
            item_id: `${sessionId}`,
            item_name: 'casual',
            item_category: 'casual',
            item_brand: 'Parkable',
            price: price/100
        };

        const firebaseParams = {
            //currency: territory?.currencyCode?.toUpperCase()??'NZD',
            value: price/100,
            items: [item],
            transaction_id: `${sessionId}`
        } as {[key: string]: any };

        const facebookParams = {
            fb_num_items: 1,
            fb_content_type: 'parkSession',
            fb_content_id: `${parkId}`,
        } as {[key: string]: string | number};

        (async () => {
            const currencyCode = await getCurrencyCode(territory, parkId);
            if (!!currencyCode) {
                facebookParams.fb_currency = currencyCode.toUpperCase();
                firebaseParams.currency = currencyCode.toUpperCase();
                logEvent({price, currencyCode: currencyCode.toUpperCase()},'fb_mobile_purchase', facebookParams, 'purchase', firebaseParams);
            } else {
                console.log('unable to get currency code')
            }
        })();


    } catch (e) {
        console.log('error on send analytics', e);
    }
}

export function stopParking(sessionId: number, territory: Territory | undefined, onSuccess: (session: ParkSessionDTO) => void, onError: (err: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token, getState: () => IRootReducer) => {

        return ApiTS.stopParkingAPI(api, token, sessionId).then((r) => {
            console.log('stopped parking: ', r);
            const updatedSession = r.parkSession!;
            const mapPark = getState().parks.mapParks[updatedSession.park];
            if (!!mapPark) {
                mapPark.currentParksAvailable = mapPark.currentParksAvailable! + 1;
                // @ts-ignore
                dispatch(setParksForMapDisplay([mapPark], 120));
            }

            dispatch(setCurrentParkingSession({
                ...updatedSession,
                endedOnDevice: true
            }));
            dispatch(setFinishedParkingData(r));

            sendSessionEndedAnalyticsEvent(updatedSession.parker, updatedSession.id, updatedSession.park, updatedSession.transactionAmount ?? 0, territory);

            onSuccess?.(updatedSession)
        }).catch(err => {

            console.log("Could not stop parking ", err);

            if (err === 'network_error') {
                throw err;
            }
            if (err?.code === 179) {
                //ended on another device, re-fetch so we can show summary screen
                dispatch(getSession(sessionId));
            }
            if (onError) {
                onError(err)
            }

        });
    }, arguments);
}

export function getPaymentsRequiringAuthentication() {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {

        return ApiTS.getPaymentsRequiringAuthentication(api, token).then((result) => {

            dispatch(setPaymentsRequiringAuthentication(result));
            return Promise.resolve(result);
        }).catch(err => {
            if(err === 'network_error'){
                throw err;
            }
            return Promise.reject(err);
        });
    }, arguments);
}

export function retryFailedPayment(sessionId:number, callback: (success: boolean, message: string, session: ParkSession | null) => void | null) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token, getState: () => IRootReducer) => {
        return ApiTS.retryFailedPayment(api, token, sessionId).then((r) => {
            const currentSession = getState().parking.currentSession;
            if(!!currentSession) {
                currentSession.transactionSuccess = true;
                dispatch(setCurrentParkingSession(currentSession));
            }

            !!callback && callback(r.success, r.message, r.updatedSession);
        });
    }, arguments);
}
