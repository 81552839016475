/**
 * Created by Chris on 14/01/17.
 */
import Qs from 'qs';

let requests = [];

export const GOOGLE_API_KEY = 'AIzaSyCEZGEzEGDM95JardM4MxxqH6WeFxY6Z4E';
//const URL_GOOGLE_PLACE_AUTO_COMPLETE_QUERY = 'https://maps.googleapis.com/maps/api/place/queryautocomplete/json?&input=';
const URL_GOOGLE_PLACE_AUTO_COMPLETE = 'https://maps.googleapis.com/maps/api/place/autocomplete/json?&input=';
const URL_GOOGLE_LOCATION = 'https://maps.googleapis.com/maps/api/place/details/json?';
const URL_GOOGLE_ROUTE_DIRECTIONS = 'https://maps.googleapis.com/maps/api/directions/json?';
const URL_GOOGLE_GEO_LOCATION = 'https://maps.googleapis.com/maps/api/geocode/json?';
const URL_GOOGLE_NEAR_BY_SEARCH = 'https://maps.googleapis.com/maps/api/place/nearbysearch/json?';
const URL_GOOGLE_RADAR_SEARCH = 'https://maps.googleapis.com/maps/api/place/radarsearch/json?';
const URL_GOOGLE_DISTANCE_MATRIX = 'https://maps.googleapis.com/maps/api/distancematrix/json?';
const TYPE_GEO_CODE = 'geocode';
const TYPE_ADDRESS = 'address';
const TYPE_GAS_STATION = 'gas_station';

let LANGUAGE = 'en';

export function getGeoLocationAPI(latitude: number, longitude: number) {
    return new Promise((resolve, reject) => {
        let request = new Request(URL_GOOGLE_GEO_LOCATION + Qs.stringify({
            latlng: latitude + ', ' + longitude,
            key: GOOGLE_API_KEY,
        }), {
            method: 'GET',
        });
        return fetchJSON(request).then((r) => {
            return resolve(r);
        }).catch(err => {
            return reject(err);
        });
    });
}

export function requestPredictions(text: string, countryCodes: any, latitude?: number, longitude?: number, sessiontoken?: any) {
    return new Promise((resolve, reject) => {
        if (text.length >= 2) {
            const query = {
                key: GOOGLE_API_KEY,
                sessiontoken: sessiontoken,
                language: LANGUAGE,

            } as any;

            if(!!countryCodes){
                query.components = countryCodes.length > 5 ? null : countryCodes.map((c: any) => 'country:' + c).join('|');
            }

            if(!!latitude && !!longitude){
                query.radius = 2000;
                query.location = `${latitude},${longitude}`;
            }

            let request = new Request(URL_GOOGLE_PLACE_AUTO_COMPLETE +
                encodeURIComponent(text) + '&' + Qs.stringify(query), {
                method: 'GET',
            });

            return fetchJSON(request).then((r: any) => {
                let predictions = r.predictions;
                return resolve(predictions);
            }).catch(err => {
                return reject(err);
            });

        } else {
            return resolve([]);
        }
    });
}

export function requestLocationDataAPI(placeId: any, sessiontoken?: any) {
    const request = new Request(URL_GOOGLE_LOCATION + Qs.stringify({
        key: GOOGLE_API_KEY,
        sessiontoken,
        placeid: placeId,
        language: LANGUAGE,
        //basic data types that are free - not atmosphere and contact info
        fields: "address_component,adr_address,business_status,formatted_address,geometry,name,place_id"
    }), {
        method: 'GET',
    });
    return fetchJSON(request);
}

export function fetchJSON(request: any) {
    return new Promise((resolve, reject) => {
        let fetchCall = fetch(request).then(r => {
            return r.json()
                .then(json => {
                    return r.ok ? resolve(json) : reject(json)
                })
                .catch(e => {
                    return reject(e);
                })
        }).catch(e => {
            return reject(e);
        });
        requests.push(fetchCall);
        return fetchCall;
    });
}

export function parseLocationData(r: any) {
    if (r.status === 'OK') {
        const locationData = r.result;
        return locationData.geometry.location;
    } else {
        return null;
    }
}

export function getRouteData(startLocationLat: number, startLocationLong: number, endLocationLat: number, endLocationLong: number) {
    return new Promise((resolve, reject) => {
        let request = new Request(URL_GOOGLE_ROUTE_DIRECTIONS + Qs.stringify({
            key: GOOGLE_API_KEY,
            origin: startLocationLat + ',' + startLocationLong,
            destination: endLocationLat + ',' + endLocationLong,
        }), {
            method: 'GET',
        });

        return fetchJSON(request).then((r) => {
            return resolve(r);
        }).catch(err => {
            return reject(err);
        });
    });

}

export function getDriveTime(startLocationLat: number, startLocationLong: number, endLocationLat: number, endLocationLong: number, departureTime: any) {
    return new Promise((resolve, reject) => {
        let request = new Request(URL_GOOGLE_DISTANCE_MATRIX + Qs.stringify({
            key: GOOGLE_API_KEY,
            origins: startLocationLat + ',' + startLocationLong,
            destinations: endLocationLat + ',' + endLocationLong,
            departure_time: departureTime,
            traffic_model: 'pessimistic'
        }), {
            method: 'GET',
        });

        return fetchJSON(request).then((r) => {
            return resolve(r);
        }).catch(err => {
            return reject(err);
        });
    });

}

