import fetch from '../reducers/fetch';
import Strings from "../../constants/localization/localization";
import { showAlert } from "../../alerts";
import * as Api from "../../api/sensors";
import * as ParkApi from "../../api/park";
import {DispatchFunc} from "../../model/Types";
import {Token} from "../../api/rest";
import {Bay} from "../../model/Bay";


export const ADMIN_BAY_SENSORS_RECEIVED = 'ADMIN_BAY_SENSORS_RECEIVED';
export const ADMIN_BAY_SENSOR_RECEIVED = 'ADMIN_BAY_SENSOR_RECEIVED';
export const CHECK_SENSOR_RECEIVED = 'CHECK_SENSOR_RECEIVED';
export const ADMIN_PARK_BAYS_RECEIVED = "ADMIN_PARK_BAYS_RECEIVED";
export const ADMIN_PARK_BAYS_LOADING = "ADMIN_PARK_BAYS_LOADING";

export function retrieveSensorsByEUI(partialEUI: any) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.searchByEUI(api, token, partialEUI).then((r) => {
            dispatch(adminSensorsReceived(r.baySensors));
        });
    }, arguments);
}

export function assignSensorToQRCode(sensorId: string, qrCode: string, onFail?: () => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.assignSensorToQRCode(api, token, sensorId, qrCode).then((sensor) => {
            if (sensor) {
                dispatch(adminSensorReceived(sensor));
            } else {
                onFail?.();
            }
        })
    }, arguments);
}

export function checkQRCode(qrCode: string, onFail?: () => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.checkQRCode(api, token, qrCode).then((result) => {
            if(result.baySensors.length > 0) {
                //QR search  retreives max of 1
                dispatch(checkSensorReceived(result.baySensors[0]));
            } else {
                onFail?.();
            }
        });
    }, arguments);
}

export function retrieveParkSensors(parkId: number){
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.retrieveParkSensors(api, token, parkId).then((result) => {
            dispatch(adminSensorsReceived(result.baySensors));
        });
    }, arguments);
}

export function checkSensorReceived(sensor: any) {
    return {
        type: CHECK_SENSOR_RECEIVED,
        sensor
    }
}

function adminSensorsReceived(sensors: any) {
    return {
        type: ADMIN_BAY_SENSORS_RECEIVED,
        sensors
    }
}

function adminSensorReceived(sensor: any) {
    return {
        type: ADMIN_BAY_SENSOR_RECEIVED,
        sensor
    }
}


export function retrievedParkBays(parkId: number) {

    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        dispatch(adminParkBaysReceived(parkId, []));
        dispatch(parkBaysLoading(true));

        return ParkApi.getBaysForPark(api, token, parkId).then((r) => {
            dispatch(adminParkBaysReceived(parkId, r.bays));
            dispatch(parkBaysLoading(false));
            return new Promise(resolve => resolve(r.bays))
        }).catch((err) => {
            dispatch(parkBaysLoading(false));
            throw err;
        });
    }, arguments);

}
function adminParkBaysReceived(parkId: number, bays: Bay[]) {
    return {
        type: ADMIN_PARK_BAYS_RECEIVED,
        bays,
        parkId,
    }
}

function parkBaysLoading(parkBaysLoading: any) {
    return {
        type: ADMIN_PARK_BAYS_LOADING,
        parkBaysLoading,
    }
}

export function assignBayToBaySensor(qrCode: any, bayId: number, parkId: number, onSuccess?: () => void, onError?: (err?: any) => void) {
    return fetch((dispatch: DispatchFunc, api: string, token: Token) => {
        return Api.getSensorsByQR(api, token, qrCode).then(({ baySensors }) => {

            if (baySensors && baySensors.length > 0) {
                const baySensor = baySensors[0];
                Api.assignSensorToBay(api, token, baySensor.id, bayId, parkId).then(() => {

                    dispatch(retrievedParkBays(parkId));
                    onSuccess?.();
              
                }).catch((e) => {
                    console.log('assignBayToBaySensor', e);
                    showAlert(Strings.fail_to_assign, Strings.error);
                    onError?.();
                })
            } else { 
                showAlert(Strings.no_bay_sensor, Strings.error);
                onError?.();
            }
        }).catch((err) => {
            if (err === 'network_error') {
                throw err;
            }
            onError?.(err);

        })
    }, arguments);
}