import {FirebaseService} from "../../services/firebase.service";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {sendMagicLink} from "../../api/user";
import {showAlert} from "../../alerts";
import Strings from "../../constants/localization/localization";
import { Platform } from "react-native";

export const EMAIL_LINK_TIMEOUT_MS = 300000;//5 mins

export const clearMagicLinkData = async () => {
    await AsyncStorage.removeItem('@email');
    await AsyncStorage.removeItem('@timeout');
}

export const requestMagicLink = async (api: string, emailAddress: string) => {
    await AsyncStorage.setItem('@email', emailAddress);
    await AsyncStorage.setItem('@timeout', new Date().getTime().toString());
    return await sendMagicLink(api, emailAddress, Platform.OS === "web" ? location.origin : undefined);
}

export const handleMagicLink = async (url: string) => {
    if(!url || !FirebaseService.isSignInWithEmailLink(url)){
        return;
    }

    try {

        if(FirebaseService.getCurrentUser() !== null){
            throw {code: "auth/signed_in"};
        }

        const email = await AsyncStorage.getItem('@email');
        const timeout = await AsyncStorage.getItem('@timeout');

        if(!email || !timeout){
            throw {code: "auth/invalid-email"};
        }

        const now = new Date().getTime();
        const then = Number.parseInt(timeout, 10);
        if(now - then > EMAIL_LINK_TIMEOUT_MS){
            throw {code: "auth/invalid-action-code"};
        }

        const user = await FirebaseService.signInWithEmailLink(email!, url);

        await clearMagicLinkData();

        return user;
    }
    catch(err: any){
        console.log("error signing on with link", err);
        if(err.code === "auth/signed_in"){
            showAlert(Strings.already_signed_in);
        }
        else if(err.code === "auth/invalid-action-code"){
            showAlert(Strings.link_already_used_or_expired);
        }
        else if(err.code === "auth/invalid-email"){
            showAlert(Strings.email_links_same_device);
        }
        else {
            showAlert(Strings.email_link_generic_error);
        }
    }
}
