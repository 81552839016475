/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 26/08/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/


import React, { PropsWithChildren, useRef } from "react";
import { ScrollView, View } from "react-native";
import NavigationHeader from "./navigation/NavigationHeader";
import { Colours } from "../../constants/colors";
import LoadingOverlay_new from "./LoadingOverlay_new";

const defaultProps = {
    title: null,
    onBackPress: null,
    overrideOnback: false,
    footerStyle: null,

    navColor: null,
    backgroundColor: null,
    scrollable: true,
    scrollToEnd: false,
    pushTop: false,
    loading: false,
    blue: false,
    blueBackground: false,
    blueNav: false,
    white: false,
    whiteNav: false,
    solidBlueNav: false,
    transparentNav: false,
    hideNavBar: false,
    navTextWhite: false,
    footer: null,

    onRightIconPress: null,
    rightIcon: null,
}

interface ContainerProps {
    title?: string,
    onBackPress?: () => void,
    overrideOnback?: boolean,
    onBackfooterStylePress?: any,

    pushTop?: boolean | number,
    navColor?: string,
    backgroundColor?: string,
    scrollable?: boolean,
    scrollToEnd?: boolean,
    loading?: boolean,
    blue?: boolean,
    blueBackground?: boolean,
    blueNav?: boolean,
    white?: boolean,
    whiteNav?: boolean,
    solidBlueNav?: boolean,
    transparentNav?: boolean,
    hideNavBar?: boolean,
    navTextWhite?: boolean,

    footer?: any,

    onRightIconPress?: any,
    rightIcon?: any,
    rightIconButtonStyle?: any,

    hideRightIcon?: any,

    containerStyle?: any,
    headerProps?: any,
    pulse?: any,
    style?: any,
    footerStyle?: any,
    contentContainerStyle?: any,
}

const Container = (props: PropsWithChildren<ContainerProps>) => {

    const scrollView = useRef<any>(null);

    let backgroundColor = !!props.backgroundColor ? props.backgroundColor : Colours.ParkableGrey;
    let blueNav = !!props.blueNav;
    let titleStyle = null;
    let pushTop = 0;

    if (!!props.blue) {
        blueNav = true;
        backgroundColor = Colours.ParkableWeirdBlue;
    }

    if (!!props.blueBackground) {
        backgroundColor = Colours.ParkableDarkBlue;
    }

    if (!!props.navTextWhite) {
        titleStyle = {color: Colours.White};
    }

    if (props.white) {
        backgroundColor = Colours.White;
    }



    if (!!props.pushTop) {

        if (typeof props.pushTop === 'boolean') {
            pushTop = 65;
        } else {
            pushTop = props.pushTop;
        }
    }

    const _props = {...props};
    Object.keys(defaultProps).map((propType) => {
        // @ts-ignore
        delete _props[propType];
    });

    return (
            <View style={[{backgroundColor: backgroundColor, flex: 1, width: '100%', opacity: 1}, props.containerStyle]}>

                {!props.hideNavBar ? <NavigationHeader overrideOnback={!!props.overrideOnback}
                                                            {...props.headerProps}
                                                            onRightIconPress={props.onRightIconPress}
                                                            hideRightIcon={props.hideRightIcon}
                                                            rightIcon={props.rightIcon}
                                                            rightIconButtonStyle={props.rightIconButtonStyle}
                                                            titleStyle={titleStyle}
                                                            title={props.title}
                                                            onBackPress={props.onBackPress}
                                                            blue={blueNav}
                                                            solidBlueNav={props.solidBlueNav}
                                                            white={props.white || props.whiteNav}
                                                            backgroundColor={props.navColor}
                                                            transparent={props.transparentNav}
                                                            pulse={props.pulse}/> : null}

                {props.scrollable !== false ?
                    <ScrollView {..._props}
                                style={[{width: '100%', backgroundColor: backgroundColor, flex: 1, zIndex: 999}, props.style]}
                                testID={'ParkScrollView'}
                                bounces={false}
                                ref={ref => scrollView.current = ref}
                                onContentSizeChange={() => {
                                    if (props.scrollToEnd && scrollView.current?.scrollToEnd) {
                                        scrollView.current.scrollToEnd();
                                    }
                                }}
                                keyboardShouldPersistTaps={"handled"}
                                contentContainerStyle={[{width: '100%', paddingTop: 5, backgroundColor: backgroundColor}, props.contentContainerStyle]}>
                        {props.children}
                    </ScrollView> :
                    <View {..._props} style={[{width: '100%', paddingTop: pushTop, backgroundColor: backgroundColor, flex: 1}, props.contentContainerStyle, props.style]}>
                        {props.children}
                    </View>}

                {!!props.footer &&
                    <View style={props.footerStyle}>
                        {props.footer}
                    </View>}

                <LoadingOverlay_new loading={props.loading??false}/>
            </View>
    );
}

export default Container;

