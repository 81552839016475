import React, { FunctionComponent, useState, useCallback } from "react";
import { View, StyleSheet } from "react-native";
import Colours from "react/parkable-components/styles/Colours";
import Text from "react/parkable-components/text/Text";
import Radio from "react/parkable-components/radio/Radio";
import CheckBox from "react/parkable-components/checkBox/CheckBox";
import Strings from '../../../constants/localization/localization';
import { IRootReducer } from "../../../redux/reducers/main";
import { DispatchFunc, LTDisplayType, CasualDisplayType } from "../../../model/Types";
import { connect } from "react-redux";
import { saveMapPreferences } from "../../../redux/actions/map";
import {createRoute} from "../../../navigation/constants";
import {Routes} from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../common/ParkableBaseView";

type Props = {}
const MapSettingsView = (props: Props & ReturnType<typeof reduxProps> & {dispatch: DispatchFunc}) => {
    const { preferences, dispatch } = props;

    const [loading, setLoading] = useState<boolean>(false);
    const [hideClosedParks, setHideClosedParks] = useState<boolean>(preferences.hideClosedParks);
    const [hideFullParks, setHideFullParks] = useState<boolean>(preferences.hideFullParks);

    const onChangeCasualPin = useCallback(async ({value}: {value: CasualDisplayType}) => {
        setLoading(true);
        await dispatch(saveMapPreferences({...preferences, casualDisplayType: value}));
        setLoading(false);
    }, [dispatch, preferences]);

    const onChangeLTPin = useCallback(async ({value}: {value: LTDisplayType}) => {
        setLoading(true);
        await dispatch(saveMapPreferences({...preferences, ltDisplayType: value}));
        setLoading(false);
    }, [dispatch, preferences]);

    const toggleClosed = useCallback(async () => {
        setLoading(true);
        setHideClosedParks(!hideClosedParks);
        await dispatch(saveMapPreferences({...preferences, hideClosedParks: !hideClosedParks}));
        setLoading(false);
    }, [hideClosedParks, dispatch, preferences]);

    const toggleFull = useCallback(async () => {
        setLoading(true);
        setHideFullParks(!hideFullParks);
        await dispatch(saveMapPreferences({...preferences, hideFullParks: !hideFullParks}));
        setLoading(false);
    }, [hideFullParks, dispatch, preferences]);

    const casualDisplayType = preferences.casualDisplayType === CasualDisplayType.PRICE_PER_DAY
        ? CasualDisplayType.PRICE_PER_HOUR : preferences.casualDisplayType;

    return <ParkableBaseView loading={loading}>
        <View style={styles.container}>
            <View style={styles.header}>
                <Text h1 bold>{Strings.map_settings}</Text>
            </View>
            <View style={styles.header2}>
                <Text h2 bold>{Strings.pin_display.toUpperCase()}</Text>
                <View style={styles.section}>
                    <CheckBox checked={hideClosedParks} onChange={toggleClosed} ><Text>{Strings.hide_closed_carparks}</Text></CheckBox>
                    <CheckBox checked={hideFullParks} onChange={toggleFull}><Text>{Strings.hide_full_carparks}</Text></CheckBox>
                </View>
            </View>
            <View style={styles.header2}>
                <Text h2 bold>{Strings.casual_pin_labels.toUpperCase()}</Text>
                <View style={styles.section}>
                    <Radio  options={[{label: Strings.display_price_per_hour, value: CasualDisplayType.PRICE_PER_HOUR}]}
                            onChange={onChangeCasualPin}
                            value={casualDisplayType} />
                    <Radio  options={[{label: Strings.display_bays_available, value: CasualDisplayType.SPOTS_LEFT}]}
                            onChange={onChangeCasualPin}
                            value={casualDisplayType} />
                </View>
            </View>
            <View style={styles.header2}>
                <Text h2 bold>{Strings.lt_pin_labels.toUpperCase()}</Text>
                <View style={styles.section}>
                    {/*<Radio  options={[{label: Strings.display_weekly_sub_price, value: LTDisplayType.PRICE_PER_WEEK}]}
                            onChange={onChangeLTPin}
                            value={preferences.ltDisplayType} /> />
                    <Radio  options={[{label: Strings.display_monthly_sub_price, value: LTDisplayType.PRICE_PER_MONTH}]}
                            onChange={onChangeLTPin}
                            value={preferences.ltDisplayType} /> */}
                    <Radio  options={[{label: Strings.display_subscriptions_available, value: LTDisplayType.SPOTS_LEFT}]}
                            onChange={onChangeLTPin}
                            value={preferences.ltDisplayType} />
                </View>
            </View>
        </View>
    </ParkableBaseView>

}

const reduxProps = (state: IRootReducer) => {
    return {
        preferences: state.maps.preferences
    }
}

const styles = StyleSheet.create({
    header: {
        top: 9,
    },
    header2: {
        top: 18
    },
    section: {
        marginBottom: 36
    },
    container: {
        flex: 1,
    }
});

export default connect(reduxProps)(MapSettingsView) as FunctionComponent<Props>

export const MapSettingsRoute = createRoute({
    path: Routes.MapSettingsView,
});
