import React, { Ref, useImperativeHandle, useRef } from "react";
import { DialogProps, DialogRef } from "react/parkable-components/dialog/Dialog";
import { MAX_WIDTH_WEB } from "../../root/container/container";
import { Platform } from "react-native";
import DialogComponent from "react/parkable-components/dialog/Dialog";

type Props = Omit<DialogProps, "statusBarTranslucent">;

function Dialog(props: Props, ref: Ref<DialogRef>) {

    const myRef = useRef<DialogRef | null>(null);

    useImperativeHandle(ref, () => ({
        show: show,
        hide: hide
    }));

    function show() {
        myRef.current?.show();
    }

    function hide() {
        myRef.current?.hide();
    }

    return <DialogComponent
        ref={myRef}
        style={Platform.select({
            web: {
                maxWidth: MAX_WIDTH_WEB,
                alignSelf: "center",
            }
        })}
        statusBarTranslucent={true}
        {...props}
    />;
}

export default React.forwardRef<DialogRef, Props>(Dialog);
