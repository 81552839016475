/**

 ▬▬ι═══════ﺤ            -═══════ι▬▬
 Created by Chris on 21/09/17.
 ▬▬ι═══════ﺤ            -═══════ι▬▬

 **/
import Strings from "./localization/localization";

export default function validateEmail(email: string): string | null {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)){
        return Strings.invalid_email_longer;
    }
    return null;
}