import React from "react";
import {Text, TableRow} from 'react/parkable-components';
import { RewardDTO } from "../../api/rewards/dto/Reward";
import {BonusBayDTO} from "../../api/bonusBay/dto/BonusBay";
import {usePark} from "../../api/park";
import Strings from '../../constants/localization/localization';
import moment from "moment";

export type Props = {
    reward: RewardDTO,
    bonusBay: BonusBayDTO,
    isPending?: boolean
}

export const RewardsActivityView = (props: Props) => {

    const { reward, bonusBay, isPending} = props;
    const { park } = usePark(bonusBay.park);
    const amount = Math.round((reward.amount - reward.redeemedAmount) /100);

    return (<TableRow textProps={{small:true}}
                      label={moment(reward.availableAt).format("Do MMMM YYYY")}
                      iconLeft={"dollaroutlined"}
                      contentRight={<Text small green={!isPending} grey={isPending} style={{fontStyle: isPending ? 'italic' : 'normal'}}>{Strings.rewards.amount_reward(amount)}</Text>}>
                <Text small>{park ? park.address : Strings.loading}</Text>
            </TableRow>);
};
