import React, {Component} from 'react'
import {StyleSheet, Text, TextInput as RNTextInput, View} from 'react-native';
import {Colours} from "../../constants/colors";
import {FONT_REGULAR} from "../../constants/constants";
import PropTypes from 'prop-types';
import Strings from '../../constants/localization/localization';

const unfocusedInputBorder = '#dcdcdc';
const focusedInputBorder = '#358fff';

export default class TextInput extends Component<any, {
    isFocused: boolean,
    inputText: string,
    error: any,
}> {

    static propTypes = {
        hideErrorBox: PropTypes.bool,
        center: PropTypes.bool,
        error: PropTypes.string,
        errorColor: PropTypes.string,
        placeholder: PropTypes.string,
        placeholderTextColor: PropTypes.string,
        iconLeft: PropTypes.string,
        iconRight: PropTypes.string,
        onChangeText: PropTypes.any,
        textStyle: PropTypes.any,
        containerStyle: PropTypes.any,
        border: PropTypes.bool,
        isTextBox: PropTypes.bool,
        autoFocus: PropTypes.bool,
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        hideErrorBox: false,
        center: false,
        error: null,
        errorColor: 'red',
        placeholder: '',
        placeholderTextColor: Colours.placeholderColor,
        iconLeft: null,
        iconRight: null,
        textStyle: {},
        containerStyle: {},
        border: true,
        isTextBox: false,
        autoFocus: false,
        disabled: false,
        onFocus: () => {
        },
        onBlur: () => {
        },
    };

    input: any = null;

    constructor(props: any) {
        super(props);

        this.state = {
            isFocused: false,
            inputText: '',
            error: props.error,
        };
    }

    UNSAFE_componentWillReceiveProps(props: any) {
        if (!!props.meta && !!props.meta.error && props.meta.error !== '' && props.meta.error.length !== 0) {
            this.setError(props.meta.error);
        }
    }

    focus = () => {
        this.input.focus();
        if (!!this.props.input && !!this.props.input.focus) {
            this.props.input.focus();
        }
    };

    clear = () => {
        if (this.state.inputText) {
            this.input.clear();
        }
    };

    blue = () => {
        this.clear();
        this.blur();
    };

    blur = () => {
        this.input.blur();
        if (!!this.props.input && !!this.props.input.blur) {
            this.props.input.blur();
        }
    };

    setError = (error: any) => {
        this.setState({
            error,
        });
    }

    clearError = () => {
        this.setState({
            error: null,
        });
    }

    onTextChanged = (text: any) => {
        if (!!this.props.onChangeText) {
            this.props.onChangeText(text);
        }


        if (!!this.props.input && !!this.props.input.onChange) {
            this.props.input.onChange(text);
        }

        if (text !== this.state.inputText) {
            this.clearError();
        }

        this.setState({inputText: text});
    }

    onFocus = () => {
        this.setState({
            isFocused: true,
        });
        this.props.onFocus();
    }

    onBlur = () => {
        this.setState({
            isFocused: false,
        });
        this.props.onBlur();
    }

    getState = () => {
        return this.state
    }

    isFocused = () => {
        return this.state.isFocused;
    }

    render() {

        let extraTextStyles = (this.props.center ? {textAlign: 'center', padding: 5} : {}) as any;

        let borderColor = this.props.border ? this.state.isFocused ? focusedInputBorder : unfocusedInputBorder : 'white';
        let hasError = null;
        let props = {...this.props};
        let value = this.props.value;
        let placeholderTextColor = this.props.placeholderTextColor;


        if (!!this.state.error) {
            borderColor = this.props.errorColor;
            if (!this.props.hideErrorBox) {
                hasError = (
                    <View style={styles.errorContainer}>
                        <Text style={styles.errorText}>{this.state.error || Strings.error}</Text>
                    </View>
                );
            }
        }

        let extraContainerStyles = {borderColor} as any;

        if (this.props.isTextBox) {
            extraContainerStyles.height = 125;
            props.multiline = true;
            props.numberOfLines = 5;
            props.maxLength = 1000;
            extraTextStyles.height = 125;
        }

        if (!!this.props.disabled) {
            extraContainerStyles.backgroundColor = Colours.ParkableXtraLightGrey;
            placeholderTextColor = Colours.ParkableDarkGrey;
        }

        if (this.props.input && this.props.input.value) {
            value = this.props.input.value;
        }

        return <View style={[styles.container, this.props.style]}>
                <View style={[styles.containerStyle, this.props.containerStyle, extraContainerStyles]}>
                    <RNTextInput ref={(ref) => this.input = ref}
                                 testID={this.props.placeholder || 'RNTextInput'}
                                 underlineColorAndroid={'transparent'}
                                 {...props}
                                 placeholderTextColor={placeholderTextColor}
                                 style={[styles.textInput, extraTextStyles, this.props.textStyle]}
                                 onChangeText={this.onTextChanged}
                                 onFocus={this.onFocus}
                                 onBlur={this.onBlur}
                                 value={value}/>
                    {this.props.disabled ? <View style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, backgroundColor: 'transparent'}}/> : null}
                </View>
                {hasError}
            </View>
    }
}

const styles = StyleSheet.create({
    container: {
        marginVertical: 10,
        flex: 1
    },
    containerStyle: {
        borderRadius: 5,
        borderWidth: 1,
        backgroundColor: Colours.White,
        flexDirection: 'row',
        zIndex: 10,
    },
    textInput: {
        flex: 1,
        fontFamily: FONT_REGULAR,
        color: Colours.ParkableDarkGrey,
        backgroundColor: Colours.Transparent,
        padding: 5,
        fontSize: 16,
        height: 40,
    },
    errorContainer: {
        backgroundColor: Colours.Red,
        marginTop: -5,
        paddingTop: 10,
        paddingBottom: 5,
        paddingHorizontal: 10,
        zIndex: 5,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
    },
    errorText: {
        color: Colours.ParkableGrey,
        fontWeight: "bold",
        fontFamily: FONT_REGULAR,
    }
});
