import React, { useEffect, useState } from "react";
import Strings from "../../constants/localization/localization";
import Button from "react/parkable-components/button/Button";
import Spinner from "react/parkable-components/spinner/Spinner";
import Text from "react/parkable-components/text/Text";
import { Image, StyleSheet, View } from "react-native";
import { Colours } from "../../constants/colors";
import localizeCurrency from "../../constants/localization/localizeCurrency";
import { Routes } from "../../navigation/root/root.paths";
import { DispatchFunc } from "../../model/Types";
import { createRoute, NavigationProps, useNavigation } from "../../navigation/constants";
import ParkableBaseView from "../common/ParkableBaseView";
import { PADDING } from "../../root/root.constants";
import { useParkSessionV3 } from "../../api/parkSession/parkSession.api";
import { usePark } from "../../api/park/park.api";
import { getTimeElapsed } from "./SessionElapsedFunctions";
import { useTerritory } from "../../api/territory/territory.api";

const sessionAutoClosedBackground = require("../../resources/sessionAutoClosedBackground.png");

class AutoEndSessionViewParams {
    sessionId: number;
}

type AutoEndSessionProps =
    NavigationProps<Routes.AutoEndSessionView>
    & { dispatch: DispatchFunc };

export default function AutoEndSessionView(props: AutoEndSessionProps) {

    const sessionId = props.route.params?.sessionId;

    const { parkSession } = useParkSessionV3(sessionId);
    const { park } = usePark(parkSession?.park);
    const {territory} = useTerritory(park?.territory);
    const currencyCode = territory?.currencyCode;

    const loading = !territory;

    const [timeElapsedString, setTimeElapsedString] = useState("");

    const navigation = useNavigation();

    const onParkDetailsPress = () => {
        navigation.reset({
            routes: [{
                name: Routes.SessionSummary
            }]
        });
    };

    const onBackPress = () => navigation.pop();

    useEffect(() => {
        if (parkSession && park) {
            const interval = setInterval(() => {
                setTimeElapsedString(getTimeElapsed(parkSession, park));
            }, 1000);
            return () => clearInterval(interval);
        }
    }, []);

    return (
        <ParkableBaseView
            removeStandardMargins
            toolbarStyle={{ marginHorizontal: PADDING }}
            onBackPress={onBackPress}
        >
            <View style={styles.bodyView}>
                <Text h2>{Strings.session_was_auto_closed}</Text>
                <View style={styles.imageContainer}>
                    <Image style={styles.image} source={sessionAutoClosedBackground} resizeMode={"cover"} />
                </View>
                <View style={styles.sessionDetails}>
                    <Text p style={styles.sessionAutoClosedText}>{Strings.session_was_auto_closed_details}</Text>
                    {loading ? (
                        <View style={styles.loading}>
                            <Spinner />
                        </View>
                    ) : (
                        <View style={styles.details}>
                            <View style={styles.borderGreyWidth} />
                            <View style={styles.boxDetails}>
                                <View style={styles.innerBoxDetails}>
                                    <Text grey p>{Strings.time_elapsed_}</Text>
                                    <Text p>{timeElapsedString}</Text>
                                </View>
                                <View style={styles.borderGreyHeight} />
                                <View style={styles.innerBoxDetails}>
                                    <Text grey p>{Strings.total_}</Text>
                                    {parkSession?.transactionAmount
                                        ? <Text p>{`${localizeCurrency(parkSession.transactionAmount, currencyCode)}`}</Text>
                                        : <Text p>{0}</Text>}
                                </View>
                            </View>
                        </View>
                    )}
                </View>
                <View style={styles.buttons}>
                    <Button plain border center textProps={{ h5: true }} style={{ flex: 1 }}
                            onPress={onBackPress}>{Strings.close}</Button>
                    <View style={styles.spacer} />
                    <Button center textProps={{ h5: true }} style={{ flex: 1 }}
                            onPress={onParkDetailsPress}>{Strings.session_details}</Button>
                </View>
            </View>
        </ParkableBaseView>
    );
};

export const AutoEndSessionRoute = createRoute({
    path: Routes.AutoEndSessionView,
    params: { type: AutoEndSessionViewParams }
});

const styles = StyleSheet.create({
    bodyView: {
        flex: 1,
        marginTop: 10,
        marginHorizontal: PADDING,
    },
    sessionDetails: {
        paddingVertical: 15,
        alignItems: 'center'
    },
    sessionAutoClosedText: {
        marginBottom: 15
    },
    buttonsDetailsAndClose: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    borderGreyWidth: {
        height: 1,
        backgroundColor: Colours.BORDER_GREY
    },
    borderGreyHeight: {
        width: 1,
        height: '100%',
        backgroundColor: Colours.BORDER_GREY
    },
    boxDetails: {
        width: '100%', flexDirection: 'row'
    },
    innerBoxDetails: {
        flex: 1,
        paddingVertical: 10
    },
    loading: {
        alignItems: "center",
    },
    details: {
        width: '100%'
    },
    imageContainer: {
        flexDirection: "row",
        marginHorizontal: -PADDING,
    },
    image: {
        flex: 1,
        zIndex: -1,
        aspectRatio: 375 / 291,
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 28,
    },
    spacer: {
        width: 18,
    },
});
