import {Nully} from "../../constants/nully";
import {useMySWR} from "../http/use-swr";
import {BayGroupResponse, BayGroupsResponse} from "./dto/BayGroupResponse";

export const useBayGroupsMemberInPark = (orgId: Nully<number>, parkId: Nully<number>) => {
   return useMySWR<BayGroupsResponse>(orgId && parkId ? `v2/organisations/${orgId}/baygroups/park/${parkId}/userMemberGroups` : null);
}

export const useBayGroupsInPark = (orgId: Nully<number>, parkId: Nully<number>) => {
    return useMySWR<BayGroupsResponse>(orgId && parkId ? `v2/organisations/${orgId}/baygroups/park/${parkId}` : null);
};

export const useBayGroup = (orgId: Nully<number>, groupId: Nully<number>) => {
    return useMySWR<BayGroupResponse>(orgId && groupId ? `v2/organisations/${orgId}/baygroups/${groupId}` : null);
};
