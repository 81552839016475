import React, {useMemo} from 'react';
import Strings from '../../constants/localization/localization';
import getParkAvailability from "react/constants/getParkAvailability";
import localizeCurrency from "react/constants/localization/localizeCurrency";
import {PricePeriod, PricePeriodType} from "react/model/parkingPrice/PricePeriod";
import {getPricePeriodByTypeAndDay} from "react/services/parkingPrice.service";
import getDayAvailability from "react/constants/availability/getDayAvailability";
import {TerritoryDTO} from "react/api/territory/dto/TerritoryDTO";
import {ParkDTO} from "react/model/ParkDTO";
import RatesTable from "react/components/pricing/RatesTable";
import Text from "react/parkable-components/text/Text";
import {StyleSheet, View} from "react-native";
import {getPeriodsFormatted} from "react/components/pricing/constants";

class StandardRatesTableProps {
    pricePeriods?: PricePeriod[];
    territory?: TerritoryDTO;
    park?: ParkDTO;
}

export default function StandardRatesTable(props: StandardRatesTableProps) {
    const {pricePeriods, territory, park} = props;
    const availability = park && getParkAvailability(park).availability;
    const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    const standardRatesTableValues = useMemo(() => {
        const rowValues = [];
        if(!pricePeriods || !availability){
            return [];
        }

        for(let i = 0; i < weekdays.length; i++) {
            const day = weekdays[i];
            const isParkAvailable = getDayAvailability((weekdays.indexOf(day) + 1) % 7, availability, (weekdays.indexOf(day) + 1) % 7).available;

            rowValues.push([day.substring(0,3),
                isParkAvailable
                    ? `${localizeCurrency(getPricePeriodByTypeAndDay(pricePeriods, PricePeriodType.Hourly, day)?.price ?? 0, territory?.currencyCode, false)}`
                    : `${Strings.closed}`,
                isParkAvailable
                    ? `${localizeCurrency(getPricePeriodByTypeAndDay(pricePeriods, PricePeriodType.Day, day)?.price ?? 0, territory?.currencyCode, false)} ${Strings.max}`
                    : `${Strings.closed}`]);
        }
        return rowValues;
    }, [pricePeriods, availability])

    const {periods} = getPeriodsFormatted(pricePeriods);
    const dayPeriod = periods?.find(p => p.type === PricePeriodType.Day);
    const parkFreePeriodInMinutes = pricePeriods?.find(p => p.freePeriodMinutes !== null)?.freePeriodMinutes ?? 0;
    const freePeriodToHours = parkFreePeriodInMinutes ? Math.floor(parkFreePeriodInMinutes / 60) : undefined;
    const freePeriodToMinutes = parkFreePeriodInMinutes ? parkFreePeriodInMinutes % 60 : undefined;

    const hoursText = freePeriodToHours === 1 ? "hour" : "hours";
    const minutesText = freePeriodToMinutes === 1 ? "minute" : "minutes";

    const getHoursAndMinutesText = () => {
        if (freePeriodToHours === 0 && (freePeriodToMinutes ?? 0) > 0) {
            return `${freePeriodToMinutes} ${minutesText}`
        }
        if (freePeriodToMinutes === 0) {
            return `${freePeriodToHours} ${hoursText}`
        }
        return `${freePeriodToHours} ${hoursText} and ${freePeriodToMinutes} ${minutesText}`
    }

    return (
        <View>
            <Text p bold>
                {Strings.how_our_rates_work}
            </Text>
            <Text p>{Strings.casual_pricing_description(periods?.map((p) => p.type))}</Text>
            <Text p bold>
                {Strings.standard_rates}
            </Text>
            {dayPeriod && (
                <Text p>
                    {Strings.period_description(dayPeriod.type, dayPeriod.start, dayPeriod.end, dayPeriod.cap)}
                </Text>
            )}
            {parkFreePeriodInMinutes > 0 && (
                <>
                    <Text p bold>
                        {Strings.free_parking_period}
                    </Text>
                    <Text p>{Strings.free_parking_period_description(getHoursAndMinutesText())}</Text>
                </>
            )}
            <RatesTable rows={standardRatesTableValues} columns={["", Strings.hourly, Strings.day]} />
        </View>
    );
};
