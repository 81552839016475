import React from "react"
import { useMemo } from "react";
import { useReduxMaps } from "react/redux/redux";
import { ParkInfo } from "react/model/ParkInfo";
import { ParkPinMeta, useParkPinPressHandler } from "../park.constants";
import { generateCasualIcon, generateCasualText, getShowCasualPin } from "./constants";
import { PinWrapper } from "../../pin-wrapper";
import { getCurrentDailyPricePeriod, getCurrentHourlyPricePeriod } from "react/services/parkingPrice.service";

export type ParkPinCasualWebProps = {
	meta: ParkPinMeta,
	data: Pick<ParkInfo, "currencyCode" | "parkingPrice" | "parkId" | "hideCasualBays">
}

export const ParkPinCasualWeb = (props: ParkPinCasualWebProps) => {
	const { data, meta } = props
	const maps = useReduxMaps()

	const {
		isFavouritePark,
		isMotorbikeDefault,
		isOrgMember,
		isParkFull,
		isParkOpen,
		casualDisplayType,
		hasShortTerm,
		numSpotsLeft,
		isParkUnavailable,
		isParkOwner,
		zIndex
	} = meta;

	const { hideCasualBays } = data;

	const shouldShow = getShowCasualPin({
		hideCasualBays,
		isParkOwner,
		hasShortTerm,
		isParkFull,
		isParkOpen,
		isOrgMember,
		isFavouritePark,
		preferences: maps.preferences
	})

	const handlePress = useParkPinPressHandler({
		parkId: data.parkId
	})

	const icon = generateCasualIcon({
		isFavouritePark,
		isMotorbikeDefault,
		isOrgMember
	})

	const pricePeriods = data.parkingPrice?.pricePeriods ?? [];
	const hourlyPricePeriod = !!pricePeriods ? getCurrentHourlyPricePeriod(pricePeriods) : null;
	const dailyPricePeriod = !!pricePeriods ? getCurrentDailyPricePeriod(pricePeriods) : null;

	const text = generateCasualText({
		currencyCode: data.currencyCode,
		displayType: casualDisplayType,
		isFavouritePark,
		isParkFull,
		numSpotsLeft,
		pricePerDay: dailyPricePeriod?.price,
		pricePerHour: hourlyPricePeriod?.price,
		isOrgMember
	})

	const deps = [
		text,
		props.data.parkId,
		shouldShow,
		icon
	]

	return useMemo(
		() => <>
			{shouldShow &&
				<PinWrapper handlePress={handlePress}
					isFavouritePark={isFavouritePark}
					isOrgMember={isOrgMember}
					isParkUnavailable={isParkUnavailable}
					icon={icon} >
					{text}
				</PinWrapper>
			}
		</>,
		deps)
}
