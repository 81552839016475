import { get, post, Token } from "./rest";
import encodeBody from "./encodeBody";
import Strings from "../constants/localization/localization";
import { useMySWR } from "./http/use-swr";
import { StripeSubscriptionInvoice } from "../model/StripeSubscriptionInvoice";
import { StripeSubscriptionRefund } from "../model/StripeSubscriptionRefund";
import { CreatePaymentResponse } from "../model/CreatePaymentResponse";

const STRIPE_API = 'https://api.stripe.com/v1/';


export type StripePublicApiKeyResponse = {stripePublicApiKey: string, masterStripePublicApiKey: string};

export const useMasterPublicStripeApiKey = () => {
    return useMySWR<StripePublicApiKeyResponse>(`v2/stripe/publicKey`);
}

export const usePublicStripeApiKey = (organisationId?: number) => {
    return useMySWR<StripePublicApiKeyResponse>(organisationId ? `v2/stripe/${organisationId}/publicKey` : null);
}

export function createCardToken(cardNum: string, expMth: string, expYr: string, cvv: string, stripeApiKey: string) {
    return new Promise((resolve, reject) => {
        let cardDetails = {
            "card[number]": cardNum,
            "card[exp_month]": expMth,
            "card[exp_year]": expYr,
            "card[cvc]": cvv,
        };

        const formBody = [];
        for (let property in cardDetails) {
            const encodedKey = encodeURIComponent(property);
            // @ts-ignore
            const encodedValue = encodeURIComponent(cardDetails[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }

        const formBodyStr = formBody.join("&");

        return fetch(STRIPE_API + 'tokens', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Bearer ' + stripeApiKey
            },
            body: formBodyStr
        }).then(response => {
            return response.json()
                .then(json => {
                    if(!response.ok) {
                        const obj = json as {error:{code:string, message:string}};
                        const {error} = obj;
                        const handlableCodes = [
                            'incorrect_cvc',
                            'incorrect_number',
                            'expired_card',
                            'invalid_card_type',
                            'invalid_cvc',
                            'invalid_expiry_month',
                            'invalid_expiry_year',
                            'invalid_number',
                            'card_declined',
                            'incorrect_address',
                            'incorrect_zip'
                        ];
                        if(!handlableCodes.includes(error.code)) {
                            obj.error.message = Strings.unknown_error_occurred;
                        }
                        return reject(obj);
                    } else {
                        return resolve(json)
                    }
                })
                .catch(e => {
                    reject(Strings.unknown_error_occurred);
                })
        }).catch(e => {
            reject(e);
        });
    });
}

export function createBankAccountToken(accountName: string,
                                       country: string,
                                       currencyCode: string,
                                       accountType: string,
                                       routingNumber: number | string | undefined,
                                       accountNumber: number | string,
                                       stripeApiKey: string) {

    let bankAccountDetails;

    if(!!routingNumber) {
        bankAccountDetails = {
            "bank_account[country]": country,
            "bank_account[currency]": currencyCode,
            "bank_account[account_holder_type]": accountType,
            "bank_account[account_holder_name]": accountName,
            "bank_account[account_number]": accountNumber,
            "bank_account[routing_number]": routingNumber,
        }
    } else {
        bankAccountDetails = {
            "bank_account[country]": country,
            "bank_account[currency]": currencyCode,
            "bank_account[account_holder_type]": accountType,
            "bank_account[account_holder_name]": accountName,
            "bank_account[account_number]": accountNumber,
        }
    }


    const REQUEST = new Request(STRIPE_API + 'tokens', {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + stripeApiKey
        },
        body: encodeBody(bankAccountDetails),
    });

    return new Promise((resolve, reject) => {
        return fetch(REQUEST).then((r) => {
            return r.json().then((json) => {
                return r.ok ? resolve(json) : reject(json)
            });
        })
    });
}

export type StripePaymentsResponse = {
    invoices: StripeSubscriptionInvoice[];
    refunds: StripeSubscriptionRefund[];
}

export const getStripePayments = (api: string, token: Token, subscriptionId: number) =>
    get(token, `${api}v2/stripe/${subscriptionId}/payments`) as Promise<StripePaymentsResponse>;

export const createSetupIntent = (api: string, token: Token) =>
    post(token, `${api}v2/stripe/create-setup-intent`) as Promise<CreatePaymentResponse>;

export const getDefaultPaymentSourceForPaymentIntent = (url: string, token: Token, paymentIntentId: string) => get(token, `${url}v4/stripe/defaultPaymentSource/paymentIntent/${paymentIntentId}`) as Promise<{paymentMethod: string}>;
