import React, { FC, PropsWithChildren, useEffect } from "react";
import { useAppDispatch, useSelector } from "../../redux/redux";
import { FirebaseService } from "../../services/firebase.service";
import { User } from "firebase/auth";
import { setFirebaseTokenAndUser, setIsAuthenticating } from "../../redux/actions/auth";
import { loadParkableUser, retrieveUserRoles, setCreateUserData, setUser } from "../../redux/actions/user";
import { setUserId } from "../../redux/actions/data";
import { Nully } from "../../constants/nully";
import useConfig from "../../hooks/useConfig";
import { API } from "../../constants/api";
import { useLogout } from "./logoutProvider";
import { getAuthToken, getCustomToken } from "../../api/auth";
import { getLegacyRefreshToken, removeLegacyRefreshToken } from "./AuthMigration";
import { Platform } from "react-native";
import { getInitialUrl } from "../../navigation/constants";
import { handleMagicLink } from "../../navigation/magicLinks/magicLinks";
import * as Linking from "expo-linking";
import { setFacebookLoggingAllowed, setUserForAnalytics } from "react/analytics";
import {
    getTrackingPermissionsAsync,
    PermissionStatus,
    requestTrackingPermissionsAsync
} from "expo-tracking-transparency";
import {useTeamsContext} from "../../../react/components/teams/context";

type Props = PropsWithChildren<{
    onIsAuthenticatingChanged?: (value: boolean) => void,
}>

export const AuthProvider: FC<Props> = (props: Props) => {

    const { children } = props;

    const dispatch = useAppDispatch();

    const logout = useLogout();
    const api = useSelector(state => state.data.api);

    const config = useConfig();
    const { firebaseAppId } = config;

    const { user } = useSelector(state => state.user);

    const {insideTeamsEnvironment} = useTeamsContext();

    useEffect(() => {
        if(user?.id) {
            dispatch(retrieveUserRoles());
        }
    }, [user?.id]);

    useEffect(() => {
        (async () => {
            if (!user) {
                return;
            }
            const allowed = await isTrackingPermitted();
            await setFacebookLoggingAllowed(allowed);
            if (allowed) {
                await setUserForAnalytics(user);
            }
        })();
    }, [user]);

    async function isTrackingPermitted() {
        if (Platform.OS !== 'ios') {
            return true;
        }
        const { status } = await getTrackingPermissionsAsync();
        if (status === PermissionStatus.DENIED) {
            return false;
        }
        if (status === PermissionStatus.GRANTED) {
            return true;
        }
        const { granted } = await requestTrackingPermissionsAsync();
        return granted;
    }

    async function tryJWTLogin() {
        if(!window?.location?.search) {
            console.log("logging out, not a browser!");
            return logout();
        }
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('id_token');
        if(!token) {
            return logout();
        }
        const customToken = await fetchCustomToken(token);
        if (customToken) {
            FirebaseService.signInWithCustomToken(customToken).catch(console.error);
        } else {
            logout();
        }
    }

    const fetchCustomToken = async (token: string): Promise<Nully<string>> => {
        try {
            const request = new Request(`${api}v4/users/auth/jwt/verify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({data: token}),
            });
            const response = await fetch(request);
            const json = await response.json();
            const customToken = json.firebaseCustomToken as string;
            console.log('customToken', customToken);
            return customToken;
        } catch (e: any) {
            console.log('Failed to fetch custom token', e);
        }
    }

    useEffect(() => {
        API.setUrl(api);
    }, [api]);

    useEffect(() => {
        if(Platform.OS !== "web") {
            const subscription = Linking.addEventListener("url", onDeeplink);
            return () => subscription?.remove();
        }
    }, []);

    function onDeeplink({ url }: Linking.EventType) {
        handleMagicLink(url).catch(console.error);
    }

    useEffect(() => {
        initFirebase().catch(console.error);
    }, [config]);

    async function initFirebase() {
        FirebaseService.initializeApp(config.firebase, insideTeamsEnvironment);
        await tryMagicLink();
        return FirebaseService.getAuth().onAuthStateChanged(onAuthStateChanged);
    }

    async function tryMagicLink() {
        try {
            const initialUrl = await getInitialUrl();
            if (initialUrl) {
                await handleMagicLink(initialUrl);
            }
        } catch (e) {
            console.error("Magic link failed", e);
        }
    }

    const onAuthStateChanged = (user: User | null) => {
        console.log(`onAuthStateChanged, user is ${user?.email}`);
        if (user) {
            onUserReceived(user).catch(console.error);
        } else if (Platform.OS === "web") {
            tryJWTLogin().catch(console.error);
        } else {
            loginUsingRefreshToken().catch(console.error);
        }
    };

    async function loginUsingRefreshToken() {
        if(firebaseAppId) {
            const refreshToken = await getLegacyRefreshToken();
            if (refreshToken) {
                try {
                    const response = await getAuthToken(config.firebase.apiKey, refreshToken);
                    const customToken = await getCustomToken(API.getUrl(), response.id_token);
                    if (customToken) {
                        return await FirebaseService.signInWithCustomToken(customToken.firebaseCustomToken);
                    }
                } catch (e) {
                    console.error(e);
                }
            }
        }
        logout();
    }

    const onUserReceived = async (user: User) => {
        if(firebaseAppId) {
            await removeLegacyRefreshToken(firebaseAppId);
        }
        const idTokenResult = await user.getIdTokenResult();
        const firebaseToken = idTokenResult.token;

        console.log("Got token");

        if(idTokenResult.claims.aud !== config.firebase.projectId) {
            console.log("The Firebase token is from the wrong environment!");
            return logout();
        }

        dispatch(setFirebaseTokenAndUser({idTokenResult,user}))

        try {
            const parkableUser = await loadParkableUser(api, { firebaseToken, parkableToken: undefined });
            if (!!parkableUser) {
                dispatch(setUserId(parkableUser.id));
                dispatch(setUser(parkableUser));

            } else {
                dispatch(setCreateUserData({ email: user.email! }));
            }
            dispatch(setIsAuthenticating(false));
        } catch (ex) {
            logout();
            throw ex;
        }
    };

    return <>
        {children}
    </>
};
