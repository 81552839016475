import { Nully } from "../../constants/nully";
import { useMyManySWR, useMySWR } from "../http/use-swr";
import { Bay } from "../../model/Bay";
import { BayDTO } from "../../model/BayDTO";
import { Feature } from "../../model/Types";
import { ActivityOrigin } from "../../model/ActivityOrigin";
import { generateQueryString, post, put } from "../http/rest";
import { ParkingPreferenceDTO, ParkingPreferenceStatus } from "../../model/ParkingPreferenceDTO";
import { VacateBayDeadlineResponse } from "../../dto/VacateBayDeadlineResponse";
import _ from "lodash";

export const useBay = (parkId: Nully<number>, bayId: Nully<number>) => {
    const r = useMySWR<Bay>(parkId && bayId ? `v2/bay/${parkId}/${bayId}` : null);
    return {
        ...r,
        bay: r.data
    }
}

export const useBaysAvailableToUser = (parkId: Nully<number>, options: {
    feature?: Feature,
    origin?: ActivityOrigin,
    requestDate?: string,
    userId?: number,
}) => {
    const queryString = generateQueryString(options)
    const r = useMySWR<{ bays: BayDTO[] }>(parkId == null ? null : `v2/parks/${parkId}/userBays${queryString}`)
    return {
        ...r,
        bays: r.data?.bays
    }

}

export const useBaysUserIsMember = (parkId: Nully<number>) => {
    const r = useMySWR<{bays: BayDTO[]}>(parkId == null ? null : `v2/parks/${parkId}/userBaysMember`)
    return {
        ...r,
        bays: r.data?.bays
    }
}

export const useBaysUserIsMemberManyParks = (parkIds: Nully<number[]>) => {
    const urls = parkIds?.map(parkId => `v2/parks/${parkId}/userBaysMember`);
    const { data, ...rest } = useMyManySWR<{bays: BayDTO[]}>(urls);
    const bays = data && _.flatten(data.map(result => result.bays));
    return {
        ...rest,
        bays,
    }
}

export const useUserParkingPreference = () => {
    return useMySWR<{preferences: ParkingPreferenceDTO[]}>(`v1/parking-preferences`);
}

export const createParkingPreferences = async (parkId: number, bayIds: number[]) => {
    const items = bayIds.map(bayId => {
        return {
            parkId,
            bayId
        }});
    return post<{preferences: ParkingPreferenceDTO[]}>(`v1/parking-preferences/create`, {items});
}

export const updateParkingPreferences = async (ids: number[], status: ParkingPreferenceStatus) => {
    const items = ids.map(id => {
        return {
            id,
            status
        }
    })
    return put<{preferences: ParkingPreferenceDTO[]}>(`v1/parking-preferences/update`, {items});
}

export const useVacateBayDeadline = (parkId: Nully<number>, bayId: Nully<number>) => {
    return useMySWR<VacateBayDeadlineResponse>(parkId && bayId ? `v2/bay/${parkId}/${bayId}/vacatedeadline`: null);
};
