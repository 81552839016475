import React from "react";
import { Routes } from "../../navigation/root/root.paths";
import { createRoute, NavigationProps } from "../../navigation/constants";
import { ParkSession } from "react/model/ParkSession";
import { ParkSessionDTO } from "react/model/ParkSessionDTO";
import { Platform } from "react-native";
import RetryPaymentMobile from "./RetryPaymentMobile";
import RetryPaymentWeb from "./RetryPaymentWeb";

type Props = NavigationProps<Routes.RetryPaymentRequest>

function RetryPayment(props: Props) {
    return Platform.OS === "web"
        ? <RetryPaymentWeb {...props}/>
        : <RetryPaymentMobile {...props}/>;
}

class RetryPaymentParams {
    sessionId: number;
    callback?: (session: ParkSession | ParkSessionDTO | null) => void
}

export default RetryPayment

export const RetryPaymentRoute = createRoute({
    options: {
        title: "Retry Payment"
    },
    path: Routes.RetryPaymentRequest,
    params: {
        type: RetryPaymentParams
    }
});
