import {useMySWR} from "../http/use-swr";
import {Parks2Response, RetrievePark2Response} from "./dto/retrieve-park-2.response";
import {get, post} from "../http/rest";

export const usePark = (parkId?: string | number | null) => {
    return useMySWR<RetrievePark2Response>(
        parkId ? `v2/parks/${parkId}` : null
    );
};

const fetcher = (url: string, params: any) => post<Parks2Response>(url, params);

export const useParks = (parkIds?: Array<string | number>) => useMySWR<Parks2Response>(parkIds ? [`v3/parks/ids`, JSON.stringify({ids: parkIds})] : null, fetcher);

export const getPark = (parkId: number) => get<RetrievePark2Response>(`v2/parks/${parkId}`);