import React from "react";
import { StyleSheet, View } from "react-native";
import { ActivityType, Voucher } from "../../../model/Voucher";
import Strings from "../../../constants/localization/localization";
import Text from "react/parkable-components/text/Text";
import { IRootReducer } from "../../../redux/reducers/main";
import { connect } from "react-redux";
import VoucherCard from "./VoucherCard";
import { createRoute, NavigationProps } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import ParkableBaseView from "../ParkableBaseView";

class VoucherDetailParams {
    voucher: Voucher;
    parkId: number;
    activity: ActivityType;
}

const mapStateToProps = (state: IRootReducer, props: NavigationProps<Routes.VoucherDetail>) => {
    return {
        voucher: props.route.params.voucher,
        parkId: props.route.params.parkId,
        activity: props.route.params.activity,
    }
};

type VoucherDetailProps = NavigationProps<Routes.VoucherDetail> & ReturnType<typeof mapStateToProps>;

function  VoucherDetail(props: VoucherDetailProps) {
    const {
        voucher,
        parkId,
        activity
    } = props;

    return <ParkableBaseView scrollable={false}>
        <View style={{flex: 1}}>
            <View style={styles.header}>
                <Text h2 >{Strings.vouchers}</Text>
                <Text small grey>{Strings.vouchers_help_text}</Text>
            </View>
            <VoucherCard
                voucherApplied
                parkId={parkId}
                voucher={voucher}
                activity={activity}
            />
    </View>
    </ParkableBaseView>;
}

export default connect(mapStateToProps)(VoucherDetail) as any as React.FunctionComponent<VoucherDetailProps>;

export const VoucherDetailRoute = createRoute({
    path: Routes.VoucherDetail,
    params: {type: VoucherDetailParams},
});

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    header:{
        paddingTop: 16,
        paddingBottom: 16,
    }
});
