import * as Api from "../../api/auth";
import fetch from "../reducers/fetch";
import {DispatchFunc} from "../../model/Types";
import { FirebaseUser, stripUnserializableValues } from "../../services/firebase.service";
import * as Notifications from 'expo-notifications';
import {Platform} from "react-native";
import { IdTokenResult } from "firebase/auth";

export const SET_FIREBASE_USER = "SET_FIREBASE_USER";
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN';
export const SET_FIREBASE_TOKEN_AND_USER = 'SET_FIREBASE_TOKEN_AND_USER';

export type FirebaseTokenAndUser = {
    idTokenResult: IdTokenResult | undefined;
    user: FirebaseUser | undefined;
};

export function setFirebaseTokenAndUser(args?: FirebaseTokenAndUser) {
    return {
        type: SET_FIREBASE_TOKEN_AND_USER,
        args: args ? {
            ...args,
            user: args.user
                ? stripUnserializableValues(args.user)
                : undefined
        } : undefined
    };
}

export const setIsAuthenticating = (isAuthenticating: boolean) => {
    return {
        type: "SET_IS_AUTHENTICATING",
        isAuthenticating
    } as const
}

export async function getPushToken() {
    if(Platform.OS === "web") {
        return Promise.resolve("WEB")
    }

    return (await Notifications.getDevicePushTokenAsync()).data;
}

export function getCustomToken(token: string)  {
    return fetch((dispatch: DispatchFunc, api: string) => {
        return Api.getCustomToken(api, token);
    }, arguments);
}
