import { Linking, ScrollView, StyleSheet, View } from "react-native";
import React, { useState } from "react";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import Strings from "../../constants/localization/localization";
import { EstimatedLeaveTimePicker } from "./EstimatedLeaveTimePicker";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {Routes} from "../../navigation/root/root.paths";
import { PADDING } from "react/root/root.constants";
import ParkableBaseView from "../common/ParkableBaseView";

class ConfirmStartTandemParkingParams {
    parkId: number;
    onConfirmTandemParking?: (estimatedLeaveTime: string | null) => void;
}

const LEARN_MORE_URL = "https://parkable.com/en-nz/how-tos/tandem-parking";

const ConfirmStartTandemParkingView = (props: NavigationProps<Routes.ConfirmStartTandemParkingView>) => {
    const { parkId, onConfirmTandemParking } = props.route.params ?? {};
    const [estimatedLeaveTime, setEstimatedLeaveTime] = useState<string | null>("");

    const onlyInfoScreen = !onConfirmTandemParking;

    const handleMoreInfoPress = async () => {
        if (await Linking.canOpenURL(LEARN_MORE_URL)) {
            Linking.openURL(LEARN_MORE_URL);
        }
    };

    const handleEstimatedLeaveTimeUpdate = () => {
        const _estimatedLeaveTime = !estimatedLeaveTime || estimatedLeaveTime === "null"
            ? null
            : estimatedLeaveTime;
        onConfirmTandemParking?.(_estimatedLeaveTime);
    }

    return (
        <ParkableBaseView scrollable={false}>
            <Text h1 style={styles.header}>
                {Strings.tandem_parking.allocated}
            </Text>
            <ScrollView
                contentContainerStyle={styles.scrollContainer}
                bounces={false}>
                <Text p>{Strings.tandem_parking.information_para1}</Text>
                <Text p>{Strings.tandem_parking.information_para2}</Text>
                <Text
                    p grey
                    style={{ textDecorationLine: "underline" }}
                    onPress={handleMoreInfoPress}
                >
                    {Strings.learn_more}
                </Text>

                {!onlyInfoScreen &&
                    <EstimatedLeaveTimePicker
                        parkId={parkId}
                        value={estimatedLeaveTime}
                        handleChange={setEstimatedLeaveTime}
                    />}

            </ScrollView>

            {!onlyInfoScreen &&
                <View style={styles.buttonContainer}>
                    <Button
                        disabled={!estimatedLeaveTime}
                        onPress={handleEstimatedLeaveTimeUpdate}
                        iconRight={"arrowboldright"}>
                        {Strings.confirm_and_start}
                    </Button>
                </View>}
        </ParkableBaseView>
    );
};

export default ConfirmStartTandemParkingView;

export const ConfirmStartTandemParkingRoute = createRoute({
    path: Routes.ConfirmStartTandemParkingView,
    params: {
        type: ConfirmStartTandemParkingParams
    }
})

const styles = StyleSheet.create({
    header: {
        paddingTop: 9,
    },
    scrollContainer: {
        paddingBottom: 120,
    },
    buttonContainer: {
        position: "absolute",
        zIndex: 1,
        left: 0,
        right: 0,
        bottom: 0,
        paddingTop: 18,
        paddingHorizontal: PADDING,
        paddingBottom: 36,
    },
});
