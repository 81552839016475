import { useReduxMaps } from "react/redux/redux";
import { CampusDTO } from "react/api/campus/dto/CampusDTO"
import getParkAvailability from "react/constants/getParkAvailability"
import { useFavouriteParkIds } from "./pin.constants"
import { useNavigation } from "react/navigation/constants";
import { Routes } from "react/navigation/root/root.paths";
import { userIsOrgMember as checkOrgMember } from "../../../../../constants/getUserMember";
import { useUserRoles } from "../../../../../api/user/user.api";

export const useCampusPinData = (args: Pick<CampusDTO, | "parks">) => {
	const { parks } = args
	const favouriteParkIds = useFavouriteParkIds()
	const organisationId = parks?.[0].organisationId
	const {userRoles} = useUserRoles();

	const hasAnOpenPark = parks.some(park => getParkAvailability(park).isOpenNow)
	const containsFavouritePark = favouriteParkIds.some(
		id => parks.some(pInfo => pInfo.parkId === id)
	)

	const isOrgMember = organisationId != null && checkOrgMember(userRoles, organisationId);

	return {
		hasAnOpenPark,
		containsFavouritePark,
		isOrgMember
	}
}

export const useCampusPinPressHandler = (args: Pick<CampusDTO, "id" | "organisation">) => {
	const campusId = args.id
	const organisationId = args.organisation;

	const { preferences } = useReduxMaps()

	const navigation = useNavigation();

	const handlePress = () =>
	{
		navigation.navigate(Routes.CampusScreenView, {
			campusId: campusId,
			organisationId,
			parkingType: preferences.parkingType,
		});

	}

	return handlePress
}
