import React from "react";
import Text from "react/parkable-components/text/Text";
import { Image, View } from "react-native";
import { contentStyles } from "./styles";
import Strings from "../../../constants/localization/localization";
import { createRoute } from "../../../navigation/constants";
import { Routes } from "../../../navigation/root/root.paths";
import ParkableBaseView from "../../common/ParkableBaseView";
import { PADDING } from "../../../root/root.constants";

const HandImage = require("../../../resources/hand.png");

export const HowItWorks_ThisWeekView = () => {
    return (
        <ParkableBaseView scrollable={false} removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            <View style={{flex: 1}}>
                <View style={{paddingHorizontal: PADDING}}>
                    <View style={contentStyles.heading}>
                        <Text h1>{Strings.future_booking.booking_for_this_week}</Text>
                    </View>

                    <Text style={contentStyles.mainText}>
                        {Strings.future_booking.how_to.this_week_p1[0]}
                    </Text>
                    <Text style={contentStyles.mainText}>
                        {Strings.future_booking.how_to.this_week_p1[1]}
                        <Text bold green>
                            {Strings.future_booking.how_to.this_week_p1[2]}
                        </Text>
                        {Strings.future_booking.how_to.this_week_p1[3]}
                    </Text>
                    <Text style={contentStyles.mainText}>
                        {Strings.future_booking.how_to.this_week_p1[4]}
                        <Text bold style={contentStyles.textOrange}>
                            {Strings.future_booking.how_to.this_week_p1[5]}
                        </Text>
                        {Strings.future_booking.how_to.this_week_p1[6]}
                    </Text>
                </View>
                <View style={contentStyles.graphicContainer}>
                    <Image source={HandImage} style={contentStyles.image}/>
                </View>
            </View>
        </ParkableBaseView>
    );
};

export const HowItWorks_ThisWeekRoute = createRoute({
    path: Routes.HowItWorks_ThisWeekView
})
