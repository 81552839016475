import { Role } from "../model/User";
import {AccessLevel, UserRoleDTO} from "../api/user/dto/user.dto";
import {Nully} from "./nully";

export const userIsAnyOrgMember = (userRoles?: UserRoleDTO[]) => {
    return (userRoles??[])
        .filter(ur => ur.role.name !== Role.PUBLIC)
        .filter(ur => !ur.suspended).length > 0;
}

export const userIsOrgMember = (userRoles: UserRoleDTO[] | undefined, orgId: Nully<number>) => {
    return (userRoles??[])
        .filter(ur => ur.role.name !== Role.PUBLIC && ur.organisation === orgId)
        .some(ur => !ur.suspended);
};

export const getPrivateOrgs = (userRoles?: UserRoleDTO[]) => {
    const organisationIds = userRoles?.filter(ur => ur.role.name !== Role.PUBLIC && !!ur.organisation).map(ur => ur.organisation!);
    if(organisationIds?.length === 0){
        return [];
    }
    return [...new Set(organisationIds)];
};

export function userIsParkableAdmin (userRoles?: UserRoleDTO[]) {
    return (userRoles?.filter(ur => ur.role.name === Role.ADMINISTRATOR)
        .filter(ur => ur.role.accessLevel === AccessLevel.Global)
        .length??0) > 0;
}

export function userIsSensorAdmin (userRoles?: UserRoleDTO[]) {
    return (userRoles?.filter(ur => ur.role.name === Role.SENSOR_ADMIN)
        .filter(ur => ur.role.accessLevel === AccessLevel.Global)
        .length??0) > 0;
}