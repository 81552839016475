import {ParkingType} from "react/model/Types";
import {useReduxMaps, useReduxUser, useSelector,} from "react/redux/redux";
import getParkAvailability from "react/constants/getParkAvailability";
import {Feature} from "react/model/Bay";
import {ParkInfo} from "react/model/ParkInfo";
import {useNavigation} from "react/navigation/constants";
import {useActiveSubscriptions, useCurrentUserVehicle, useFavouriteParkIds} from "../pin.constants";
import {Routes} from "react/navigation/root/root.paths";
import { userIsOrgMember as checkOrgMember } from "../../../../../../constants/getUserMember";
import { useUserRoles } from "../../../../../../api/user/user.api";

export type ParkPinMeta = ReturnType<typeof useParkPinMeta>
export const useParkPinMeta = (info: Pick<
	ParkInfo,
	"parkId"
	| "organisationId"
	| "ownerId"
	| "numberOfLeasesAvailable"
	| "numberOfPublicSubscriptionBays"
	| "currentParksAvailable"
	| "numberOfPublicDynamicBays"
	| "numberOfEVBays"
	| "currentLeasesAvailable"
	| "timeZoneId"
	| "availability"
>,
	parkingType?: ParkingType) => {
	const { user } = useReduxUser();
	const {userRoles} = useUserRoles();
	const { preferences: mapPreferences } = useReduxMaps();
	const favParkIds = useFavouriteParkIds()
	const currentVehicle = useCurrentUserVehicle()

	const isFavouritePark = favParkIds?.includes(info.parkId) ?? false;
	const isOrgMember = info.organisationId != null && checkOrgMember(userRoles, info.organisationId);

	const isParkOwner = info.ownerId === user?.id;
	const hasLongTerm =
		Number(info.numberOfLeasesAvailable) +
		Number(info.numberOfPublicSubscriptionBays) >
		0;
	const hasShortTerm =
		Number(info.currentParksAvailable) +
		Number(info.numberOfPublicDynamicBays) >
		0;
	const hasEvCharger = Number(info.numberOfEVBays) > 0;
	const parkAvailability = getParkAvailability(info);
	const isParkOpen = parkAvailability.isOpenNow;
	const isLongTerm = mapPreferences.parkingType === ParkingType.LONG_TERM;
	// FIXME: This was taken from mobile project, but results in erroneous displayed availability
	const numSpotsLeft = isLongTerm
		? info.currentLeasesAvailable
		: parkingType === ParkingType.ELECTRIC
			? info.numberOfEVBays
			: info.currentParksAvailable
	const isParkFull = numSpotsLeft === 0;
	const isParkUnavailable = (!isOrgMember && isParkFull) || !isParkOpen;
	const isMotorbikeDefault = currentVehicle?.feature === Feature.MOTORBIKE;
	const parkingTypeFilter = mapPreferences.parkingType;
	const casualDisplayType = mapPreferences.casualDisplayType;
	const longTermDisplayType = mapPreferences.ltDisplayType;
	const zIndex = 1
		+ (Number(isOrgMember ?? 0) * 3)
		+ (Number(isFavouritePark ?? 0) * 2)
		+ (Number(isParkOpen ?? 0) * 1)
		+ (Number(!isParkFull ?? 0) * 1)

	return {
		isFavouritePark,
		isOrgMember,
		isParkOpen,
		isParkOwner,
		hasLongTerm,
		hasShortTerm,
		hasEvCharger,
		isParkFull,
		isParkUnavailable,
		isMotorbikeDefault,
		parkingTypeFilter,
		casualDisplayType,
		longTermDisplayType,
		numSpotsLeft,
		zIndex
	};
};

export const useParkPinPressHandler = (info: Pick<ParkInfo, | "parkId">) => {
	const navigation = useNavigation()
	const parkId = info.parkId
	const activeSubs = useActiveSubscriptions()
	const currentSession = useSelector(state => state.parking.currentSession)
	const preferences = useSelector(state => state.maps.preferences)

	const subInPark = activeSubs.find(
		(s) => s.park === parkId
	)

	const hasActiveSessionAtPark =
		currentSession != null
		&& currentSession.startedAt != null
		&& currentSession?.endedAt == null
		&& currentSession.park === parkId

	const handlePress = () => {
		if (hasActiveSessionAtPark) {
			navigation.navigate(Routes.ActiveSession);
		} else if (subInPark) {
			navigation.navigate(Routes.SingleSubscriptionView, {subscriptionId: subInPark.id})
		} else {
			navigation.navigate(Routes.ParkDetailView, {
				parkId: parkId,
				parkingType: preferences.parkingType
			})
		}
	}

	return handlePress
}
