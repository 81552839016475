import I18n from "i18n-js";
import en from "./translations/en";
import {IStrings} from "./translations/en";

I18n.fallbacks = true;

I18n.defaultLocale = "en";
I18n.locale = I18n.currentLocale();

I18n.translations = {
    en,
};

const Strings: any = {};

Object.keys(I18n.translations)
    .forEach(lang => {
        const language: any = I18n.translations[lang];
        Object.keys(en)
            .filter(k => !language[k])
            .forEach(k => {throw `Key ${k} missing from ${lang} translation`;});
});

Object.keys(en).forEach(k => Strings[k] = I18n.t(k));

export default <IStrings>Strings;

