// import * as Sentry from '@sentry/react-native';
import DEFAULT_API_VERSION from "./apiVersion";
import {FirebaseService} from "../services/firebase.service";

export type Token = {
    firebaseToken: string|undefined,
    parkableToken: string|undefined|null,
}

function get(token: Token|undefined, url: string, params?: any) {
    const qs = [];
    if (params) {
        for (let param in params) {
            if (params[param] || params[param] === 0 || params[param] === false) {
                const p = encodeURIComponent(param);
                let v;
                if (Object.prototype.toString.call(params[param]) === '[object Array]') {
                    v = encodeURIComponent(params[param].join(','));
                } else {
                    v = encodeURIComponent(params[param]);
                }
                qs.push(p + '=' + v);
            }
        }
    }

    let query = '';
    if (qs.length > 0) {
        query = '?' + qs.join('&');
    }

    return fetchJSON(token, 'get', url + query);

}

function post(token: Token, url: string, data?: any, headerOverrides?: {[key:string]: string}) {
    return fetchJSON(token, 'post', url, data, headerOverrides);
}

function put(token: Token|undefined, url: string, data?: any) {
    return fetchJSON(token, 'put', url, data);
}

function del(token: Token, url: string, data?: any) {
    return fetchJSON(token, 'delete', url, data);
}

let firebaseAuthTokenRefreshPromise: Promise<string|undefined> | null = null;

async function refreshFirebaseAuthToken() : Promise<string|undefined> {
    if (firebaseAuthTokenRefreshPromise) {
        return firebaseAuthTokenRefreshPromise;
    }
    try {
        firebaseAuthTokenRefreshPromise = tryRefreshFirebaseAuthToken(3);
        return await firebaseAuthTokenRefreshPromise;
    } finally {
        firebaseAuthTokenRefreshPromise = null;
    }
}

async function tryRefreshFirebaseAuthToken(retries: number) : Promise<string|undefined> {
    try {
        return await FirebaseService.getCurrentUser()?.getIdToken(true);
    } catch (e) {
        if(retries == 0) {
            throw e;
        } else {
            console.log("Error encountered while attempting to refresh firebase token.", e);
            return tryRefreshFirebaseAuthToken(retries-1)
        }
    }
}

async function fetchJSON(token: Token|undefined, method: string, url: string, data?: any, headerOverrides?: {[key:string]: string}|undefined) {

    let retries = 3;

    while(retries > 0){

        const headers = {} as {[key:string]: string};

        headers['X-Parkable-API-Version'] = DEFAULT_API_VERSION;

        if (data === null || (data != null && !data.isformdata)) {
            headers['Accept'] = 'application/json';
            headers['Content-Type'] = 'application/json';
        }

        if(token?.firebaseToken != undefined){
            headers['Authorization'] = token.firebaseToken;
        }
        else if (token?.parkableToken != null) {
            headers['X-Token'] = token.parkableToken;
        }
        else{
            console.log("NO TOKEN!");
        }

        let body = null;
        if (method !== 'get' && data != null) {

            if (data.isformdata) {
                body = data;
            } else {
                body = JSON.stringify(data);
            }
        }

        const request = new Request(url, {
            method: method,
            headers: {...headers, ...headerOverrides},
            body: body
        });

       // console.log("Headers= ", request.headers);
       // console.log("body= ", request.body);
        try{
            let response = await fetch(request);
            let responseBody = response.status === 204 ? {} as any : await response.json();
            if(response.ok){

                return Promise.resolve(responseBody);
            }
            else if(response.status === 401 && !!token?.firebaseToken && responseBody?.errorCode !== "UNVERIFIED_EMAIL") {
                //our firebase token wasn't accepted, get a new one
                console.log("REQUESTING A NEW TOKEN!");

                token.firebaseToken = await refreshFirebaseAuthToken();

                console.log("RECEIVED A NEW TOKEN!");

                if(retries === 0){
                    console.log("exhausted retries: responseBody",responseBody);
                    responseBody.status = response.status;
                    // Sentry.captureException(new Error("exhausted retries"), {
                    //     tags: {
                    //         url,
                    //         data,
                    //         responseBody: JSON.stringify(responseBody)
                    //     },
                    // });
                    return Promise.reject(responseBody)
                }
                --retries;
            }
            else{
                responseBody = {...responseBody, status: response.status};
                console.log("responseBody",responseBody);
                // Sentry.captureException({message: "Retry fail fall through, response: " + JSON.stringify(response)});
                return Promise.reject(responseBody);
            }
        }
        catch(_err){
            const err = _err as any;

            if (!!err) {
                if (err.code === "auth/internal-error" || err.code === "auth/user-token-expired" || err.code === "auth/no-current-user") {
                    console.log("Firebase error in rest", err);
                    //firebase has logged the user out, due to pwd/email or other major change to user object
                    await FirebaseService.logout();
                    // Sentry.captureException(err);
                    return Promise.reject("Token expired");
                }
                else if(err.cdoe === 400){
                    console.log("ERROR IS 400!");
                }
                console.log('error getting response:', err, url);
            }

            --retries;

            if(retries === 0){
                return Promise.reject("network_error");
            }

        }
    }

    console.log("Retry fail fall through");
    // Sentry.captureException(new Error("Retry fail fall through"), {
    //     tags: {
    //         url,
    //         data
    //     },
    // });
    return Promise.reject();
}


export {get, post, put, del};
