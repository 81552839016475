import React from "react";
import Text from "react/parkable-components/text/Text";
import Button from "react/parkable-components/button/Button";
import {Image, View} from "react-native";
import {contentStyles} from "./how-it-works.styles";
import Strings from "../../constants/localization/localization";
import {useTerritories} from "../../api/territory/territory.api";
import {useOrganisation} from "../../api/organisation/organisation.api";
import Constants from "../../constants/constants";
import { Routes } from "react/navigation/root/root.paths";
import ParkableBaseView from "../common/ParkableBaseView";
import {createRoute, NavigationProps} from "../../navigation/constants";
import {PADDING} from "../../root/root.constants";

const HandImage = require("../../resources/hand.png");

class HowItWorksRewardsParams {
    organisationId: number;
    territoryId?: number;
};

const _HowItWorks_Rewards = (props: NavigationProps<Routes.HowItWorks_Rewards>) => {
    const params = props.route.params;
    const {territories} = useTerritories();
    const territory = territories?.find(t => params?.territoryId ? t.id === params.territoryId : t.countryCode === "NZ");
    const {organisation} = useOrganisation(params?.organisationId);

    const onMyRewardsPress = () => {
        props.navigation.push(Routes.MyRewardsView, {organisationId: params?.organisationId, territoryId: territory?.id});
    };

    return (
        <ParkableBaseView removeStandardMargins toolbarStyle={{marginLeft: PADDING}}>
            <View style={{flex: 1, paddingHorizontal: PADDING}}>
                <View style={contentStyles.heading}>
                    <Text h1>{Strings.rewards.how_it_works}</Text>
                </View>

                <Text style={contentStyles.mainText}>
                    {Strings.rewards.how_it_works_describe(organisation?.bonusSessionMargin??0, territory?.currencyCode??"NZD")}
                </Text>

                <Text style={contentStyles.mainText}>
                    {Strings.rewards.how_it_works_describe_once_you_have(Constants.minimumPointsToRedeemed)}
                </Text>

                <Button style={contentStyles.rewardsBtn} center
                        onPress={onMyRewardsPress}>{Strings.rewards.view_rewards}</Button>
            </View>
            <View style={contentStyles.graphicContainer}>
                <Image source={HandImage} style={contentStyles.image}/>
            </View>
        </ParkableBaseView>
    );
};

export const HowItWorks_Rewards = _HowItWorks_Rewards;

export const HowItWorksRewardsRoute = createRoute({
    path: Routes.HowItWorks_Rewards,
    params: {type: HowItWorksRewardsParams}
})
