import React, {useContext, useEffect} from "react";
import {ChatContext, ProblemActionContext, SupportContext} from "../Context";
import {Linking} from "react-native";
import Constants from "../../../constants/constants";
import Strings from "../../../constants/localization/localization";
import { AllProblemViewProps } from "../ProblemView";
import { QuestionTypes } from "./ProblemFlow.types";
import { usePark } from "../../../api/park";
import { useTerritory } from "../../../api/territory/territory.api";

const flow: QuestionTypes = {
    fetchingEvFaq: {
        isItSupport: true,
        title: Strings.redirecting_to_faq,
        type: "content",
        action: "onRedirectToEVFaqs"
    }
}

export const EVFaqs = (props: AllProblemViewProps) => {

    const { parkId } = props;

    const {problemActions, updateProblemActions } = useContext(ProblemActionContext)
    const {messages, processOptionQuestion, errorHandling} = useContext(ChatContext);
    const {updateSupportFlow} = useContext(SupportContext);

    const { park } = usePark(parkId);
    const { territory } = useTerritory(park?.territory);

    useEffect(()=> {
        problemActions["onEvFaqsPress"] = onEvFaqsPress;
        problemActions["onRedirectToEVFaqs"] = onRedirectToEVFaqs;
        updateProblemActions(problemActions);
    }, [messages])

    const isUKPark = !!territory && Constants.COUNTRY_CODE_UK === territory.countryCode;

    const onEvFaqsPress = () => {
        processOptionQuestion("evFaqs");
        updateSupportFlow('evFaqs', flow, 'fetchingEvFaq');
    }

    const onRedirectToEVFaqs = async () => {
        let URL = "https://parkable.com/ev-parkers#faqs";
        if(isUKPark) {
            URL = "https://parkable.com/ev/uk/parkers#faqs";
        }

        if (await Linking.canOpenURL(URL)) {
            await Linking.openURL(URL);
        } else {
            const err_msg = Strings.error_message_evfaq.replace("faq_url", URL);
            errorHandling(err_msg)
        }
    }

    return <></>
}
